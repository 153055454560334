import './SidePanelFooter.css';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ModuleDetails } from '../../../models/PamModels';
import SpfAccounts from './SpfAccounts/SpfAccounts';
import SpfEndpoint from './SpfEndpoint/SpfEndpoint';
import SpfSession from './SpfSession/SpfSession';

const TYPE = {
  account: 'account',
  session: 'session',
  endpoint: 'endpoint',
};

function SidePanelFooter({
  data,
  accountOnClick,
  dataTestId,
  setIsCheckInModalOpen,
  setIconClickedForRow,
  className,
}) {
  const classes = classnames('SidePanelFooter', className);

  const renderSidePanelFooter = (type) => {
    switch (type) {
      case TYPE.account:
        return <SpfAccounts data={data} accountOnClick={accountOnClick} />;
      case TYPE.session:
        return (
          <SpfSession
            data={data}
            accountOnClick={accountOnClick}
            setIsCheckInModalOpen={setIsCheckInModalOpen}
            setIconClickedForRow={setIconClickedForRow}
          />
        );
      case TYPE.endpoint:
        return <SpfEndpoint data={data} accountOnClick={accountOnClick} />;
      default:
        return null;
    }
  };

  return (
    <div className={classes} data-testid={dataTestId}>
      {renderSidePanelFooter(data.type)}
    </div>
  );
}

SidePanelFooter.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  accountOnClick: PropTypes.func,
  className: PropTypes.string,
  setIsCheckInModalOpen: PropTypes.func,
  setIconClickedForRow: PropTypes.func,
};

SidePanelFooter.defaultProps = {
  data: null,
  dataTestId: null,
  accountOnClick: null,
  className: null,
  setIsCheckInModalOpen: null,
  setIconClickedForRow: null,
};

export default SidePanelFooter;
