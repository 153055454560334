/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable object-curly-newline */
import './TimeAccessDuration.css';

import { getCurrentDate } from '@saviynt/common';
import {
  Icon,
  InlineMessage,
  ToggleButton,
  ToggleButtonGroup,
} from '@saviynt/design-system';
import { add, addMinutes, addSeconds, isBefore, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  SECONDS_IN_FIVE_MINUTES,
  SECONDS_PER_DAY,
} from '../../../../utilities/timeConstants';
import ViewScheduleLink from '../../ViewScheduleModalIsland/ViewScheduleLink/ViewScheduleLink';
import ByTypeDateAndTimePicker from './helpers/ByTypeDateAndTimePicker';
import CustomDuration from './helpers/CustomDuration';
import DurationChips from './helpers/DurationChips';
import DurationContinueButton from './helpers/DurationContinueButton';

/**
 * #### WIP Todo:
 * - Replace Shared max/min date with API criteria
 * - Dropdown Now Selection should have Minutes, Hours, Days (Depends on the maximum allowable request time customer’s admin has set. “Days” won’t be an option sometimes)
 */

const initialIntervalOptions = [
  {
    label: 'Minutes',
    value: 'minutes',
    isChecked: true,
  },
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Days',
    value: 'days',
  },
];

const nowConflictWarning = (date) =>
  `The duration you entered conflicts with another session starting at ${date}.`;

function TimeAccessDuration({
  toggleChoice,
  setToggleChoice,
  setDurationContinue,
  startDateOfNextCredential,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  isTimeBlocked,
  setIsTimeBlocked,
  setIsScheduleViewOpen,
  accountSelection,
}) {
  // Inline message warning
  const [isWarning, setIsWarning] = useState(false);
  // NOW tab related states
  const [customDurationInputValue, setCustomDurationInputValue] = useState('');
  const [intervalOptions, setIntervalOptions] = useState(
    initialIntervalOptions
  );
  const [isCustomUnderFiveMin, setIsCustomUnderFiveMin] = useState(false);
  const [isNowBlocked, setIsNowBlocked] = useState(false);
  // FUTURE tab realated states
  const [isDurationCustom, setIsDurationCustom] = useState(false);
  const [durationChipValue, setDurationChipValue] = useState(0);
  // Add 1 year to the current date
  const rangeMaxDate = add(new Date(), { years: 1 });
  const rangeMinDate = new Date();

  useEffect(() => {
    if (startDate) {
      const isoStartDate = startDate.toISOString();

      const parsedStartDateOfNextCredential = parseISO(
        startDateOfNextCredential
      );
      const parsedStartDate = parseISO(isoStartDate);
      const fiveMinutesAfterRangeMinDate = addMinutes(parsedStartDate, 5);

      setIsNowBlocked(
        isBefore(parsedStartDateOfNextCredential, fiveMinutesAfterRangeMinDate)
      );
    }
  }, [startDate, startDateOfNextCredential]);

  // Reset Now block and Tab selection if account is switched
  useEffect(() => {
    setIsNowBlocked(false);
    setToggleChoice(1);
  }, [accountSelection]);

  // Now or Future
  const isNowTab = toggleChoice === 1;

  useEffect(() => {
    if (accountSelection.maxtime < SECONDS_PER_DAY) {
      // Filter out the 'Days' option
      const updatedIntervalOptions = initialIntervalOptions.filter(
        (option) => option.value !== 'days'
      );

      setIntervalOptions(updatedIntervalOptions);
    } else {
      setIntervalOptions(initialIntervalOptions);
    }
  }, [accountSelection]);

  useEffect(() => {
    if (isNowBlocked) setToggleChoice(2);
  }, [isNowBlocked]);

  // Reset the duration chip selection on tab change
  useEffect(() => {
    setDurationChipValue(0);
    setIsDurationCustom(false);
  }, [toggleChoice]);

  useEffect(() => {
    // Critical Alert message for custom
    if (isDurationCustom && durationChipValue < SECONDS_IN_FIVE_MINUTES) {
      setIsCustomUnderFiveMin(true);
    } else {
      setIsCustomUnderFiveMin(false);
    }

    const isFuturePredefinedChipWithStartDate =
      !isNowTab && startDate && durationChipValue && !isDurationCustom;
    const isNowWithStartDate =
      isNowTab && startDate && durationChipValue && !isDurationCustom;
    const isCustomNowWithStartDate =
      isNowTab && startDate && durationChipValue && isDurationCustom;

    if (isFuturePredefinedChipWithStartDate)
      setEndDate(addSeconds(startDate, durationChipValue));

    if (isNowWithStartDate)
      setEndDate(addSeconds(startDate, durationChipValue));

    if (isCustomNowWithStartDate)
      setEndDate(addSeconds(startDate, durationChipValue));
  }, [startDate, durationChipValue]);

  useEffect(() => {
    if (isNowTab) {
      setStartDate(getCurrentDate());
    } else {
      setStartDate(null);
    }
  }, [isNowTab, durationChipValue]);

  const getDatePickerType = (type) => (
    <ByTypeDateAndTimePicker
      type={type}
      isNowTab={isNowTab}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      isDurationCustom={isDurationCustom}
      rangeMaxDate={rangeMaxDate}
      rangeMinDate={rangeMinDate}
    />
  );

  const onWarningClose = () => {
    setIsWarning(false);
  };

  // Format the warning date in user's local time zone
  const localTimeOfNextCredential = new Date(startDateOfNextCredential);
  let formattedWarningDate = '';

  if (
    localTimeOfNextCredential &&
    !Number.isNaN(new Date(localTimeOfNextCredential).getTime())
  ) {
    formattedWarningDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    }).format(new Date(localTimeOfNextCredential));
  } else {
    formattedWarningDate = 'TIME';
  }

  // Check for Selected NOW duration blocker
  useEffect(() => {
    const now = new Date().toISOString();
    const nowPlusFiveMinutes = addMinutes(new Date(now), 5);
    const startDateObj = new Date(startDate);

    const endDateWithAddedDuration = addSeconds(
      startDateObj,
      durationChipValue
    );
    const checkBlockerWhenCustom =
      endDateWithAddedDuration >= localTimeOfNextCredential;

    const isBlocked = Boolean(
      (startDateOfNextCredential &&
        endDate &&
        new Date(endDate) >= localTimeOfNextCredential) ||
        (startDateOfNextCredential &&
          isDurationCustom &&
          durationChipValue &&
          checkBlockerWhenCustom)
    );

    setIsTimeBlocked(isBlocked);
    setIsWarning(isBlocked);

    if (startDateOfNextCredential) {
      const startDateOfNextCredentialDate = new Date(startDateOfNextCredential);
      const isNowPlusFiveBlocked = isBefore(
        nowPlusFiveMinutes,
        startDateOfNextCredentialDate
      );

      setIsNowBlocked(!isNowPlusFiveBlocked);
    } else {
      setIsNowBlocked(false);
    }
  }, [
    endDate,
    isTimeBlocked,
    durationChipValue,
    startDate,
    startDateOfNextCredential,
    isDurationCustom,
  ]);

  return (
    <div className='TimeAccessDuration'>
      <InlineMessage
        kind='background'
        text={nowConflictWarning(formattedWarningDate)}
        colorTheme='warning'
        leftIcon={<Icon kind='Warning' />}
        isVisible={isWarning || isTimeBlocked}
        onDismiss={onWarningClose}
        linkComponent={
          <ViewScheduleLink
            setIsScheduleViewOpen={setIsScheduleViewOpen}
            kind='warning'
          />
        }
      />
      <ToggleButtonGroup
        selection={toggleChoice}
        setSelection={setToggleChoice}>
        <ToggleButton label='Now' isDisabled={isNowBlocked} />
        <ToggleButton label='Future' />
      </ToggleButtonGroup>
      {getDatePickerType('start')}
      <DurationChips
        isNowTab={isNowTab}
        startDate={startDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        isDurationCustom={isDurationCustom}
        setIsDurationCustom={setIsDurationCustom}
        durationChipValue={durationChipValue}
        setDurationChipValue={setDurationChipValue}
        setDurationContinue={setDurationContinue}
        startDateOfNextCredential={startDateOfNextCredential}
      />
      <CustomDuration
        isNowTab={isNowTab}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        rangeMaxDate={rangeMaxDate}
        rangeMinDate={rangeMinDate}
        isDurationCustom={isDurationCustom}
        setIsDurationCustom={setIsDurationCustom}
        setDurationChipValue={setDurationChipValue}
        customDurationInputValue={customDurationInputValue}
        setCustomDurationInputValue={setCustomDurationInputValue}
        intervalOptions={intervalOptions}
        setIntervalOptions={setIntervalOptions}
        setDurationContinue={setDurationContinue}
        isCustomUnderFiveMin={isCustomUnderFiveMin}
        userMaxTime={accountSelection.maxtime}
      />
      {getDatePickerType('end')}
      <DurationContinueButton
        isNowTab={isNowTab}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        isDurationCustom={isDurationCustom}
        setDurationChipValue={setDurationChipValue}
        customDurationInputValue={customDurationInputValue}
        durationChipValue={durationChipValue}
        setDurationContinue={setDurationContinue}
        isTimeBlocked={isTimeBlocked}
      />
    </div>
  );
}

TimeAccessDuration.propTypes = {
  toggleChoice: PropTypes.number.isRequired,
  setToggleChoice: PropTypes.func.isRequired,
  startDateOfNextCredential: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  setDurationContinue: PropTypes.func,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  isTimeBlocked: PropTypes.bool.isRequired,
  setIsTimeBlocked: PropTypes.func.isRequired,
  setIsScheduleViewOpen: PropTypes.func.isRequired,
  accountSelection: PropTypes.shape({
    value: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    maxtime: PropTypes.string,
  }),
};

TimeAccessDuration.defaultProps = {
  startDateOfNextCredential: null,
  startDate: null,
  endDate: null,
  setDurationContinue: () => {},
  setStartDate: () => {},
  setEndDate: () => {},
  accountSelection: null,
};

export default React.memo(TimeAccessDuration);
