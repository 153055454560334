import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ButtonCore from '../../Button/ButtonCore/ButtonCore';
import Icon from '../../Icon/Icon';

import './AccordionStepper.css';

const ACCORDION_SHIFT_OFFSET = 8;
const PIXELS_PER_REM = 16;
const OVERFLOW_VISIBLE_STYLE_DELAY = 250;

function AccordionStepper({
  isDarkMode,
  isExpanded,
  isCritical,
  setIsExpanded,
  prefixIconKind,
  headerText,
  primarySupportingText,
  secondarySupportingText,
  isShowingSupportingTextOnExpanded,
  accordionValue,
  suffixLink,
  children,
  buttonClassName,
  contentPanelClassName,
  criticalMessage,
  dataTestId,
}) {
  const contentPanelRef = useRef(null);

  const setExpandedClass = (content) =>
    `AccordionStepper-${content} ${
      isExpanded && `AccordionStepper-${content}--isExpanded`
    }`;

  const showingTextClass = !isShowingSupportingTextOnExpanded
    ? 'AccordionStepper-supportingContent--isShowingSupportingTextOnExpanded'
    : null;
  const secondaryTextClass =
    secondarySupportingText && !isExpanded && accordionValue
      ? 'AccordionStepper-supportingContent--hasSecondaryText'
      : null;

  const articleClasses = classnames(
    'AccordionStepper-article',
    isCritical && 'AccordionStepper-article--isCritical',
    secondarySupportingText &&
      accordionValue &&
      'AccordionStepper-article--hasSecondaryText'
  );

  const buttonClasses = classnames(
    setExpandedClass('button'),
    secondarySupportingText &&
      accordionValue &&
      'AccordionStepper-button--hasSecondaryText',
    buttonClassName
  );

  const supportingContentClasses = classnames(
    setExpandedClass('supportingContent'),
    showingTextClass,
    secondaryTextClass
  );

  const contentPanelClasses = classnames(
    setExpandedClass('contentPanel'),
    contentPanelClassName
  );

  const contentPanelWrapperClasses = classnames(
    'AccordionStepper-contentPanelWrapper',
    isExpanded
      ? `AccordionStepper-contentPanelWrapper--isExpanded`
      : `AccordionStepper-contentPanelWrapper--isNotExpanded`
  );

  const prefixIconClasses = setExpandedClass('prefixIcon');
  const bodyContentClasses = setExpandedClass('bodyContent');
  const headerTextClasses = setExpandedClass('header');

  useEffect(() => {
    const recalculateHeight = () => {
      if (contentPanelRef.current) {
        const height = contentPanelRef.current.scrollHeight;
        const heightInRem = height / PIXELS_PER_REM;

        contentPanelRef.current.style.maxHeight = `${
          heightInRem + ACCORDION_SHIFT_OFFSET
        }rem`;

        if (isExpanded) {
          // Set a timeout to change the overflow style after 0.25s when expanding
          const timeoutId = setTimeout(() => {
            contentPanelRef.current.style.overflow = 'visible';
          }, OVERFLOW_VISIBLE_STYLE_DELAY);

          return () => clearTimeout(timeoutId);
        }

        contentPanelRef.current.style.overflow = 'hidden';
      }
    };

    recalculateHeight();

    const observer = new MutationObserver(recalculateHeight);

    // subtree:true observes grandchildren (dynamically created children)
    observer.observe(contentPanelRef.current, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, [isExpanded]);

  const renderedSupportingText = () => (
    <div className={supportingContentClasses}>
      {/* Primary Text or Accodrion Value */}
      {accordionValue && !isExpanded ? (
        <div className='AccordionStepper-accordionValue'>{accordionValue}</div>
      ) : (
        <p className='AccordionStepper-supportingText'>
          {primarySupportingText}
        </p>
      )}
      {/* Secondary Text */}
      {!isExpanded && secondarySupportingText && accordionValue ? (
        <div className='AccordionStepper-supportingText'>
          {secondarySupportingText}
        </div>
      ) : null}
    </div>
  );

  return (
    <article className='AccordionStepper-inlineBox'>
      <div className={articleClasses} data-testid={dataTestId}>
        <ButtonCore
          type='button'
          ariaExpanded={isExpanded ? 'true' : 'false'}
          className={buttonClasses}
          onClick={() => setIsExpanded(!isExpanded)}
          dataTestId={`${dataTestId}-button`}>
          <div className={prefixIconClasses}>
            <Icon kind={prefixIconKind} />
          </div>
          <div className={bodyContentClasses}>
            <h4 className={headerTextClasses}>{headerText}</h4>
            {renderedSupportingText()}
          </div>
          {/* Optional Link is only visible when expanded */}
          {isExpanded && suffixLink}
          {!isExpanded && (
            <div className='AccordionStepper-suffixIcon'>
              <Icon kind='edit' />
            </div>
          )}
        </ButtonCore>
        {/* Accordion Content Panel */}
        <section ref={contentPanelRef} className={contentPanelWrapperClasses}>
          <div
            className={contentPanelClasses}
            data-testid={`${dataTestId}-content-panel`}>
            {children}
          </div>
        </section>
      </div>
      {criticalMessage}
    </article>
  );
}

AccordionStepper.propTypes = {
  isDarkMode: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isCritical: PropTypes.bool,
  setIsExpanded: PropTypes.func,
  prefixIconKind: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  primarySupportingText: PropTypes.string.isRequired,
  secondarySupportingText: PropTypes.string,
  isShowingSupportingTextOnExpanded: PropTypes.bool,
  accordionValue: PropTypes.node,
  suffixLink: PropTypes.node,
  children: PropTypes.node,
  buttonClassName: PropTypes.string,
  contentPanelClassName: PropTypes.string,
  criticalMessage: PropTypes.node,
  dataTestId: PropTypes.string,
};

AccordionStepper.defaultProps = {
  isDarkMode: false,
  isExpanded: false,
  isCritical: false,
  setIsExpanded: () => {},
  secondarySupportingText: null,
  isShowingSupportingTextOnExpanded: false,
  accordionValue: null,
  suffixLink: null,
  children: null,
  buttonClassName: null,
  contentPanelClassName: null,
  criticalMessage: null,
  dataTestId: null,
};

export default AccordionStepper;
