import fetchIntercept from 'fetch-intercept';
import { push } from 'react-router-redirect';
import { ShowLoader, HideLoader } from '../utils/Loading-actions';
import { ShowMessageModal } from 'ui-common/src/utils/ResponseHandler-actions';
import { Cookies } from 'react-cookie';
// import store from 'misc/store';
// import { ecmLogoutAction } from 'ui-common/src/utils/LoginActions';
import { checkEnvironment, ecmLogin } from 'ui-common/src/lib/utils';
import configData from 'ui-common/src/config';
import { hideErrorNotification } from '../utils/apiUtils';
import { setSecureCookies } from 'ui-common/src/utils/common';

const cookies = new Cookies();

let reqCount = 0;
let resCount = 0;
let showLoaderFunc;

function getECMTokenFromRefreshToken() {
  const { ECM_URL } = checkEnvironment();
  const formData = new FormData();
  formData.append('grant_type', 'refresh_token');
  formData.append('refresh_token', cookies.get('refresh_token'));
  fetch(`${ECM_URL}/oauth/access_token_withissuer`, {
    method: 'POST',
    body: formData,
    credentials: 'include',
  })
    .then(response => {
      if (response.status === 200) return response.json();
      // 401 && 403 are handled
      if (response.status === 401 || response.status === 403)
        throw new Error('Refresh Token expired');
      return new Error('something went wrong fetching refresh token');
    })
    .then(data => {
      setSecureCookies('token_type', data.token_type);
      setSecureCookies('access_token', data.access_token);
      window.location.reload();
    })
    .catch(e => {
      cookies.remove('access_token', { path: '/' });
      cookies.remove('refresh_token', { path: '/' });
      cookies.remove('token_type', { path: '/' });
      cookies.remove('user_name', { path: '/' });
      cookies.remove('userKey', { path: '/' });
      cookies.remove('first_name', { path: '/' });
      window.store.dispatch({ type: 'mainlayout/ECM_LOGOUT' });
      push('/sessiontimeout');
      window.location.reload();
    });
}
// not used
function getTokenSSO() {
  const SERVICE_ENDPOINT_PAMMS = checkEnvironment().PAMMS_API_URL;
  fetch(`${SERVICE_ENDPOINT_PAMMS}/gettoken`, {
    method: 'GET',
    headers: {
      Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
    },
  })
    .then(response => response.text())
    .then(data => {
      cookies.set('access_token', data, {
        path: '/',
      });
      window.location.reload();
    })
    .catch(() => {
      cookies.remove('access_token', { path: '/' });
      cookies.remove('refresh_token', { path: '/' });
      cookies.remove('token_type', { path: '/' });
      cookies.remove('user_name', { path: '/' });
      cookies.remove('userKey', { path: '/' });
      cookies.remove('first_name', { path: '/' });
      push('/sessiontimeout');
    });
}
function handleLoader() {
  reqCount = 0;
  resCount = 0;
  window.store.dispatch(HideLoader());
  clearTimeout(showLoaderFunc);
}

const unregister = fetchIntercept.register({
  request(url, config) {
    const SERVICE_ENDPOINT_PAMMS = checkEnvironment().PAMMS_API_URL;
    const gridApiEndpoints = [
      `${SERVICE_ENDPOINT_PAMMS}/privilegedinstance/v2/list`,
      `${SERVICE_ENDPOINT_PAMMS}/privilegedinstance/sessions`,
      `${SERVICE_ENDPOINT_PAMMS}/privilegedinstance/v2/list`,
    ];
    if (
      config.headers &&
      url.indexOf('/api/auth/token') === -1 &&
      url.indexOf('/oauth/access_token_withissuer') === -1 &&
      url.indexOf('/login/ping') === -1
    ) {
      config.headers.Authorization = `${cookies.get('token_type')} ${cookies.get('access_token')}`;
    }
    if (gridApiEndpoints.includes(url)) return [url, config];
    // Show Loading
    if (!config.headers) config.headers = {};
    if (reqCount === 0 && !config.headers['X-NOLOADER']) {
      showLoaderFunc = setTimeout(() => {
        window.store.dispatch(ShowLoader());
      }, configData.loaderMinDelay);
    }
    reqCount += 1;

    // Modify the url or config here
    return [url, config];
  },
  requestError(error) {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },
  response(response) {
    // Hide Loading
    const SERVICE_ENDPOINT_PAMMS = checkEnvironment().PAMMS_API_URL;
    const gridApiEndpoints = [
      `${SERVICE_ENDPOINT_PAMMS}/privilegedinstance/v2/list`,
      `${SERVICE_ENDPOINT_PAMMS}/privilegedinstance/sessions`,
      `${SERVICE_ENDPOINT_PAMMS}/privilegedinstance/v2/list`,
    ];
    window.fetchResponse = response;
    if (!gridApiEndpoints.includes(response.url)) {
      resCount += 1;
      if (reqCount === resCount) {
        handleLoader();
      }
    }
    // Modify the reponse object

    if (response.status === 401 && response.url.indexOf('/api/auth/login') === -1) {
      if (
        cookies.get('access_token') &&
        cookies.get('refresh_token') &&
        response.url.indexOf('/oauth/access_token') === -1
      ) {
        if (!checkEnvironment().SSO_LOGIN) {
          return getECMTokenFromRefreshToken();
        }
      }
      // push('/login');
      cookies.remove('access_token', { path: '/' });
      cookies.remove('refresh_token', { path: '/' });
      cookies.remove('token_type', { path: '/' });
      cookies.remove('user_name', { path: '/' });
      cookies.remove('userKey', { path: '/' });
      cookies.remove('first_name', { path: '/' });
      // push('/login');
      if (window.location.hostname === 'localhost' && !checkEnvironment().SSO_LOGIN) {
        push('/login');
      } else if (checkEnvironment().SSO_LOGIN === true) {
        // if sso login, we dont fetch new access token. we redirect to IDP login page.
        const { SSO_LOGIN_URL, CALLBACK_URL } = checkEnvironment();
        // CALLBACK_URL = 'http://127.0.0.1:3000/ECMv6/auth';
        window.location.href = `//${SSO_LOGIN_URL}?url=/request/requestHome&callbackurl=${CALLBACK_URL}`;
      } else if (checkEnvironment().SSO_LOGIN === 'ECM') {
        ecmLogin();
      } else {
        // window.store.dispatch(ecmLogoutAction());
        ecmLogin();
      }
      // window.location.reload();
      return null;
    }
    if (
      response.status !== 200 &&
      response.status !== 201 &&
      response.status !== 401 &&
      response.status !== 503 &&
      response.status !== 412
    ) {
      // return Promise.reject(error);
      // All Errors Handling
      // Cloning the response so that we can consume response again later in the Saga if required.
      response
        .clone()
        .json()
        .then(res => {
          // res.message = res.message ? res.message : 'An Error Occured';
          // res.error = res.error ? res.error : 'An Error Occured';
          let errorMessage = 'ErrorMessage.fetch.default';
          if (res.error) {
            errorMessage = res.error;
          } else if (res.message) {
            errorMessage = res.message;
          }
          if (!hideErrorNotification(response.url)) {
            window.store.dispatch(
              ShowMessageModal({
                message: errorMessage, // code to lookup in messages.
                data: res.data,
                encrypted: true, // extra error info like specific field.
                status: 'failure',
              })
            );
          }
        });
    } else if (response.status === 503) {
      window.store.dispatch(
        ShowMessageModal({
          message: 'ServiceUnavailable',
          ecrypted: true,
        })
      );
    }
    return response;
  },

  responseError(error) {
    // Hide Loading
    // resCount++;
    // if (reqCount === resCount) {
    //   handleLoader();
    // }

    if (error.message.includes('Failed to fetch')) {
      window.store.dispatch(
        ShowMessageModal({
          message: 'ServiceUnavailable',
          ecrypted: true,
          status: 'failure',
        })
      );
    }
    // Handle an fetch error
    return Promise.reject(error);
  },
});

export default unregister;
