import './SpfAccounts.css';

import { Box, Button, Icon } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ModuleDetails } from '../../../../models/PamModels';

const BUTTON_LABELS = {
  CHECKOUT_CREDENTIALS: 'Check Out Credentials',
  CONNECT_TO_SESSION: 'Connect to Session',
};

function SpfAccounts({ data, accountOnClick, dataTestId, className }) {
  const classes = classnames('SpfAccounts', className);
    const isCredentialed = data.pamType?.toUpperCase() === 'CREDENTIAL';
    const isCredentialless = data.pamType?.toUpperCase() === 'CREDENTIALLESS';

  const hasCredentialType = isCredentialed || isCredentialless;

  const renderBothButtons = () => (
    <Box className='SpfAccounts-twoButtons'>
      <Button
        type='button'
        kind='outlined'
        className='SpfAccounts-twoButtons--buttonStyle'
        onClick={() => accountOnClick()}
        leftIcon={
          <Icon
            kind='PasswordCredentialUnlock'
            size='smallMedium'
            color='neutral-100'
          />
        }>
        {BUTTON_LABELS.CHECKOUT_CREDENTIALS}
      </Button>
      <Button
        type='button'
        kind='outlined'
        className='SpfAccounts-twoButtons--buttonStyle'
        leftIcon={
          <Icon kind='ConnectPlug' size='smallMedium' color='neutral-100' />
        }>
        {BUTTON_LABELS.CONNECT_TO_SESSION}
      </Button>
    </Box>
  );

  return (
    <Box className={classes} dataTestId={dataTestId}>
      {hasCredentialType ? (
        <Button
          leftIcon={
            <Icon
              kind={isCredentialed ? 'PasswordCredentialUnlock' : 'ConnectPlug'}
              size='smallMedium'
              color='neutral-100'
            />
          }>
          {isCredentialed
            ? BUTTON_LABELS.CHECKOUT_CREDENTIALS
            : BUTTON_LABELS.CONNECT_TO_SESSION}
        </Button>
      ) : (
        renderBothButtons()
      )}
    </Box>
  );
}

SpfAccounts.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  accountOnClick: PropTypes.func,
  className: PropTypes.string,
};

SpfAccounts.defaultProps = {
  data: null,
  dataTestId: null,
  accountOnClick: null,
  className: null,
};

export default SpfAccounts;
