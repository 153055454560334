import codegen from 'codegen.macro';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Cookies } from 'react-cookie';
import {
  BrowserRouter as Router, Route, Redirect, withRouter, Switch,
} from 'react-router-dom';
import { Redirector } from 'react-router-redirect';
import { throttle } from 'lodash';
import { Route404, Global404 } from 'ui-components/src';

import { useSelector } from 'react-redux';
import { initialiseEnv, pingECM } from 'ui-common/src/lib/utils';
import Admin from 'ui-admin';
import CookieService from 'ui-common/src/utils/CookieService';
import { ACCESS_TOKEN } from 'ui-common/src/constants';
import { PAMRoutes } from '@saviynt/pam';
import { CertsRoutes } from '@saviynt/certs';
import { Login } from '../features/MainLayout/Login';
import { RequestHome } from '../features/MainLayout/RequestHome';
// eslint-disable-next-line
import { getModule } from './dynamic-modules';
import { Footer } from '../features/MainLayout/Footer';
import { Control } from '../features/MainLayout/Control';
import AuthRedirect from '../auth/AuthRedirect';
import UserLogout from '../logout/UserLogout';
import SomethingWentWrong from '../logout/SomethingWentWrong';
import SessionTimeout from '../logout/SessionTimeout';
import VersionPage from '../features/MainLayout/Version';
import Layout from '../Layout/Layout';
import LayoutWrapper from '../Layout/LayoutWrapper/LayoutWrapper';
import { Header } from '../features/MainLayout/Header';
import BrandingConfig from '../features/AdminHome/views/BrandingConfig';
import FallbackUIerror from './ErrorFallbackUI/FallbackUIerror';

const cookies = new Cookies();

const leftNavGen2 = window.localStorage.getItem('leftNavGen2');
const HeaderType = () => {
  if (cookies.get('leftNavGen2') === '0') {
    window.localStorage.setItem('menuOpened', 'false');
  }
  if (cookies.get('leftNavGen2') === '1') {
    return <Layout />;
  } if (cookies.get('leftNavGen2') === '0') return <Header />;

  return <FallbackUIerror />;
};

const PrivateRoute = withRouter(({ component: Component, location, ...rest }) => {
  window.throttledPingECM = throttle(pingECM, 2000, { trailing: false });
  useEffect(() => {
    window.throttledPingECM();
  }, [location]);

  const Child = (props) => ((cookies.get('leftNavGen2') === '1' && Component === HeaderType) || cookies.get('leftNavGen2') !== '1' ? (
    <Component {...props} />
  ) : (
    <LayoutWrapper>
      <Component {...props} />
    </LayoutWrapper>
  ));

  return (
    <Route
      render={(props) => (CookieService.get(ACCESS_TOKEN) !== undefined ? Child(props) : null)}
      {...rest}
    />
  );
});

const FooterType = () => <div>{cookies.get('leftNavGen2') === '1' ? null : <Footer />}</div>;

const RedirectRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (CookieService.get(ACCESS_TOKEN) === undefined ? (
      <Component {...props} />
    ) : (
      <Redirect to="/request/requestHome" />
    ))}
  />
);

RedirectRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

class DynamicModules extends React.Component {
  render() {
    const { location } = this.props;
    const loadedCSSClass = location.pathname.indexOf('mySessions') > 0 ? 'extendedTabHeight' : '';
    const loginClass = location.pathname === '/login' ? 'd-none' : '';
    const UserLogoutClass = location.pathname === '/logout' ? 'd-none' : '';
    const SessionTimeoutClass = location.pathname === '/sessiontimeout' ? 'd-none' : '';

    return (
      <div
        className={`dynamicModules ${loadedCSSClass} ${loginClass} ${UserLogoutClass} ${SessionTimeoutClass}`}
      >
        {codegen.require('./dynamicRouteCodegen')}
      </div>
    );
  }
}

DynamicModules.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

const WithRuterModule = withRouter(DynamicModules);
const Routes = (props) => {
  const allowedRoutes = useSelector((state) => state.user.userModules);

  let blockedUrlList = useSelector((state) => state.user.blockedUrlList);

  blockedUrlList = blockedUrlList
    ? blockedUrlList.map((r) => {
      if (r) return r.replace('/ECMv6', '');

      return r;
    })
    : null;
  const blockedNames = useSelector((state) => state.user.blockedNames);
  const domainInfo = useSelector((state) => state.user.domainInfo);
  const [allowedReactRoutes, setAllowedReactRoutes] = useState(['/login']);

  useEffect(() => {
    if (domainInfo && Object.keys(domainInfo).length) {
      initialiseEnv(domainInfo);
    }
  }, [domainInfo]);
  useEffect(() => {
    if (allowedRoutes.length === 0) {
      return;
    }

    const allowedFilteredRoutes = allowedRoutes.map((r) => {
      if (r) {
        return r.replace('/ECMv6', '');
      }

      return r;
    });

    setAllowedReactRoutes([...allowedFilteredRoutes]);
  }, [allowedRoutes]);

  return (
    <Router basename="/ECMv6">
      <Route404
        routes={{
          staticRoutes: allowedReactRoutes,
          dynamicRoutes: [
            '/request/privilegedAccess/launch',
            '/versionpage',
            '/',
            '/pam',
            '/certs',
          ],
          blockedUrlList,
          blockedNames,
        }}
        render={(path) => <Global404 path={path} />}
      >
        <PrivateRoute
          exact
          path="/"
          render={(args) => <Redirect to="/request/requestHome" {...args} />}
        />
        <Redirector />
        <PrivateRoute path="/pam" component={PAMRoutes} />
        <PrivateRoute path="/certs" component={CertsRoutes} />
        <PrivateRoute path="/request" component={HeaderType} />
        <PrivateRoute path="/review" component={HeaderType} />
        <PrivateRoute path="/design" component={HeaderType} />
        <PrivateRoute path="/control" component={HeaderType} />
        <PrivateRoute path="/campaign" component={HeaderType} />
        <PrivateRoute path="/admin" component={HeaderType} />
        <PrivateRoute path="/identityMatchAndMerge/home" component={HeaderType} />
        <RedirectRoute exact path="/login" component={Login} />
        <PrivateRoute exact path="/request/requestHome" component={RequestHome} />
        <PrivateRoute exact path="/versionpage" component={VersionPage} />
        <Route exact path="/auth" component={AuthRedirect} />
        <RedirectRoute exact path="/logout" component={UserLogout} />
        <RedirectRoute exact path="/somethingwentwrong" component={SomethingWentWrong} />
        <RedirectRoute exact path="/sessiontimeout" component={SessionTimeout} />
        <WithRuterModule {...props} />
        <Switch>
          <PrivateRoute exact path="/admin/UIBranding" component={BrandingConfig} />
          <PrivateRoute path="/admin" component={Admin} />
        </Switch>
        <PrivateRoute path="/request" component={FooterType} />
        <PrivateRoute path="/review" component={FooterType} />
        <PrivateRoute path="/design" component={FooterType} />
        <PrivateRoute path="/control" component={Control} />
        <PrivateRoute path="/campaign" component={FooterType} />
        <PrivateRoute path="/identityMatchAndMerge/home" component={FooterType} />
      </Route404>
    </Router>
  );
};

export default Routes;
