/* eslint-disable react/jsx-curly-spacing */
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import useScrollbarWidth from '../../../hooks/useScrollbarWidth';
import Button from '../../Button/Button';
import ButtonIcon from '../../ButtonIcon/ButtonIcon';
import Icon from '../../Icon/Icon';

import './ModalIsland.css';

// TODO: Fix the small modal jump on open

const KINDS = {
  default: 'default',
  question: 'question',
  success: 'success',
  warning: 'warning',
  critical: 'critical',
};

function ModalIsland({
  kind,
  isOpen,
  onClose,
  headerIcon,
  title,
  subtitle,
  alertTitle,
  alertSubtitle,
  contentBody,
  sectionRef,
  primaryButton,
  secondaryButton,
  FooterActionBarComp,
  className,
}) {
  const modalRef = useRef(null);

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  };

  /** focusOnlyModalIslandElements makes the tab order locked to only the focusable components inside the modalIsland */

  const focusOnlyModalIslandElements = (e) => {
    const focusableElements = modalRef.current.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    if (e.keyCode === 9) {
      if (e.shiftKey) {
        if (document.activeElement === firstElement) {
          lastElement.focus();
          e.preventDefault();
        }
      } else if (document.activeElement === lastElement) {
        firstElement.focus();
        e.preventDefault();
      }
    }
  };

  // Get the browser scrollBar width
  useScrollbarWidth();

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('ModalIsland--isOpen--bodyScrollLock');
      document.addEventListener('keydown', focusOnlyModalIslandElements);
      const closeButton = document.getElementById('modalCloseButton-true');

      closeButton?.focus();
    } else {
      document.body.classList.remove('ModalIsland--isOpen--bodyScrollLock');
    }

    return () => {
      document.body.classList.remove('ModalIsland--isOpen--bodyScrollLock');
      document.removeEventListener('keydown', focusOnlyModalIslandElements);
    };
  }, [isOpen]);

  const overlayBgClasses = classnames(
    'ModalIsland-background',
    isOpen && `ModalIsland-background--isOpen`
  );

  const modalIslandClasses = classnames(
    'ModalIsland',
    `ModalIsland-${kind}`,
    isOpen && 'ModalIsland--isOpen',
    className
  );

  const headerClasses = classnames(
    'ModalIsland-header',
    subtitle && `ModalIsland-header--hasSubtitle`
  );

  const alertModalIslandClasses = classnames(
    'ModalIsland-alert',
    `ModalIsland-alert-${kind}`
  );
  const alertBgClasses = classnames(
    'ModalIsland-alertBg',
    `ModalIsland-alertBg-${kind}`
  );

  const getAlertIslandIcon = () => {
    switch (kind) {
      case 'question':
        return (
          <Icon
            kind='HelpFilledSeparateColors'
            className='ModalIsland-alertContent-icon'
          />
        );
      case 'success':
        return (
          <Icon
            kind='CheckCircleFilledSeparateColors'
            className='ModalIsland-alertContent-icon'
          />
        );
      case 'warning':
        return (
          <Icon
            kind='AlertWarningFilledSeparateColors'
            className='ModalIsland-alertContent-icon'
          />
        );
      case 'critical':
        return (
          <Icon
            kind='AlertCriticalFilledSeparateColors'
            className='ModalIsland-alertContent-icon'
          />
        );
      default:
        return null;
    }
  };

  return ReactDOM.createPortal(
    <>
      <div className={overlayBgClasses} />
      <article ref={modalRef} id='ModalIsland' className={modalIslandClasses}>
        {kind === 'default' ? (
          <section className='ModalIsland-section'>
            <header className={headerClasses}>
              <div className='ModalIsland-titleAndHeaderIcon'>
                {headerIcon}
                <div className='ModalIsland-titleContainer'>
                  <h2 className='ModalIsland-title'>{title}</h2>
                  {subtitle && (
                    <div className='ModalIsland-subtitle'>{subtitle}</div>
                  )}
                </div>
              </div>
              <ButtonIcon
                id={`modalCloseButton-${isOpen}`}
                size='medium'
                kind='ghost'
                icon={
                  <Icon kind='close' color='neutral-100' size='smallMedium' />
                }
                onClick={() => {
                  onClose();
                }}
              />
            </header>
            <section className='ModalIsland-body' ref={sectionRef}>
              {contentBody}
            </section>
            {FooterActionBarComp}
          </section>
        ) : (
          <section className={alertModalIslandClasses}>
            <ButtonIcon
              id={`modalCloseButton-${isOpen}`}
              size='medium'
              kind='ghost'
              className='ModalIsland-alertCloseButton'
              icon={
                <Icon kind='close' color='neutral-100' size='smallMedium' />
              }
              onClick={() => {
                onClose();
              }}
            />
            {/* SVG background using Icon comp */}
            <Icon
              kind='AlertModalBackground'
              color='neutral-100'
              className={alertBgClasses}
            />
            <div className='ModalIsland-alertContent'>
              {getAlertIslandIcon()}
              <div className='ModalIsland-alertContent-body'>
                <h2
                  className={`ModalIsland-alertTitle ModalIsland-alertTitle-${kind}`}>
                  {alertTitle}
                </h2>
                <div className='ModalIsland-alertSubtitle'>{alertSubtitle}</div>
              </div>
              <div className='ModalIsland-alertContent-controlsContainer'>
                <div className='ModalIsland-alertContent-controls'>
                  {primaryButton}
                  {secondaryButton}
                </div>
              </div>
            </div>
          </section>
        )}
      </article>
    </>,
    document.body
  );
}

ModalIsland.propTypes = {
  kind: PropTypes.oneOf(Object.values(KINDS)),
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  headerIcon: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  alertTitle: PropTypes.string,
  alertSubtitle: PropTypes.string,
  contentBody: PropTypes.node,
  sectionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  primaryButton: PropTypes.node,
  secondaryButton: PropTypes.node,
  FooterActionBarComp: PropTypes.node,
};

ModalIsland.defaultProps = {
  kind: KINDS.default,
  headerIcon: null,
  title: '',
  subtitle: null,
  alertTitle: '',
  alertSubtitle: '',
  contentBody: null,
  sectionRef: null,
  primaryButton: (
    <Button type='button' kind='outlined' size='medium'>
      Primary Button
    </Button>
  ),
  secondaryButton: (
    <Button type='button' kind='ghost' size='medium'>
      Cancel
    </Button>
  ),
  FooterActionBarComp: null,
};

export default ModalIsland;
