import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ButtonIcon from '../../ButtonIcon/ButtonIcon';
import Icon from '../../Icon/Icon';

import './UploadedFile.css';

/**
 * Component for displaying an uploaded file.
 *
 * @component
 * @param {string} fileName - name of the uploaded file.
 * @param {string} fileSize - size of the uploaded file.
 * @param {Function} onReupload - function to handle reuploading the file.
 * @param {Function} onDelete - function to handle deleting the file.
 * @param {string} [error] - error message, if any.
 * @param {string} [dataTestId] - data test ID for testing purposes.
 *
 * @returns {JSX.Element} The rendered component.
 */
function UploadedFile({
  fileName,
  fileSize,
  onReupload,
  onDelete,
  error,
  dataTestId,
}) {
  return (
    <section className='UploadedFile-container'>
      <div
        className={classnames('UploadedFile', error && 'UploadedFile--error')}
        data-testid={dataTestId}>
        <Icon kind='document' />
        <div className='UploadedFile-nameSizeContainer'>
          <span className='UploadedFile-fileName'>{fileName}</span>
          <span className='UploadedFile-fileSize'>{fileSize}</span>
        </div>
        <div className='UploadedFile-actionIcons'>
          <ButtonIcon
            size='large'
            kind='outlinedSecondary'
            icon={<Icon kind='retry' color='neutral-100' size='xSmall' />}
            onClick={onReupload}
          />
          <ButtonIcon
            size='large'
            kind='outlinedSecondary'
            icon={<Icon kind='delete' color='neutral-100' size='xSmall' />}
            onClick={onDelete}
          />
        </div>
      </div>
      {error && (
        <div className='UploadedFile-error'>
          <Icon
            kind='AlertCritical'
            size='xSmall'
            className='UploadedFile-errorIcon'
          />
          <span className='UploadedFile-errorMessage'>{error}</span>
        </div>
      )}
    </section>
  );
}

UploadedFile.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.string.isRequired,
  onReupload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  error: PropTypes.string,
  dataTestId: PropTypes.string,
};

UploadedFile.defaultProps = { error: null, dataTestId: null };

export default UploadedFile;
