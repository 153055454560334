import React from 'react';
import PropTypes from 'prop-types';

/**
 * 'Box' is a simple wrapper div for components and page elements.
 * It intentionally only takes a className prop at this time.
 */
function Box({ children, dataTestId, className }) {
  return (
    <div className={className} data-testid={dataTestId}>
      {children}
    </div>
  );
}

Box.propTypes = {
  children: PropTypes.node,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

Box.defaultProps = {
  children: null,
  dataTestId: null,
  className: null,
};

export default Box;
