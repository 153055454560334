import { getCurrentDate } from '@saviynt/common';
import { Box, Chip, Typography } from '@saviynt/design-system';
import { addSeconds, differenceInSeconds, isValid, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { SECONDS_PER_MINUTE } from '../../../../../utilities/timeConstants';

const chipOptions = [
  { value: 30 * SECONDS_PER_MINUTE, label: '30 min' },
  { value: 60 * SECONDS_PER_MINUTE, label: '1 hour' },
  { value: 120 * SECONDS_PER_MINUTE, label: '2 hours' },
  { value: 240 * SECONDS_PER_MINUTE, label: '4 hours' },
  { value: 480 * SECONDS_PER_MINUTE, label: '8 hours' },
];

function DurationChips({
  isNowTab,
  startDate,
  setStartDate,
  setEndDate,
  isDurationCustom,
  setIsDurationCustom,
  durationChipValue,
  setDurationChipValue,
  setDurationContinue,
  startDateOfNextCredential,
}) {
  const [startToFirstSession, setStartToFirstSession] = useState(null);

  useEffect(() => {
    const startDateObj = new Date(startDate);

    if (isValid(startDateObj) && isValid(parseISO(startDateOfNextCredential))) {
      setStartToFirstSession(
        differenceInSeconds(parseISO(startDateOfNextCredential), startDateObj)
      );
    }
  }, [startDateOfNextCredential, startDate]);

  const handleChipSelection = (time) => {
    if (isNowTab && time) {
      setIsDurationCustom(false);
      setDurationChipValue(time);
      setStartDate(getCurrentDate());
      setEndDate(getCurrentDate(time));
      setDurationContinue(true);
    } else if (!time) {
      setIsDurationCustom(true);
      setDurationChipValue(0);
    } else if (!isNowTab && time) {
      setIsDurationCustom(false);
      setDurationChipValue(time);
      if (startDate) setEndDate(addSeconds(startDate, time));
    }
  };

  return (
    <Box className='TimeAccessDuration-durationChips-container'>
      <Typography kind='label' className='TimeAccessDuration-durationLabel'>
        Duration
      </Typography>
      <Box className='TimeAccessDuration-durationChips'>
        {chipOptions.map(({ value, label }) => {
          const isChipSelected = durationChipValue === value;
          const chipKind = isChipSelected ? 'boldFilled' : 'outlined';
          const isDisabled =
            startToFirstSession !== null && value > startToFirstSession;

          return (
            <Chip
              key={value}
              kind={chipKind}
              label={label}
              isSelected={isChipSelected}
              onClick={() => handleChipSelection(value)}
              color='brandSecondary'
              isDisabled={isDisabled}
            />
          );
        })}
        <Box className='TimeAccessDuration-dividerLine' />
        <Chip
          kind={isDurationCustom ? 'boldFilled' : 'outlined'}
          label='Custom'
          isSelected={isDurationCustom}
          onClick={() => handleChipSelection(false)}
          color='brandSecondary'
        />
      </Box>
    </Box>
  );
}

DurationChips.propTypes = {
  isNowTab: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  isDurationCustom: PropTypes.bool.isRequired,
  setIsDurationCustom: PropTypes.func.isRequired,
  durationChipValue: PropTypes.number,
  setDurationChipValue: PropTypes.func.isRequired,
  setDurationContinue: PropTypes.func.isRequired,
  startDateOfNextCredential: PropTypes.string,
};

DurationChips.defaultProps = {
  startDate: null,
  durationChipValue: null,
  startDateOfNextCredential: null,
};

export default DurationChips;
