export const listAPIListReceivedActionType = 'listApi';
export const listRowSelectedActionType = 'listRowSelected';

export const listAPIListReceived = (data, success) => ({
  type: listAPIListReceivedActionType,
  payload: data,
  successMessage: success,
});

export const selectedListDetails = (data, success) => ({
  type: listRowSelectedActionType,
  payload: data,
  successMessage: success,
});
