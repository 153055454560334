import { ListRow } from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React from 'react';

import useCreateRowObject from '../../hooks/use-createrowobject';

const KINDS = {
  ENDPOINT: 'endpoint',
  SESSION: 'session',
};

function ListRowWrapper({
  kind,
  listItemObj,
  isCheckInModalOpen,
  setIsCheckInModalOpen,
  setIconClickedForRow,
  rowIsSelected,
  listRowOnClick,
  index,
  ENABLE_FOR_BETA,
  listRowData,
  setListRowData,
}) {
  const createObjectForRow = useCreateRowObject(
    listItemObj,
    isCheckInModalOpen,
    setIsCheckInModalOpen,
    setIconClickedForRow,
    listRowData,
    setListRowData
  );
  const listItem = createObjectForRow();

  return (
    <div
      className={rowIsSelected(listItemObj)
        ? 'LandingPage-listRowContainer--selected'
        : 'LandingPage-listRowContainer'}>
      <ListRow
        category={listItem.category}
        title={listItem.title}
        description={listItem.description}
        status={!rowIsSelected(listItemObj) && listItem.status}
        primaryIcon={!rowIsSelected(listItemObj) && listItem.primaryIcon}
        secondaryIcon={kind === KINDS.ENDPOINT
          ? ENABLE_FOR_BETA &&
              !rowIsSelected(listItemObj) &&
              listItem.secondaryIcon
          : !rowIsSelected(listItemObj) && listItem.secondaryIcon}
        hasMoreOptions={!rowIsSelected(listItemObj) && listItem.hasMoreOptions}
        hasBookmark={false}
        onRowClick={() => listRowOnClick(listItemObj)}
        className={rowIsSelected(listItemObj)
          ? 'LandingPage-listRow--selected'
          : 'LandingPage-listRow'}
        type={listItem.type}
        dataTestId={`${kind}-row-${index}`}
      />
    </div>
  );
}

ListRowWrapper.propTypes = {
  // listItemObj: PropTypes.shape({...}).isRequired, // TODO: add shape
  kind: PropTypes.string.isRequired,
  isCheckInModalOpen: PropTypes.bool.isRequired,
  setIsCheckInModalOpen: PropTypes.func.isRequired,
  setIconClickedForRow: PropTypes.func.isRequired,
  rowIsSelected: PropTypes.func.isRequired,
  listRowOnClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  ENABLE_FOR_BETA: PropTypes.bool.isRequired,
  listRowData: PropTypes.shape({
    result: PropTypes.shape({ userAccesses: PropTypes.instanceOf(Array).isRequired, }).isRequired,
    count: PropTypes.number.isRequired,
  }).isRequired,
  setListRowData: PropTypes.func.isRequired,
};
ListRowWrapper.defaultProps = {};

export default ListRowWrapper;
