import React, { cloneElement, useMemo, useRef, useState } from 'react';
import { styleTextBeforeToken } from '@saviynt/common';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import useOutsideClickEffect from '../../../hooks/use-outsideclickeffect';
import ApplicationIcon from '../../ApplicationIcon/ApplicationIcon';
import ButtonCore from '../../Button/ButtonCore/ButtonCore';
import Icon from '../../Icon/Icon';
import MenuMultiFooter from '../MenuFooter/MenuMultiFooter/MenuMultiFooter';
import MenuItemMulti from '../MenuItem/MenuItemMulti/MenuItemMulti';
import MenuMultiSearchBar from '../MenuSearchBar/MenuMultiSearchBar/MenuMultiSearchBar';

import './MenuMulti.css';

const DEFAULT_ICON_COLOR = 'neutral-1000';
const DISABLED_ICON_COLOR = 'neutral-400';

/**
 * MenuMulti renders a trigger and a multiselect dropdown list.
 *
 * #### WIP Todo (CPAM):
 * - Expand menu up/down logic.
 * - Custom scrollbar for long lists.
 * - Hovering over the menu item shows the checkbox hover effect? (confirm w/ design)
 */

function MenuMulti({
  options,
  label,
  trigger,
  onChange,
  sortOnCheck,
  appendLabelsToButton,
  minOptionsForSearch,
  minOptionsForFooter,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const [searchText, setSearchText] = useState(''); // for search bar

  const showSearchBar = options.length >= minOptionsForSearch;

  const wrapperRef = useRef(null);

  useOutsideClickEffect(wrapperRef, () => setIsOpen(false), isOpen);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    // toggle the isChecked property of the clicked option
    const updatedOptions = options.map((opt) =>
      opt.value === option.value ? { ...opt, isChecked: !opt.isChecked } : opt
    );

    // filter out only the options that are checked
    const newSelectedOptions = updatedOptions.filter((opt) => opt.isChecked);

    onChange(newSelectedOptions);
  };

  const handleSelectAll = () => {
    // set isChecked to true only for options that are not disabled.
    const newOptions = options.map((option) => ({
      ...option,
      // preserve the isChecked state if the option is disabled
      isChecked: option.isDisabled ? option.isChecked : true,
    }));

    onChange(newOptions.filter((opt) => opt.isChecked));
  };

  const handleClearAll = () => {
    const newOptions = options.map((option) => ({
      ...option,
      // don't modify disabled options. i.e., disabled and checked.
      isChecked: option.isDisabled ? option.isChecked : false,
    }));

    const remainingSelectedOptions = newOptions.filter(
      (opt) => opt.isDisabled && opt.isChecked
    );

    onChange(remainingSelectedOptions);
  };

  const sortedAndFilteredOptions = useMemo(() => {
    if (!sortOnCheck) {
      return options;
    }

    // clone the options array to avoid mutating props.
    const optionsClone = [...options];

    // perform the sort.
    optionsClone.sort(
      (a, b) =>
        (b.isChecked === true) - (a.isChecked === true) ||
        a.label.localeCompare(b.label)
    );

    // filter based on the search text
    const lowerSearchText = searchText.toLowerCase();

    return optionsClone.filter((option) =>
      option.label.toLowerCase().includes(lowerSearchText)
    );
  }, [options, sortOnCheck, searchText]);

  const renderSearchBar = () => (
    <MenuMultiSearchBar
      text={searchText}
      setText={setSearchText}
      tabIndex={isOpen ? 0 : -1}
      dataTestId='menumulti-searchbar'
    />
  );

  const renderFooter = () => {
    // consider disabled options in the calculations.
    const isSelectAllDisabled = options.every(
      (opt) => opt.isChecked || opt.isDisabled
    );
    const isClearDisabled = !options.some(
      (opt) => opt.isChecked && !opt.isDisabled
    );

    return (
      <MenuMultiFooter
        onSelectAll={handleSelectAll}
        onClear={handleClearAll}
        isClearDisabled={isClearDisabled}
        isSelectAllDisabled={isSelectAllDisabled}
        tabIndex={isOpen ? 0 : -1}
        dataTestId='menumulti-footer'
      />
    );
  };

  const renderTrigger = () => {
    const checkedLabels = options
      .filter((option) => option.isChecked)
      .map((option) => option.label)
      .join(', ');

    const displayText =
      appendLabelsToButton && checkedLabels?.length
        ? styleTextBeforeToken(
            `${label}: ${checkedLabels}`,
            ':',
            true,
            'u-button--isLabelAppended'
          )
        : label;

    return trigger ? (
      cloneElement(trigger, {
        label: displayText,
        isOpen,
        onClick: toggleDropdown,
        dataTestId: 'menumulti-trigger',
      })
    ) : (
      // TODO: style and allow as default trigger.
      <ButtonCore
        type='button'
        className='MenuMulti-buttonDefault'
        onClick={toggleDropdown}
        dataTestId='menumulti-trigger'
        aria-pressed={isOpen}>
        {displayText}
        {options.isApplicationIcon ? (
          <Icon kind='ChevronDown' rotate={isOpen ? '180' : null} />
        ) : (
          <ApplicationIcon kind='ChevronDown' rotate={isOpen ? '180' : null} />
        )}
      </ButtonCore>
    );
  };

  const sortedOptions = useMemo(() => {
    if (!options || options.length < 1) return [];
    if (!sortOnCheck) return options;

    // separate the options into two groups: checked and unchecked.
    const checkedOptions = options.filter((opt) => opt.isChecked);
    const uncheckedOptions = options.filter((opt) => !opt.isChecked);

    // sort both groups alphabetically.
    const sortedCheckedOptions = checkedOptions.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    const sortedUncheckedOptions = uncheckedOptions.sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    // merge the sorted groups, with checked options first.
    return [...sortedCheckedOptions, ...sortedUncheckedOptions];
  }, [options, sortOnCheck]);

  const renderMenuItems = () => {
    const optionsWithDefaults = sortedAndFilteredOptions.map((option) => ({
      ...option,
      prefixIcon: {
        ...option.prefixIcon,
        // If option.prefixIcon.color is not set and option is disabled, use gray, else use default color
        color:
          option?.prefixIcon?.color ??
          (option.isDisabled ? DISABLED_ICON_COLOR : DEFAULT_ICON_COLOR),
      },
    }));

    return optionsWithDefaults.map((option, index) => {
      const isSelected = option.isChecked;

      const isNextItemChecked =
        sortedOptions[index + 1] && sortedOptions[index + 1].isChecked;

      const isAllChecked = sortedOptions.every((opt) => opt.isChecked);

      const classnameProp =
        sortOnCheck && isSelected && !isNextItemChecked && !isAllChecked
          ? { className: 'MenuItemMulti-checkedDivider' }
          : {};

      classnameProp.className = `${classnameProp.className || ''}${
        !isOpen ? ' MenuItemMulti--isCollapsed' : ''
      }`;

      return (
        <React.Fragment key={option.value}>
          <MenuItemMulti
            key={option.value}
            option={option}
            onClick={() => {
              !option.isDisabled && handleOptionClick(option);
            }}
            isDisabled={option.isDisabled}
            tabIndex={isOpen ? 0 : -1}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...classnameProp}
          />
        </React.Fragment>
      );
    });
  };

  return (
    <div
      className={classnames(
        'MenuMulti',
        showSearchBar && 'MenuMulti--isSearchBarVisible'
      )}
      ref={wrapperRef}
      role='combobox'
      aria-controls='custom-select-listbox'
      aria-expanded={isOpen}
      aria-label={label}>
      {renderTrigger()}
      <div
        className={classnames(
          'MenuMulti-dropdown',
          isOpen && 'MenuMulti-dropdown--isVisible'
        )}>
        {showSearchBar && renderSearchBar()}
        <ul className='MenuMulti-items' role='listbox' tabIndex='-1'>
          {renderMenuItems()}
        </ul>
        {options.length >= minOptionsForFooter && renderFooter()}
      </div>
    </div>
  );
}

MenuMulti.propTypes = {
  label: PropTypes.string,
  trigger: PropTypes.element,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      isDisabled: PropTypes.bool,
      isChecked: PropTypes.bool,
      prefixIcon: PropTypes.shape({
        isApplicationIcon: PropTypes.bool,
        kind: PropTypes.string,
        size: PropTypes.string,
        color: PropTypes.string,
      }),
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  sortOnCheck: PropTypes.bool,
  appendLabelsToButton: PropTypes.bool,
  minOptionsForSearch: PropTypes.number,
  minOptionsForFooter: PropTypes.number,
};

MenuMulti.defaultProps = {
  label: '',
  trigger: null,
  sortOnCheck: true,
  appendLabelsToButton: true,
  minOptionsForSearch: 6,
  minOptionsForFooter: 6,
};

export default MenuMulti;
