import {
  INIT_STATE,
  GET_LOGS,
  LOADING_RESULTS,
  UPDATE_HAS_MORE_DATA,
  UPDATE_QUERY,
  GET_SELECTED_SERVICES,
  UPDATE_SCROLL_MARKER,
  APPEND_LOGS,
  PREPEND_LOGS,
  UPDATE_SELECTION,
  NO_SERVICE_SELECTED,
  LOADING_DATA,
  CLEAR_AND_SHOW_ERROR,
} from '../actions/logsAction';
import { serviceNamesMapping } from '../../constant';

// Before modifying the below numbers reach out to Foundation Team to double-check the math is right!
// noOfRecordsToStoreInMemory is multiples of noOfRecordsToQuery
export const defaultConfig = {
  noOfRecordsToQuery: 300,
  noOfRecordsToStoreInMemory: 900,
  noOfRecordsToDownload: 10000,
  noOfRecordsToView: 200, // Always try to keep it even number
};

export const defaultServices = (isAuthMSEnabled, isPAMServices) => serviceNamesMapping(isAuthMSEnabled, isPAMServices).map((service) => service.value);

export const initState = (
  config,
  results = [],
  isAuthMSEnabled = false,
  isPAMServicesEnabled = false,
) => ({
  results,
  resultsStartScrollIndex: 0,
  dataLoading: false,
  query: {
    searchKey: '',
    fromDate: '',
    toDate: '',
    recordsCount: config.noOfRecordsToQuery,
    servicename: defaultServices(isAuthMSEnabled, isPAMServicesEnabled),
  },
  selection: {
    fromDate: '',
    toDate: '',
  },
  services: defaultServices(isAuthMSEnabled, isPAMServicesEnabled),
  loading: false,
  hasMoreData: false,
  selectedServices: defaultServices(isAuthMSEnabled, isPAMServicesEnabled),
  scrollMarkers: [],
  noServiceSelected: false,
  isError: false,
});

export const logsReducer = (config) => {
  const getFilteredLogs = (results, prepend = false, resultsCount) => {
    const appendMarkersCount = results.filter((f) => f.appendMarker).length;
    const actionResultsCount = resultsCount + appendMarkersCount;
    const noOfRecordsToStoreInMemory = config.noOfRecordsToStoreInMemory - actionResultsCount;
    const isMoreDataToStore = results.length + config.noOfRecordsToQuery;

    if (isMoreDataToStore >= config.noOfRecordsToStoreInMemory) {
      if (prepend) {
        return results.filter((f, i) => i <= noOfRecordsToStoreInMemory && !f.appendMarker);
      }

      const indexToIncludeAfter = isMoreDataToStore - config.noOfRecordsToStoreInMemory;

      return results.filter((f, i) => i >= indexToIncludeAfter && !f.appendMarker);
    }

    return results.filter((f) => !f.appendMarker);
  };

  return (state = initState(config), action) => {
    switch (action.type) {
      case INIT_STATE:
        return initState(config, [], action.isAuthMSEnabled, action.isPAMServicesEnabled);

      case UPDATE_QUERY:
        return { ...state, query: action.query };

      case UPDATE_SELECTION:
        return { ...state, selection: action.selection };

      case LOADING_RESULTS:
        return { ...state, loading: action.loading };

      case LOADING_DATA:
        return { ...state, dataLoading: action.loading };

      case UPDATE_HAS_MORE_DATA:
        return { ...state, hasMoreData: action.hasMoreData };

      case APPEND_LOGS:
        return {
          ...state,
          results: [
            ...getFilteredLogs(state.results, false, action.results.length),
            ...action.results,
          ],
          loading: false,
          resultsStartScrollIndex:
            state.results.length < defaultConfig.noOfRecordsToStoreInMemory
              ? 0
              : state.resultsStartScrollIndex + defaultConfig.noOfRecordsToQuery,
        };

      case PREPEND_LOGS:
        return {
          ...state,
          results: [
            ...action.results,
            ...getFilteredLogs(state.results, true, action.results.length),
          ],
          loading: false,
          resultsStartScrollIndex: state.resultsStartScrollIndex - defaultConfig.noOfRecordsToQuery,
        };

      case GET_LOGS:
        return {
          ...state,
          results: action.results,
          scrollMarkers: action.results.length === 0 ? [] : [action.results[0].searchId],
          loading: false,
          resultsStartScrollIndex: 0,
          isError: false,
        };

      case GET_SELECTED_SERVICES:
        return { ...state, selectedServices: action.selectedServices };

      case UPDATE_SCROLL_MARKER:
        return { ...state, scrollMarkers: action.scrollMarkers };

      case NO_SERVICE_SELECTED:
        return { ...state, noServiceSelected: action.selected };

      case CLEAR_AND_SHOW_ERROR:
        return { ...state, results: [], isError: true };

      default:
        return state;
    }
  };
};

export default logsReducer(defaultConfig);
