import './SchedulerBlock.css';

import { getSchedulerTimeWindowString } from '@saviynt/common';
import { Box } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Session } from '../../../models/PamModels';

function SchedulerBlock({
  details,
  isCheckedOut,
  startTime,
  endTime,
  timeFormat,
  onClick,
  isModalView,
  dataTestId,
  className,
}) {
  const classes = classnames(
    'SchedulerBlock',
    isModalView && 'SchedulerBlock-modal',
    className
  );

  const blockStatus = details ? 'reserved' : 'available';

  const getReservationDetails = (reservationDetails) => (
    <Box className='SchedulerBlock-reserved-detailsText'>
      <span className='SchedulerBlock-reserved-detailsText-action'>
        {isCheckedOut ? 'Checked out' : 'Requested'}
      </span>
      {` · by ${reservationDetails.firstname || ''} ${reservationDetails.lastname || ''} (${reservationDetails.username  || ''}) · ${reservationDetails.requestId}`}
    </Box>
  );

  return (
    <section className={classes} data-testid={dataTestId} onClick={onClick}>
      <section className={`SchedulerBlock-${blockStatus}`}>
        <div className={`SchedulerBlock-${blockStatus}-content`}>
          <div className='SchedulerBlock-timeRange'>
            <span className={`SchedulerBlock-${blockStatus}-timeRange`}>
              {`${getSchedulerTimeWindowString(
                startTime,
                timeFormat.roundToNearest,
                timeFormat.is24HourFormat
              )} - ${getSchedulerTimeWindowString(
                endTime,
                timeFormat.roundToNearest,
                timeFormat.is24HourFormat
              )}`}
            </span>
          </div>
          <span className={`SchedulerBlock-${blockStatus}-details`}>
            {details ? getReservationDetails(details) : 'Available for request'}
          </span>
        </div>
      </section>
    </section>
  );
}

SchedulerBlock.propTypes = {
  details: Session,
  isCheckedOut: PropTypes.bool,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  timeFormat: PropTypes.shape({
    roundToNearest: PropTypes.string,
    is24HourFormat: PropTypes.bool,
  }).isRequired,
  isModalView: PropTypes.bool,
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SchedulerBlock.defaultProps = {
  details: null,
  isCheckedOut: false,
  onClick: () => {},
  isModalView: false,
  dataTestId: null,
  className: null,
};

export default SchedulerBlock;
