/* eslint-disable react/jsx-wrap-multilines */
import './AccountListModalIsland.css';

import {
  Box,
  Button,
  FooterActionBar,
  Icon,
  InputField,
  ModalIsland,
  RadioButton,
} from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

function AccountListModalIsland({
  accountListArray,
  accountListModalContentRef,
  sectionRefIsScrolled,
  accountSelection,
  setAccountSelection,
  isAccountListModal,
  setIsAccountListModal,
}) {
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [modalAccountSelection, setModalAccountSelection] = useState(null);

  useEffect(() => {
    setModalAccountSelection(accountSelection);
  }, [accountSelection]);

  useEffect(() => {
    setFilteredData(accountListArray);
  }, [accountListArray]);

  useEffect(() => {
    setSearchValue('');
  }, [isAccountListModal]);

  useEffect(() => {
    const searchString = searchValue.toLowerCase();

    setSearchValue(searchString);

    const filteredArray = accountListArray?.filter(
      (item) =>
        item.title.toLowerCase().includes(searchString) ||
        item.description.toLowerCase().includes(searchString)
    );

    setFilteredData(filteredArray);
  }, [searchValue]);

  const handleClick = (e, value) => {
    e.stopPropagation();
    setModalAccountSelection(value);
  };

  const handleCancel = () => {
    setIsAccountListModal(false);
    setModalAccountSelection(accountSelection);
  };

  const handleSave = () => {
    setIsAccountListModal(false);
    setAccountSelection(modalAccountSelection);
  };

  const getModalIslandContent = () => (
    <Box className='AccountListModalIsland-modalIslandContent'>
      <Box className='AccountListModalIsland-modalIslandContent-search'>
        <InputField
          name='modalIsland-search'
          kind='search'
          placeholder='Search by account name or description'
          prefixIcon={<Icon kind='search' size='smallMedium' />}
          value={searchValue}
          setValue={setSearchValue}
        />
      </Box>
      <Box className='AccountListModalIsland-radioButtons'>
        {filteredData?.map((account) => (
          <RadioButton
            key={`accountModalRadio-${account.value}`}
            id={`accountModalRadio-${account.value}`}
            name='accountListSelectionRadioGroup'
            title={account.title}
            description={account.description}
            value={account}
            radioSelection={modalAccountSelection}
            onClick={handleClick}
            isTruncated
          />
        ))}
      </Box>
    </Box>
  );

  const modalIslandFooterActionBar = () => (
    <Box className='AccountListModalIsland-footerActionBar'>
      <Button kind='outlined' size='medium' onClick={() => handleCancel()}>
        Cancel
      </Button>
      <Button
        isDisabled={!modalAccountSelection}
        kind='filled'
        size='medium'
        onClick={() => handleSave()}>
        Save
      </Button>
    </Box>
  );

  return (
    <ModalIsland
      className='AccountListModalIsland'
      onClose={() => setIsAccountListModal(false)}
      isOpen={isAccountListModal}
      headerIcon={<Icon kind='account' size='large' />}
      title='Which account do you need?'
      contentBody={getModalIslandContent()}
      sectionRef={accountListModalContentRef}
      FooterActionBarComp={
        <FooterActionBar size='large' isScrolled={sectionRefIsScrolled}>
          {modalIslandFooterActionBar()}
        </FooterActionBar>
      }
    />
  );
}

AccountListModalIsland.propTypes = {
  accountListArray: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  accountListModalContentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  sectionRefIsScrolled: PropTypes.bool,
  accountSelection: PropTypes.shape({
    value: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  setAccountSelection: PropTypes.func,
  isAccountListModal: PropTypes.bool,
  setIsAccountListModal: PropTypes.func,
};

AccountListModalIsland.defaultProps = {
  accountListArray: null,
  accountListModalContentRef: null,
  sectionRefIsScrolled: null,
  accountSelection: null,
  setAccountSelection: () => {},
  isAccountListModal: null,
  setIsAccountListModal: () => {},
};

export default AccountListModalIsland;
