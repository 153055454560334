import PropTypes from 'prop-types';

export const ModuleDetails = PropTypes.shape({
  requestAccessKey: PropTypes.number,
  name: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  accountName: PropTypes.string,
  accountType: PropTypes.string,
  displayName: PropTypes.string,
  credentialType: PropTypes.string,
  description: PropTypes.string,
  accountKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customproperty2: PropTypes.string,
  customproperty5: PropTypes.string,
  customproperty7: PropTypes.string,
  customproperty9: PropTypes.string,
  customproperty10: PropTypes.string,
  customproperty11: PropTypes.string,
  customproperty15: PropTypes.string,
  customproperty16: PropTypes.string,
  customproperty17: PropTypes.string,
  customproperty30: PropTypes.string,
  endpointKey: PropTypes.number,
  endointName: PropTypes.string,
  resourceType: PropTypes.string,
  applicationType: PropTypes.string,
  connectionType: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  applicationTypeDisplayName: PropTypes.string,
  applicationTypeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  applicationTypeName: PropTypes.string,
  assetType: PropTypes.string,
  endpointDisplayName: PropTypes.string,
  endpointName: PropTypes.string,
  endpointStatus: PropTypes.number,
  epCustomproperty6: PropTypes.string,
  epPamConfig: PropTypes.shape({
    maxConcurrentSession: PropTypes.string,
    rotateKey: PropTypes.string,
  }),
  exclusiveAccess: PropTypes.bool,
  location: PropTypes.string,
  pamPlatformType: PropTypes.string,
  platformType: PropTypes.string,
  remoteAppDetails: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      icon: PropTypes.string,
      internalAttr: PropTypes.shape({ client: PropTypes.string }),
      key: PropTypes.string,
      setup: PropTypes.string,
    })
  ),
  remoteAppEnabled: PropTypes.bool,
  sessionStartDate: PropTypes.string,
  sessionEndDate: PropTypes.string,
});

export const Session = PropTypes.shape({
  requestAccessKey: PropTypes.number,
  requestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  requestType: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  requestAccessStatus: PropTypes.number,
  endpointName: PropTypes.string,
  endpointDisplayName: PropTypes.string,
  username: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  accountName: PropTypes.string,
  accountKey: PropTypes.number,
  endpointKey: PropTypes.number,
  pamType: PropTypes.string,
  sendTargetEndpointKey: PropTypes.bool,
});

export const SessionDetails = PropTypes.shape({
  userName: PropTypes.shape({
    value: PropTypes.string,
    effect: PropTypes.string,
  }),
  password: PropTypes.shape({
    value: PropTypes.string,
    effect: PropTypes.string,
  }),
  host: PropTypes.shape({
    value: PropTypes.string,
    effect: PropTypes.string,
  }),
  port: PropTypes.shape({
    value: PropTypes.string,
    effect: PropTypes.string,
  }),
  publicIp: PropTypes.shape({
    value: PropTypes.string,
    effect: PropTypes.string,
  }),
  privateKey: PropTypes.shape({
    value: PropTypes.string,
    effect: PropTypes.string,
  }),
});

export const CredentialRequest = PropTypes.shape({
  // TODO: Evaluate the required fields with testing and other teams
  accesstype: PropTypes.string.isRequired,
  requestor: PropTypes.string.isRequired,
  requestedfor: PropTypes.string.isRequired,
  comments: PropTypes.string,
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      businessjustification: PropTypes.string.isRequired,
      startdate: PropTypes.string.isRequired,
      enddate: PropTypes.string.isRequired,
      accountId: PropTypes.number.isRequired,
    })
  ),
  // TODO: Replace other with any other actual pamTypes
  pamtype: PropTypes.oneOf(['credentials', 'other']),
});
