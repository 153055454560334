/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
import './ViewScheduleModalIsland.css';

import { InterPoint } from '@saviynt/common';
import { Box, ModalIsland, Typography } from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React from 'react';

import Scheduler from '../../../components/Scheduler/Scheduler';
import { Session } from '../../../models/PamModels';

function ViewScheduleModalIsland({
  viewScheduleModalContentRef,
  sectionRefIsScrolled,
  isScheduleViewOpen,
  setIsScheduleViewOpen,
  checkedAccountSessions,
}) {
  const getHeaderSubheadingContent = () => (
    <Box className='ViewScheduleModalIsland-headerSubtitle'>
      <Typography kind='body2' className='ViewScheduleModalIsland-detailName'>
        Asset:
      </Typography>
      <Typography kind='body2' className='ViewScheduleModalIsland-detialInfo'>
        WIP
      </Typography>
      <InterPoint color='neutralSubtle' font='body2' />
      <Typography kind='body2' className='ViewScheduleModalIsland-detailName'>
        Account:
      </Typography>
      <Typography kind='body2' className='ViewScheduleModalIsland-detialInfo'>
        WIP
      </Typography>
      <InterPoint color='neutralSubtle' font='body2' />
      <Typography kind='body2' className='ViewScheduleModalIsland-detailName'>
        Time Zone:
      </Typography>
      <Typography kind='body2' className='ViewScheduleModalIsland-detialInfo'>
        WIP
      </Typography>
    </Box>
  );

  const modalIslandContent = () => (
    <Box className='ViewScheduleModalIsland-modalIslandContent'>
      <Scheduler
        sessions={checkedAccountSessions}
        numOfDaysDisplayed={30}
        timeFormat={{ roundToNearest: 'minute', is24HourFormat: false }}
        isModalView
      />
    </Box>
  );

  return (
    <ModalIsland
      className='ViewScheduleModalIsland'
      onClose={() => setIsScheduleViewOpen(false)}
      isOpen={isScheduleViewOpen}
      title='Schedule'
      subtitle={getHeaderSubheadingContent()}
      contentBody={modalIslandContent()}
      sectionRef={viewScheduleModalContentRef}
    />
  );
}

ViewScheduleModalIsland.propTypes = {
  viewScheduleModalContentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  sectionRefIsScrolled: PropTypes.bool,
  isScheduleViewOpen: PropTypes.bool.isRequired,
  setIsScheduleViewOpen: PropTypes.func.isRequired,
  checkedAccountSessions: PropTypes.arrayOf(Session),
};

ViewScheduleModalIsland.defaultProps = {
  viewScheduleModalContentRef: null,
  sectionRefIsScrolled: null,
  checkedAccountSessions: [],
};

export default ViewScheduleModalIsland;
