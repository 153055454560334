import './CountdownTimer.css';

import PropTypes from 'prop-types';
import React from 'react';

import { useCountdown } from '../../hooks/use-countdown';

const renderTimeCounter = (days, hours, minutes, seconds) => {
  if (days > 1) {
    return `${days} d${hours > 0 ? ` ${hours} h` : ''}`;
  }

  if (days === 1) {
    return `${days} d${hours > 0 ? ` ${hours} h` : ''}`;
  }

  if (hours > 0) {
    return `${hours} h${minutes > 0 ? ` ${minutes} m` : ''}`;
  }

  if (minutes > 0 && seconds > 0) {
    return `${minutes} m ${seconds} s`;
  }

  if (minutes > 0) {
    return `${minutes} m`;
  }

  if (seconds >= 0) {
    return `${seconds} s`;
  }

  return '';
};

function CountdownTimer({ targetDate, leftText, hideTimer, onTimerExpired }) {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds < 0) {
    if (new Date(targetDate).getTime() - new Date().getTime() <= 0) {
      onTimerExpired();
    }

    return '';
  }

  return (
    !hideTimer && (
      <div className='CountdownTimer-display'>
        <span>{renderTimeCounter(days, hours, minutes, seconds)}</span>
        <span>{leftText}</span>
      </div>
    )
  );
}

CountdownTimer.defaultProps = { hideTimer: false, onTimerExpired: () => {} };

CountdownTimer.propTypes = {
  targetDate: PropTypes.string.isRequired,
  leftText: PropTypes.string.isRequired,
  hideTimer: PropTypes.bool,
  onTimerExpired: PropTypes.func,
};

export default CountdownTimer;
