/**
 * Custom hook for handling file uploads.
 *
 * @param {number} [maxFileSize=1] - Max file size allowed per file in MB.
 * @param {number} [maxFileCount=10] - Max number of files allowed to be uploaded.
 * @param {boolean} [shouldIgnoreDuplicateFilenames=false] - Flag to determine whether to ignore duplicate filenames.
 *
 * @returns {Object} - Object containing the uploaded files and various file handling functions.
 */
import { useState } from 'react';
import { getRandomString } from '@saviynt/common';

const BYTES_PER_MB = 1024 * 1024;
const FILE_TYPE_NOT_SUPPORTED_TEXT = 'File type not supported. Try again.';

const useUploadFile = (
  maxFileSize = 1, // in MB
  maxFileCount = 5,
  shouldIgnoreDuplicateFilenames = false
) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [reuploadFileName, setReuploadFileName] = useState(null);

  const handleFileSelect = async (files, allowedFileTypes) => {
    const allowedExtensions = allowedFileTypes
      ? allowedFileTypes.map((type) => type.replace('.', ''))
      : null;

    let filesToProcess = files.slice(0, maxFileCount - uploadedFiles.length);

    // when reuploading, process only the first file
    if (reuploadFileName) filesToProcess = [files[0]];

    const fileObjects = filesToProcess
      .map((file) => {
        let fileName = file.name;
        const fileExtension = fileName.split('.').pop();

        const isDuplicate =
          uploadedFiles.some((f) => f.fileName === fileName) &&
          fileName !== reuploadFileName;

        if (isDuplicate) {
          if (shouldIgnoreDuplicateFilenames) {
            return null;
          }

          fileName = `${fileName.replace(
            `.${fileExtension}`,
            ''
          )}-${getRandomString()}.${fileExtension}`;
        }

        // check if file type is allowed
        if (allowedExtensions && !allowedExtensions.includes(fileExtension)) {
          return {
            file,
            fileName,
            fileSize: file.size,
            isError: true,
            errorMessage: FILE_TYPE_NOT_SUPPORTED_TEXT,
            isUploading: false,
            id: Date.now() + Math.random(),
          };
        }

        if (file.size / BYTES_PER_MB > maxFileSize) {
          return {
            file,
            fileName,
            fileSize: file.size,
            isError: true,
            errorMessage: `File size exceeds ${maxFileSize}MB limit. Try again.`,
            isUploading: false,
            id: Date.now() + Math.random(),
          };
        }

        return {
          file,
          fileName,
          fileSize: file.size,
          isError: false,
          errorMessage: '',
          isUploading: true,
          id: Date.now() + Math.random(), // unique identifier (internal component use)
        };
      })
      // filter null entries from possibly ignored duplicates
      .filter((fileObject) => fileObject !== null);

    // add new files or update reuploaded file in the state
    setUploadedFiles((currentFiles) => {
      if (reuploadFileName) {
        return currentFiles.map((file) =>
          file.fileName === reuploadFileName ? { ...fileObjects[0] } : file
        );
      }

      return [...currentFiles, ...fileObjects];
    });

    if (reuploadFileName) {
      setReuploadFileName(null);
    }
  };

  const handleFileDelete = (fileName) => {
    setUploadedFiles((currentFiles) =>
      currentFiles.filter((file) => file.fileName !== fileName)
    );
  };

  const handleFileReupload = (fileName) => {
    // reset the error state of the file being reuploaded
    setUploadedFiles((currentFiles) =>
      currentFiles.map((file) =>
        file.fileName === fileName
          ? { ...file, isError: false, errorMessage: '' }
          : file
      )
    );
    setReuploadFileName(fileName);
  };

  const hasAnyUploadErrors = uploadedFiles.some((file) => file.isError);

  return {
    uploadedFiles,
    handleFileSelect,
    handleFileDelete,
    handleFileReupload,
    reuploadFileName,
    isMaxNumberUploaded: uploadedFiles.length >= maxFileCount,
    hasAnyUploadErrors,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useUploadFile };
