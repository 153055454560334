import { call, put } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';
import { checkEnvironment } from 'ui-common/src/lib/utils';
import {
  refreshKPI,
  fetchFeatureNameListSuccess,
  fetchFeatureNameListFailure,
} from './kpiForm-actions';

const cookies = new Cookies();

export function* updateKpiFormCode(action) {
  try {
    const IDW_ENDPOINT = checkEnvironment().IDWMS_API_URL;
    const url = `${IDW_ENDPOINT}/res/content`;
    const response = yield call(fetch, url, {
      method: 'POST',
      headers: {
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
      },
      body: JSON.stringify(action.payload),
    });
    if (response && response.status === 200 && action.refreshKPI) {
      yield put(refreshKPI());
    }
  } catch (e) {
    // error
  }
}
export function* updateKpiAttributes(action) {
  try {
    const IDW_ENDPOINT = checkEnvironment().IDWMS_API_URL;
    const url = `${IDW_ENDPOINT}/kpi/${action.payload.id}`;
    const response = yield call(fetch, url, {
      method: 'PUT',
      headers: {
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
      },
      body: JSON.stringify(action.payload),
    });
    if (response && response.status === 200) {
      yield put(refreshKPI());
    }
  } catch (e) {
    // error
  }
}

export function* newKpiAttributes(action) {
  try {
    const IDW_ENDPOINT = checkEnvironment().IDWMS_API_URL;
    const url = `${IDW_ENDPOINT}/kpi`;
    const response = yield call(fetch, url, {
      method: 'POST',
      headers: {
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
      },
      body: JSON.stringify(action.payload),
    });
    if (response && response.status === 200) {
      yield put(refreshKPI());
    }
  } catch (e) {
    // error
  }
}

export function* requestFeatureNameList() {
  try {
    const IDW_ENDPOINT = checkEnvironment().IDWMS_API_URL;
    const url = `${IDW_ENDPOINT}/feature`;
    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
      },
    });

    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      if (json) {
        yield put(fetchFeatureNameListSuccess(json));
      }
    } else {
      yield put(fetchFeatureNameListFailure());
    }
  } catch (e) {
    // error
    yield put(fetchFeatureNameListFailure());
  }
}
