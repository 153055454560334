export const TABS = {
  ACCOUNT: 'Account',
  ASSET: 'Asset',
  SCHEDULE: 'Schedule',
  SESSION: 'Session',
};

export const TITLES = {
  SUMMARY: 'Summary',
  SYSTEM_DETAILS: 'System Details',
  TIME: 'Time',
  ACCOUNT: 'Account',
};

export const ASSET_TYPES = {
  ACCOUNT: 'Account',
  APPLICATION: 'Application',
  DATABASE: 'Database',
  SERVER: 'Server',
  CONSOLE: 'Console',
};

export const CLOUD_PROVIDERS = {
  AWS: 'AWS',
  AZURE: 'AZURE',
  GCP: 'GCP',
};

export const PLATFORMS_LABELS = {
  AWS_ACCOUNT: 'AWS Account',
  AZURE_SUBSCRIPTION: 'Azure Subscription',
  GCP_PROJECT: 'GCP Project',
};

export const TENANTS_LABELS = {
  TENANT_AWS: 'Tenant (AWS Account)',
  TENANT_AZURE: 'Tenant (Azure Account)',
  TENANT_GCP: 'Tenant (GCP Account)',
};

export const LABELS = {
  NAME: 'Name',
  PLATFORM: 'Platform',
  DESCRIPTION: 'Description',
  LOCATION: 'Location',
  ACCOUNT_TYPE: 'Account Type',
  AUTHENTICATION_MECHANISM: 'Authentication Mechanism',
  ID: 'ID',
  OPERATING_SYSTEM: 'Operating System',
  ENVIRONMENT: 'Environment',
  NETWORK: 'Network',
  DATABASE_NAME: 'Database Name',
  INSTANCE: 'Instance',
  INSTANCE_OS: 'Instance (OS)',
  RDS_TYPE: 'RDS Type',
  PUBLIC_IP: 'Public IP',
  PRIVATE_IP: 'Private IP',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
};
