import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TooltipComponent } from '@saviynt/design-system';
import styles from './Header.module.css';
import Logo from '../Logo/Logo';
import { fullScreenRoutes, nonFullscreenGriv2routes } from '../../Constants';
import Profile from '../Profile/Profile';
import HelperMenu from '../HelperMenu';
import MenuHamburger from '../../ECMv6/assets/MenuHamburger';

const Header = ({
  classes,
  isSideMenuAvailable,
  isWhite,
  intl,
  isVisible,
  expandSideMenu,
  handleMobileDrawerClick,
}) => {
  const IsMobileDevice = useMediaQuery('(max-width:480px)');
  const { username, helpDetails } = useSelector((state) => state.profile);

  const [chatBot, setChatBot] = useState(false);

  let tabIndex = 0;
  const isMaintenancePages = /maintenance\/securityQuestions/gm.test(
    window.location.href,
  );

  if (
    fullScreenRoutes
      .map((r) => matchPath(window.location.pathname, {
        path: r,
        exact: true,
        strict: true,
      }))
      .filter((x) => !!x).length > 0
  ) {
    tabIndex = -1;
  } else if (
    nonFullscreenGriv2routes
      .map((r) => matchPath(window.location.pathname, {
        path: r,
        exact: true,
        strict: true,
      }))
      .filter((x) => !!x).length > 0
  ) {
    tabIndex = 1;
  }

  const chatBotsOpen = () => {
    setChatBot(!chatBot);
  };

  if (isVisible) {
    return (
      <AppBar
        position="fixed"
        className={IsMobileDevice ? styles.mobileAppBar : styles.appBar}
        color="inherit"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleMobileDrawerClick}
          >
            <MenuHamburger />
          </IconButton>
          {!expandSideMenu && !IsMobileDevice && (
            <Logo isSideMenuAvailable={isSideMenuAvailable} isWhite={isWhite} />
          )}
          <div className={styles.container}>
            {(helpDetails.isHelpEnabled || helpDetails.isDocEnabled) && (
              <HelperMenu />
            )}
            <Profile />
            {!IsMobileDevice && (
              (username && username.length > 25) ? (
                <TooltipComponent
                  delay={250}
                  position="left"
                  text={username}
                  className={styles.headerUsernameTooltip}
                  maxWidth="none"
                >
                  <div className={styles.navUsername}>{username}</div>
                </TooltipComponent>
              )
                : <div className={styles.navUsername}>{username}</div>
            )}
          </div>
        </Toolbar>
      </AppBar>
    );
  }

  return null;
};

export default injectIntl(Header);
