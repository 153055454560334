import { Cookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { matchRouteToRegExArr } from '../../utilities/featureUtil';

const cookies = new Cookies();

const Route404 = ({ routes, render, location, children }) => {
  if (!cookies.get('access_token')) return children; // If not logged in dont show 4o4 for any URL.

  if (
    matchRouteToRegExArr({
      url: location.pathname,
      allowedUrls: routes.staticRoutes,
      blockedUrl: routes.blockedUrlList,
      blockedFeatures: routes.blockedNames,
    })
  ) {
    return children;
  }

  //  support dynamic parameter routes like /posts/:postId etc..
  const matchedStaticRoute = routes.staticRoutes.filter((sr) => {
    const routeParts = sr.split(':');

    if (routeParts.length === 2) {
      // One url parameter. first elemnt is static url
      const locationDynPart = location.pathname.replace(routeParts[0], '');

      if (locationDynPart.indexOf('/') < 0) return true;
    }

    if (routeParts.length === 3) {
      // first array element is static path, second is first url parameter, third elemnt is second url parameter.
      const locationDynPart = location.pathname.replace(routeParts[0], '');
      const dynValueArray = locationDynPart.split('/');

      if (dynValueArray.length === 2) return true;
    }

    return false;
  });

  if (matchedStaticRoute.length > 0) return children;

  //  support dynamic module routes .
  const matchedRoutes = routes.dynamicRoutes.filter((dyn) => {
    const dynPart = location.pathname.replace(dyn, '');

    if (
      location.pathname.startsWith(dyn) &&
      (dynPart.startsWith('/') || dynPart.length === 0)
    ) {
      return true;
    }

    return false;
  });

  if (matchedRoutes.length > 0) {
    return children;
  }

  // If the above doesnt return , it means route not found.
  return render(location.pathname);
};

export default withRouter(Route404);
