/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable object-curly-newline */
import './JustificationDetails.css';

import {
  Icon,
  InlineMessage,
  InputField,
  Typography,
  Upload,
  useUploadFile,
} from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import useRequestUploadsMetaData from '../../../../hooks/use-requestuploadmetadata';
import { mockRequestUploadMetaData } from '../../../../models/MockData/MockData';

function JustificationDetails({
  isCritical,
  justificationInputValue,
  setIsCritical,
  setJustificationInputValue,
}) {
  const [ticketNumber, setTicketNumber] = useState('');

  const fileUploadMetaDataObj = useRequestUploadsMetaData(
    mockRequestUploadMetaData
  );

  const {
    uploadedFiles, // TODO: these are the files to upload
    handleFileSelect,
    handleFileDelete,
    handleFileReupload,
    reuploadFileName,
    isMaxNumberUploaded,
    hasAnyUploadErrors, // TODO: use this as a valid form indicator
  } = useUploadFile(
    fileUploadMetaDataObj?.MAX_FILE_SIZE,
    fileUploadMetaDataObj?.MAX_FILE_COUNT
  );

  return (
    <div className='JustificationDetails'>
      <div className='JustificationDetails-fields'>
        <InputField
          name='Justification Details'
          placeholder='Provide a reason for your request'
          label={
            <Typography kind='label' htmlFor='Justification'>
              Justification
            </Typography>
          }
          backgroundColor='secondary'
          value={justificationInputValue}
          setValue={setJustificationInputValue}
          kind='multiline'
          isRequired
          isCritical={isCritical}
          setIsCritical={setIsCritical}
          CriticalHelperText={
            <InlineMessage
              text='This required field is incomplete'
              colorTheme='critical'
              size='small'
              leftIcon={<Icon kind='AlertCritical' color='critical-700' />}
            />
          }
        />
        <InputField
          name='Ticket Number'
          placeholder='Enter ticket number'
          label={
            <Typography kind='label' htmlFor='Ticket Number (optional)'>
              Ticket Number (optional)
            </Typography>
          }
          backgroundColor='secondary'
          value={ticketNumber}
          setValue={setTicketNumber}
        />
        <div className='JustificationDetails-attachments'>
          <Typography kind='h3'>Attachments</Typography>
          <Upload
            kind='dropzone'
            isMultiple
            onFileSelect={handleFileSelect}
            onFileDelete={handleFileDelete}
            onFileReupload={handleFileReupload}
            uploadedFiles={uploadedFiles}
            acceptTypes={fileUploadMetaDataObj?.ALLOWED_FILE_TYPES}
            reuploadFileName={reuploadFileName}
            isMaxNumberUploaded={isMaxNumberUploaded}
            maxNumberFilesText={`Max ${fileUploadMetaDataObj?.MAX_FILE_COUNT} files.`}
          />
        </div>
      </div>
    </div>
  );
}

JustificationDetails.propTypes = {
  isCritical: PropTypes.bool,
  justificationInputValue: PropTypes.string,
  setIsCritical: PropTypes.func,
  setJustificationInputValue: PropTypes.func,
};

JustificationDetails.defaultProps = {
  isCritical: false,
  justificationInputValue: null,
  setIsCritical: () => {},
  setJustificationInputValue: () => {},
};

export default JustificationDetails;
