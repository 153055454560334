import React from 'react';

/**
 * A utility function to style the first word of the text parameter.
 * This function can act as a stand-in for the lack of a CSS ::first-word pseudo-class.
 *
 * @function
 * @param {string} text - The text string whose first word should be styled differently.
 * @param {string} className - A CSS class name to apply to the first word.
 * @returns {JSX.Element|string} A JSX element containing the styled text.
 *
 * @example
 * const styledText = styleFirstWord("Hello World", "someText--isBold");
 */
function styleFirstWord(text, className) {
  if (!text || !className) return text;

  const words = text.split(' ');

  const firstWord = words[0];
  const remainingWords = words.slice(1).join(' ');

  return (
    <>
      <span className={className}>{firstWord}</span>
      {remainingWords && <span>{` ${remainingWords}`}</span>}
    </>
  );
}

/**
 * A utility function to style all words before a specified token in the text param.
 * If shouldStyleToken is true, the token will also be styled along with the text.
 *
 * @function
 * @param {string} text - The text string to process.
 * @param {string} token - The string token.
 * @param {boolean} [shouldStyleToken=false] - Whether to style the token along with the preceding words.
 * @param {string|null} [className=null] - A CSS class to apply to the styled words.
 * @returns {JSX.Element|string} A JSX element containing the styled text,
 * or 'text' if the token is not found.
 *
 * @example
 * const styledText = styleTextBeforeToken("Hello World: How are you?", ':' true, "someText--isBold");
 */
function styleTextBeforeToken(
  text,
  token,
  shouldStyleToken = false,
  className = null
) {
  if (!text || !className) {
    return text;
  }

  // find the first instance of the token
  const tokenIndex = text.indexOf(token);

  if (tokenIndex === -1) {
    return text;
  }

  const textFirstPart = text.slice(
    0,
    shouldStyleToken ? tokenIndex + 1 : tokenIndex + token.length
  );
  const textAfterToken = text.slice(tokenIndex + token.length);

  return (
    <>
      <span className={shouldStyleToken ? className : null}>
        {textFirstPart}
      </span>
      {textAfterToken && <span>{textAfterToken}</span>}
    </>
  );
}

/**
 * Generates a random string of the specified length.
 *
 * @param {number} length - Length of the string to generate. Default 5.
 * @param {boolean} shouldIncludeNumbers - Whether to include numbers in the string. Default true.
 * @returns {string} A random string.
 */
function getRandomString(length = 5, shouldIncludeNumbers = true) {
  const CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  const NUMBERS = '0123456789';

  const chars = `${CHARACTERS}${shouldIncludeNumbers ? NUMBERS : ''}`;
  let randomString = '';

  for (let i = 0; i < length; i += 1) {
    randomString += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return randomString;
}

export { getRandomString, styleFirstWord, styleTextBeforeToken };
