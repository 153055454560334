import './SpvSession.css';

import { formatLocalizedDateTime } from '@saviynt/common';
import {
  Box,
  Button,
  Icon,
  InputField,
  TabContent,
  TabGroup,
  TabItem,
  Typography,
} from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useTenantLabel } from '../../../../hooks/use-labels';
import { ModuleDetails, SessionDetails } from '../../../../models/PamModels';
import { LABELS, TABS, TITLES } from '../../constants';
import SpvSection from '../SpvSection/SpvSection';

function SpvSession({ data, session, sessionDetails, dataTestId, className }) {
  const classes = classnames('SpvSession', className);

  const cloudProvider = data.connectionType?.toUpperCase();

  const [activeTab, setActiveTab] = useState('1');
  const [showPassword, setShowPassword] = useState(false);
  const [showPrivateKey, setShowPrivateKey] = useState(false);

  const handleChange = (value) => {
    setActiveTab(value);
  };

  const hasCredentials =
    sessionDetails?.userName?.value ||
    sessionDetails?.password?.value ||
    sessionDetails?.host?.value ||
    sessionDetails?.port?.value ||
    sessionDetails?.publicIp?.value ||
    sessionDetails?.privateKey?.value;

  const sessionTimeHasFields =
    session?.sessionStartDate || session?.sessionEndDate;

  const sessionAccountHasFields = session?.accountName || data.description;

  const assetSummaryHasFields =
    data.connectionType || data.customproperty10 || data.accountType;

  const assetSystemDetailsHasFields =
    data.accountKey || data.customproperty5 || data.connectionType;

  const copyOnClick = async (value) => {
    await navigator.clipboard.writeText(value);
  };

  const togglePasswordOnClick = () => {
    setShowPassword(!showPassword);
  };

  const togglePrivateKeyOnClick = () => {
    setShowPrivateKey(!showPrivateKey);
  };

  useEffect(() => {
    let passwordTimeout;
    let privateKeyTimeout;

    if (showPassword) {
      passwordTimeout = setTimeout(() => {
        setShowPassword(false);
      }, 5000);
    }

    if (showPrivateKey) {
      privateKeyTimeout = setTimeout(() => {
        setShowPrivateKey(false);
      }, 5000);
    }

    return () => {
      clearTimeout(passwordTimeout);
      clearTimeout(privateKeyTimeout);
    };
  }, [showPassword, showPrivateKey]);

  return (
    <section className={classes} data-testid={dataTestId}>
      <TabGroup
        className='SpvSession-tabGroup'
        value={activeTab}
        onChange={handleChange}>
        <TabItem
          label={TABS.SESSION}
          value='1'
          icon={
            <Icon kind='PrivilegedSession' size='medium' color='neutral-1000' />
          }
        />
        <TabItem
          label={TABS.ASSET}
          value='2'
          icon={
            <Icon kind='AssetTargetSystem' size='medium' color='neutral-1000' />
          }
        />
      </TabGroup>
      <TabContent
        className='SpvSession-tabContent'
        value={activeTab}
        tabValue='1'>
        <SpvSection className='SpvSession-credentials'>
          {sessionDetails?.userName?.value && (
            <section className='SpvSession-credential'>
              <Box className='SpvSession-credential-field'>
                <InputField
                  label={
                    <Typography
                      kind='h4'
                      className='SpvSession-credential-label'>
                      Username
                    </Typography>
                  }
                  placeholder='Username'
                  name='username'
                  value={sessionDetails.userName.value}
                  setValue={() => {}}
                  isReadOnly
                />
              </Box>
              <Button
                type='button'
                kind='outlined'
                size='large'
                leftIcon={
                  <Icon kind='Copy' size='medium' color='neutral-100' />
                }
                onClick={() => copyOnClick(sessionDetails.userName.value)}
                dataTestId='copy-button-username'
              />
            </section>
          )}
          {sessionDetails?.password?.value && (
            <section className='SpvSession-credential'>
              <Box className='SpvSession-credential-field'>
                <InputField
                  label={
                    <Typography
                      kind='h4'
                      className='SpvSession-credential-label'>
                      Password
                    </Typography>
                  }
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Password'
                  name='password'
                  suffixButtonType='password'
                  value={sessionDetails.password.value}
                  suffixOnClick={togglePasswordOnClick}
                  suffixIsToggle={showPassword}
                  setValue={() => {}}
                  isReadOnly
                />
              </Box>
              <Button
                type='button'
                kind='outlined'
                size='large'
                leftIcon={
                  <Icon kind='Copy' size='medium' color='neutral-100' />
                }
                onClick={() => copyOnClick(sessionDetails.password.value)}
                dataTestId='copy-button-password'
              />
            </section>
          )}
          {sessionDetails?.host?.value && (
            <section className='SpvSession-credential'>
              <Box className='SpvSession-credential-field'>
                <InputField
                  label={
                    <Typography
                      kind='h4'
                      className='SpvSession-credential-label'>
                      Host
                    </Typography>
                  }
                  placeholder='Host'
                  name='host'
                  value={sessionDetails.host.value}
                  setValue={() => {}}
                  isReadOnly
                />
              </Box>
              <Button
                type='button'
                kind='outlined'
                size='large'
                leftIcon={
                  <Icon kind='Copy' size='medium' color='neutral-100' />
                }
                onClick={() => copyOnClick(sessionDetails.host.value)}
                dataTestId='copy-button-host'
              />
            </section>
          )}
          {sessionDetails?.port?.value && (
            <section className='SpvSession-credential'>
              <Box className='SpvSession-credential-field'>
                <InputField
                  label={
                    <Typography
                      kind='h4'
                      className='SpvSession-credential-label'>
                      Port
                    </Typography>
                  }
                  placeholder='Port'
                  name='port'
                  value={sessionDetails.port.value}
                  setValue={() => {}}
                  isReadOnly
                />
              </Box>
              <Button
                type='button'
                kind='outlined'
                size='large'
                leftIcon={
                  <Icon kind='Copy' size='medium' color='neutral-100' />
                }
                onClick={() => copyOnClick(sessionDetails.port.value)}
                dataTestId='copy-button-port'
              />
            </section>
          )}
          {sessionDetails?.publicIp?.value && (
            <section className='SpvSession-credential'>
              <Box className='SpvSession-credential-field'>
                <InputField
                  label={
                    <Typography
                      kind='h4'
                      className='SpvSession-credential-label'>
                      Public Ip
                    </Typography>
                  }
                  placeholder='Public Ip'
                  name='publicIp'
                  value={sessionDetails.publicIp.value}
                  setValue={() => {}}
                  isReadOnly
                />
              </Box>
              <Button
                type='button'
                kind='outlined'
                size='large'
                leftIcon={
                  <Icon kind='Copy' size='medium' color='neutral-100' />
                }
                onClick={() => copyOnClick(sessionDetails.publicIp.value)}
                dataTestId='copy-button-public-ip'
              />
            </section>
          )}
          {sessionDetails?.privateKey?.value && (
            <section className='SpvSession-credential'>
              <Box className='SpvSession-credential-field'>
                <InputField
                  label={
                    <Typography
                      kind='h4'
                      className='SpvSession-credential-label'>
                      Private Key
                    </Typography>
                  }
                  placeholder='Private Key'
                  type={showPrivateKey ? 'text' : 'password'}
                  name='privateKey'
                  suffixButtonType='password'
                  value={sessionDetails.privateKey.value}
                  suffixOnClick={togglePrivateKeyOnClick}
                  suffixIsToggle={showPrivateKey}
                  setValue={() => {}}
                  isReadOnly
                />
              </Box>
              <Button
                type='button'
                kind='outlined'
                size='large'
                leftIcon={
                  <Icon kind='Copy' size='medium' color='neutral-100' />
                }
                onClick={() => copyOnClick(sessionDetails.privateKey.value)}
                dataTestId='copy-button-private-key'
              />
            </section>
          )}
        </SpvSection>
        {sessionTimeHasFields && (
          <SpvSection
            className={hasCredentials ? 'SpvSession-tabSection' : null}
            title={TITLES.TIME}
            fields={[
              {
                label: LABELS.START_TIME,
                value: formatLocalizedDateTime(session.sessionStartDate),
              },
              {
                label: LABELS.END_TIME,
                value: formatLocalizedDateTime(session.sessionEndDate),
              },
            ]}
          />
        )}
        {sessionAccountHasFields && (
          <SpvSection
            className='SpvSession-tabSection'
            title={TITLES.ACCOUNT}
            fields={[
              {
                label: LABELS.NAME,
                value: session?.accountName,
              },
              {
                label: LABELS.DESCRIPTION,
                value: data.description,
              },
            ]}
          />
        )}
      </TabContent>
      <TabContent
        className='SpvSession-tabContent'
        value={activeTab}
        tabValue='2'>
        {assetSummaryHasFields && (
          <SpvSection
            title={TITLES.SUMMARY}
            fields={[
              {
                label: LABELS.PLATFORM,
                value: data.connectionType,
              },
              {
                label: LABELS.LOCATION,
                value: data.customproperty10,
              },
              {
                label: LABELS.ACCOUNT_TYPE,
                value: data.accountType,
              },
            ]}
          />
        )}
        {assetSystemDetailsHasFields && (
          <SpvSection
            title={TITLES.SYSTEM_DETAILS}
            fields={[
              { label: LABELS.ID, value: data.accountName },
              {
                label: useTenantLabel(cloudProvider),
                value: data.accountKey ? 'Key' : null,
              },
              {
                // TODO: Temp mapping; real mapped field unknown; needs update
                label: LABELS.INSTANCE_OS,
                value: data.accountKey,
              },
              {
                label: LABELS.ENVIRONMENT,
                value: data.customproperty5,
              },
              {
                label: LABELS.NETWORK,
                value: data.connectionType,
              },
            ]}
            className='SpvSession-tabSection'
          />
        )}
      </TabContent>
    </section>
  );
}

SpvSession.propTypes = {
  data: ModuleDetails,
  session: ModuleDetails,
  sessionDetails: SessionDetails,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvSession.defaultProps = {
  data: null,
  session: null,
  sessionDetails: null,
  dataTestId: null,
  className: null,
};

export default SpvSession;
