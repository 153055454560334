import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';

/**
 * Component for conditional rendering of the IntlProvider only when
 * it's pam running on localhost:3001.
 *
 * @param {boolean} isPamLocalhostPort3001 - Bool indicating if pam is running on localhost:3001.
 * @param {ReactNode} props.children - The child components to render.
 * @returns {ReactNode} - The conditionally wrapped component.
 */

function LocalizedWrapper({ isPamLocalhostPort3001, children }) {
  return isPamLocalhostPort3001 ? (
    <IntlProvider locale='en'>{children}</IntlProvider>
  ) : (
    children
  );
}

LocalizedWrapper.propTypes = {
  isPamLocalhostPort3001: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default LocalizedWrapper;
