import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import InlineMessage from '../InlineMessage/InlineMessage';
import InputCore from '../Input/InputCore/InputCore';
import Link from '../Link/Link';

import UploadedFile from './UploadedFile/UploadedFile';
import { formatUploadFileSize } from './helpers';

import './Upload.css';

const KINDS = { dropzone: 'dropzone', button: 'button' };

/**
 * ###### TODO:
 * - Add uploaded preview image for uploaded images.
 * - If possible, close the OS level file explorer window
 * after dragging and dropping a file from it into the dropzone.
 */
function Upload({
  kind,
  isMultiple,
  helperText,
  onFileSelect,
  onFileDelete,
  onFileReupload,
  uploadedFiles,
  isUploading,
  acceptTypes,
  reuploadFileName,
  isMaxNumberUploaded,
  buttonLabel,
  dropzoneLinkText,
  fileTypesAllowedText,
  maxNumberFilesText,
  className,
  dataTestId,
}) {
  const [isDragging, setIsDragging] = useState(false);
  const dragCounter = useRef(0);
  const fileInputRef = useRef(null);
  const MAX_UPLOADS_REACHED_TEXT = 'Max files reached.';

  useEffect(() => {
    // trigger file input when a file is set for reupload
    if (reuploadFileName && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [reuploadFileName]);

  const preventDefaultAndStopPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleBrowseClick = (e) => {
    e.stopPropagation();
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    preventDefaultAndStopPropagation(e);
  };

  const handleDragEnter = (e) => {
    preventDefaultAndStopPropagation(e);
    dragCounter.current += 1;
    if (dragCounter.current === 1) setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    preventDefaultAndStopPropagation(e);
    dragCounter.current -= 1;
    if (dragCounter.current === 0) setIsDragging(false);
  };

  const handleDrop = (e) => {
    preventDefaultAndStopPropagation(e);
    setIsDragging(false);
    dragCounter.current = 0;

    let files = Array.from(e.dataTransfer.files);

    if (!isMultiple) {
      files = files.slice(0, 1);
    }

    const allowedFileTypes = acceptTypes
      ? acceptTypes.split(',').map((type) => type.trim())
      : null; // allow all types if acceptTypes is falsy

    onFileSelect?.(files, allowedFileTypes);
  };

  const handleFileChange = (e) => {
    let files = Array.from(e.target.files);

    if (!isMultiple) {
      files = files.slice(0, 1);
    }

    const allowedFileTypes = acceptTypes
      ? acceptTypes.split(',').map((type) => type.trim())
      : null; // allow all types if acceptTypes is falsy

    onFileSelect?.(files, allowedFileTypes);

    e.target.value = null; // reset the file input for the next file.
  };

  const renderedUploadedFiles = uploadedFiles.map((file) => {
    let fadeClass = file.isUploading ? 'UploadedFile--fadingIn' : '';

    if (reuploadFileName === file.fileName) {
      fadeClass = 'UploadedFile--fadingOut';
    }

    return (
      <UploadedFile
        key={file.fileName}
        fileName={file.fileName}
        fileSize={formatUploadFileSize(file.fileSize)}
        error={file.isError ? file.errorMessage : null}
        onReupload={() => onFileReupload(file.fileName)}
        onDelete={() => onFileDelete(file.fileName)}
        isUploading={isUploading}
        className={fadeClass}
      />
    );
  });

  const shouldShowDropzoneOrButton = isMultiple || uploadedFiles.length === 0;

  return (
    <section
      className={classnames('Upload-container', className)}
      data-testid={dataTestId}>
      {helperText && <div className='Upload-helperText'>{helperText}</div>}
      {kind === KINDS.dropzone ? (
        <>
          {shouldShowDropzoneOrButton && (
            <div
              className={classnames(
                'Upload-dropzone',
                isMaxNumberUploaded && 'Upload-dropzone--isMaxNumberUploaded',
                isDragging && 'Upload--isDragging'
              )}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}>
              <div className='Upload-content'>
                <div className='Upload-icon'>
                  <Icon
                    kind='document'
                    color={isMaxNumberUploaded ? 'neutral-600' : 'navy-900'}
                  />
                </div>
                {isMaxNumberUploaded ? (
                  <div className='Upload-maxNumberUploaded'>
                    <InlineMessage
                      text={MAX_UPLOADS_REACHED_TEXT}
                      colorTheme='warning'
                      size='small'
                      leftIcon={<Icon kind='Warning' />}
                    />
                    <div className='Upload-filetypes'>
                      {fileTypesAllowedText}
                      <div>{maxNumberFilesText}</div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='Upload-instructions'>
                      Drag and drop or&nbsp;
                      <Link
                        text={dropzoneLinkText}
                        onClick={handleBrowseClick}
                      />
                    </div>
                    <div className='Upload-filetypes'>
                      {fileTypesAllowedText}
                      <div>{maxNumberFilesText}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {renderedUploadedFiles}
        </>
      ) : (
        <>
          {renderedUploadedFiles}
          <div className='Upload-buttonTriggerContainer'>
            {shouldShowDropzoneOrButton && (
              <Button
                type='button'
                kind='filled'
                size='medium'
                onClick={handleBrowseClick}
                className='Upload-buttonTrigger'
                isDisabled={isMaxNumberUploaded}
                leftIcon={
                  <Icon kind='Upload' size='smallMedium' color='neutral-100' />
                }>
                {buttonLabel}
              </Button>
            )}
            {isMaxNumberUploaded && (
              <InlineMessage
                text={MAX_UPLOADS_REACHED_TEXT}
                colorTheme='warning'
                size='small'
                leftIcon={<Icon kind='Warning' />}
              />
            )}
            {(isMultiple
              ? !isMaxNumberUploaded
              : renderedUploadedFiles.length === 0) && (
              // when isMultiple, show this jsx until max number of files is reached
              <div className='Upload-filetypes'>
                {fileTypesAllowedText}
                {isMultiple && <div>{maxNumberFilesText}</div>}
              </div>
            )}
          </div>
        </>
      )}
      <InputCore
        type='file'
        className='Upload-input'
        accept={acceptTypes}
        multiple={isMultiple}
        onChange={handleFileChange}
        ref={fileInputRef}
      />
    </section>
  );
}

Upload.propTypes = {
  kind: PropTypes.oneOf(['button', 'dropzone']),
  isMultiple: PropTypes.bool,
  helperText: PropTypes.string,
  onFileSelect: PropTypes.func.isRequired,
  onFileDelete: PropTypes.func.isRequired,
  onFileReupload: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      fileSize: PropTypes.number,
      isError: PropTypes.bool,
      errorMessage: PropTypes.string,
      isUploading: PropTypes.bool,
    })
  ).isRequired,
  isUploading: PropTypes.bool,
  acceptTypes: PropTypes.string,
  reuploadFileName: PropTypes.string,
  isMaxNumberUploaded: PropTypes.bool,
  buttonLabel: PropTypes.string,
  dropzoneLinkText: PropTypes.string,
  fileTypesAllowedText: PropTypes.string,
  maxNumberFilesText: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

Upload.defaultProps = {
  kind: KINDS.dropzone,
  isMultiple: false,
  helperText: '',
  acceptTypes: '',
  isUploading: false,
  reuploadFileName: null,
  isMaxNumberUploaded: false,
  buttonLabel: 'Browse',
  dropzoneLinkText: 'browse',
  fileTypesAllowedText: 'Only .PNG or .JPG files. Max 1MB per file.',
  maxNumberFilesText: null,
  className: null,
  dataTestId: null,
};

export default Upload;
