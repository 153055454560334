import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Color from '../Color/Color';
import { COLORS_KEYS } from '../Color/dist/Color-constants';

import * as Icons from './Icons';

import './Icon.css';

const SIZES = {
  'xx-xxxSmall': 'xx-xxxSmall',
  'x-xxxSmall': 'x-xxxSmall',
  xxxSmall: 'xxxSmall',
  xxSmall: 'xxSmall',
  xSmall: 'xSmall',
  small: 'small',
  smallMedium: 'smallMedium',
  medium: 'medium',
  large: 'large',
  xLarge: 'xLarge',
  xxLarge: 'xxLarge',
};

function Icon({ kind, color, size, rotate, className, dataTestId }) {
  const iconName = kind.charAt(0).toUpperCase() + kind.slice(1);
  const classes = classnames(
    'Icon',
    size && `Icon--${size}`,
    rotate && `Icon--rotate-${rotate}`,
    className
  );

  return (
    <Color
      color={color}
      className={classes}
      type='icon'
      dataTestId={`Icon-${dataTestId || kind}`}>
      {React.createElement(Icons[iconName])}
    </Color>
  );
}

Icon.propTypes = {
  kind: PropTypes.oneOf(Icons),
  color: PropTypes.oneOf(COLORS_KEYS),
  size: PropTypes.oneOf(Object.values(SIZES)),
  rotate: PropTypes.oneOf(['90', '180', '270']),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

Icon.defaultProps = {
  kind: 'info',
  color: null,
  size: 'medium',
  rotate: null,
  className: null,
  dataTestId: null,
};

export default Icon;
