import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';

import * as ApplicationIcons from './ApplicationIcons';

import './ApplicationIcon.css';

const SIZES = {
  xxSmall: 'xxSmall',
  xSmall: 'xSmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
  xLarge: 'xLarge',
  xxLarge: 'xxLarge',
};

const KINDS = {
  logo: 'logo',
  icon: 'icon',
  placeholder: 'placeholder',
};

const logoToComponentMap = {
  account: 'Account',
  ad: 'AD',
  aws: 'AWS',
  azure: 'Azure',
  azure_ad: 'AzureAD',
  gcp: 'GCP',
  okta: 'Okta',
  sap: 'SAP',
  salesforce: 'Salesforce',
  webappconsole: 'WebAppConsole',
  application: 'Application',
  database: 'Database',
  server: 'Server',
  placeholder: 'Placeholder',
};

function ApplicationIcon({ kind, className, size, icon, logo }) {
  const ApplicationIconName = logoToComponentMap[logo.toLowerCase()] || '';

  const classes = classnames(
    'ApplicationIcon',
    className,
    size && `ApplicationIcon--${size}`
  );

  function applicationIsSupported(iconName) {
    switch (iconName.toLowerCase()) {
      case 'aws':
      case 'gcp':
      case 'azure':
      case 'azuread':
      case 'salesforce':
      case 'okta':
      case 'ad':
      case 'sap':
      case 'account':
      case 'console':
      case 'application':
      case 'database':
      case 'server':
      case 'placeholder':
        return true;
      default:
        return false;
    }
  }

  return (
    <>
      {kind === KINDS.logo && (
        <div className={classes}>
          {applicationIsSupported(ApplicationIconName) &&
            React.createElement(ApplicationIcons[ApplicationIconName])}
        </div>
      )}
      {kind === KINDS.icon && <div className={classes}>{icon}</div>}
      {kind === KINDS.placeholder && (
        <div className={classes}>
          <Icon kind='placeholder' size={size} />
        </div>
      )}
    </>
  );
}

ApplicationIcon.propTypes = {
  kind: PropTypes.oneOf(Object.values(KINDS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  className: PropTypes.string,
  icon: PropTypes.node,
  logo: PropTypes.oneOf(ApplicationIcons),
};

ApplicationIcon.defaultProps = {
  size: 'small',
  kind: 'placeholder',
  className: null,
  icon: null,
  logo: '',
};

export default ApplicationIcon;
