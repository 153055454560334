import { Link } from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React from 'react';

function ViewScheduleLink({ setIsScheduleViewOpen, kind }) {
  const handleScheduleModal = (event) => {
    event.preventDefault();

    setIsScheduleViewOpen(true);
  };

  const isKindAccordion = kind === 'accordion';

  return (
    <Link
      href='/'
      url='/'
      onClick={(e) => {
        handleScheduleModal(e);
      }}
      text='View Schedule'
      prefixIconKind={isKindAccordion ? 'Calendar' : null}
      suffixIconKind={!isKindAccordion ? 'ChevronRight' : null}
      target='_self'
      shouldStopPropagation
    />
  );
}

ViewScheduleLink.propTypes = {
  setIsScheduleViewOpen: PropTypes.func.isRequired,
  kind: PropTypes.string,
};

ViewScheduleLink.defaultProps = { kind: 'accordion' };

export default ViewScheduleLink;
