import { Provider, useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import InnerHTML from 'dangerously-set-html-content';
import { push } from 'react-router-redirect';
import { initialiseEnv, ecmLogin } from 'ui-common/src/lib/utils';
import { Snackbar, ThemePreviewSnackbar, NotificationModal, AppLoading } from 'ui-components/src';
import { ConfirmProvider } from 'material-ui-confirm';
import { hideNotificationModal } from 'ui-common/src/utils/Loading-actions';
import {
  appConstantsAction,
  appConstantsSuccess,
  appMessages,
  getIntitialDataAction,
} from 'ui-common/src/utils/LoginActions';
import { APP_CONSTANTS, ACCESS_TOKEN } from 'ui-common/src/constants';
import CookieService from 'ui-common/src/utils/CookieService';
import SessionStorageService from 'ui-common/src/utils/SessionStorageService';
import ConnectedResponseMessageHandling from './misc/ResponseHandler/ResponseHandler';
import ConnectedLoadingHandling from './misc/Loading/Loading';
import store from './misc/store';
import Routes from './misc/routes';
// eslint-disable-next-line import/named
import { getModule } from './misc/dynamic-modules';
import {
  showPreviewModal,
  updateThemeConfigList,
} from './features/AdminHome/redux/adminHome-actions';
import { fetchECMVersion } from './features/MainLayout/Version/redux/version-actions';
import theme from './themes';
import { useQuery } from './misc/hooks';

process.env.REACT_APP_MODE === 'ECM' ? require('./AppHeader.css') : require('./App.css');

const SnackBarToast = () => {
  const [showLoginInfo, setShowLoginInfo] = useState(false);
  const loginInfo = useSelector((state) => state.user.loginInfo);

  useEffect(() => {
    if (loginInfo && loginInfo.lastLoginTime && loginInfo.showLoginSnack) {
      setShowLoginInfo(true);
    }
  }, [loginInfo]);

  return (
    <div className="snackBarBackground">
      <Snackbar
        open={showLoginInfo}
        onSnackBarClose={() => {
          setShowLoginInfo(false);
        }}
        message={
          loginInfo
            ? `Last Login: ${moment
                .utc(loginInfo.lastLoginTime, 'YYYY-MM-DD HH:mm:ss')
                .local()
                .format('MMM DD YYYY hh:mm A')}`
            : ''
        }
        verticalPosition="bottom"
        horizontalPosition="left"
      />
    </div>
  );
};
// eslint-disable-next-line react/prop-types
const ClientCustomScript = ({ customCode }) => {
  const html = `<script src="${customCode}"></script>`;

  return <InnerHTML html={html} />;
};

const App = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const messages = useSelector((state) => state.user.messages) || {};
  const envConfig = useSelector((state) => state.user.domainInfo);
  const [jsonEditorTheme, setJsonEditorTheme] = useState({});
  const [previewTheme, setPreviewTheme] = useState(false);
  const [configThemeObj, setThemeObj] = useState({});
  const appLoading = useSelector((state) => state.user.appLoading);

  const { defaultThemeConfig } = useSelector((state) => state.adminHome.uiConfig);

  let domainName = query.get('domain') || window.location.hostname.split('.')[0];
  const locale = query.get('locale') || 'en';

  if (query.get('domain')) localStorage.setItem('localDomain', query.get('domain'));

  if (domainName === 'localhost' || domainName === '127') {
    domainName = localStorage.getItem('localDomain');
  }

  if (query.get('DEMO_MODE')) window.DEMO_MODE = true;

  const { type, visible, title, details } = useSelector((state) => state.loader.notificationDialog);

  useEffect(() => {
    document.addEventListener('mousedown', () =>
      document.querySelector('body').classList.remove('keydown')
    );
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Tab') {
        document.querySelector('body').classList.add('keydown');
      }
    });
  }, []);

  useEffect(() => {
    const serializedState = JSON.parse(localStorage.getItem('uiConfigTheme'));
    const serializedStatePreview = JSON.parse(localStorage.getItem('uiConfigThemeModal'));

    // ({ cssTheme, muiTheme } = themes[THEME] || themes.defaultTheme);
    // &Nagesh, THEME_COLOR is already locked line 82. how change in RGB_THEME_COLORS affect output of following code.

    if (
      serializedState &&
      Object.keys(serializedState).length > 0 &&
      Object.keys(serializedState.previewTheme).length > 0 &&
      serializedStatePreview.showPreviewTheme
    ) {
      const preview = theme(JSON.parse(serializedState.previewTheme));

      Object.keys(preview.cssTheme).forEach((key) => {
        const value = preview.cssTheme[key];

        document.documentElement.style.setProperty(key, value);
      });
      setJsonEditorTheme(preview);
      setPreviewTheme(true);
      setThemeObj(JSON.parse(serializedState.previewTheme));
    } else if (defaultThemeConfig && Object.keys(defaultThemeConfig).length) {
      const THEME_COLOR = theme(defaultThemeConfig);

      Object.keys(THEME_COLOR.cssTheme).forEach((key) => {
        const value = THEME_COLOR.cssTheme[key];

        document.documentElement.style.setProperty(key, value);
      });
      setJsonEditorTheme(THEME_COLOR);
      setPreviewTheme(false);
      setThemeObj(defaultThemeConfig);
    }
  }, [defaultThemeConfig]);

  // Make sure that access token is there when this method is called.
  const getInitialData = () => {
    // get All the initial data i.e UserDetails, Messages, HeaderDetails, userModules, Themes.
    dispatch(getIntitialDataAction(query.get('locale') || localStorage.getItem('locale') || 'en'));
  };

  const userloginPage = (appConstants) => {
    // for localhost we direct to /login in eseffect.
    if (window.location.hostname === 'localhost') return;

    if (appConstants.SSO_LOGIN === true) {
      // if sso login, we dont fetch new access token. we redirect to IDP login page.
      const { SSO_LOGIN_URL, CALLBACK_URL } = appConstants;

      // CALLBACK_URL = 'http://127.0.0.1:3000/ECMv6/auth';

      window.location.href = `//${SSO_LOGIN_URL}?url=/ECMv6/request/requestHome&callbackurl=${CALLBACK_URL}`;
    } else if (appConstants.SSO_LOGIN === 'ECM') {
      // This case is when ECM sso login. which happens through IDP but still need the MS_REDIRECT_URL cookie
      ecmLogin();
    } else {
      // This handles pure ECM login but still goes through ecmLogin method.
      ecmLogin();
    }
  };

  useEffect(() => {
    const sessionItems = SessionStorageService.get(APP_CONSTANTS);
    const appConstants = sessionItems && sessionItems[domainName];

    if (appConstants && Object.keys(appConstants).length) {
      initialiseEnv(appConstants);
      dispatch(appConstantsSuccess(appConstants));

      if (!query.get('jwt') && !CookieService.get(ACCESS_TOKEN)) {
        userloginPage(appConstants);
      }
    } else {
      dispatch(appConstantsAction(domainName, locale));
    }
  }, []);
  // localhost useeffect to get messages
  // becos unless messages arrive, login screen wont show
  useEffect(() => {
    if (window.location.hostname === 'localhost') {
      if (Object.keys(messages).length !== 0) {
        push('/login');
      }
    }
  }, [messages]);
  useEffect(() => {
    if (envConfig && Object.keys(envConfig).length) {
      initialiseEnv(envConfig);

      // We need messages response to render App in localhost
      if (window.location.hostname === 'localhost') {
        dispatch(
          appMessages(
            query.get('locale') || localStorage.getItem('locale') || 'en',
            envConfig.GATEWAY_API_URL
          )
        );
      }

      if (!query.get('jwt') && CookieService.get(ACCESS_TOKEN) === undefined) {
        userloginPage(envConfig);

        return;
      }

      // if jwt query param exist , it means its redirect from the IDP. by this time access token not yet available
      // ecm login will go inside the IF(if aceess token there).
      // idp login will go inside IF, if /auth?jwt is not the route.
      // if the route is infact /auth?jwt , all these calls are called in AuthRedirect component.
      if (CookieService.get(ACCESS_TOKEN)) {
        getInitialData();
      }

      dispatch(fetchECMVersion());
    }
  }, [envConfig]);

  // Dont show ugly messsage keys.
  if (Object.keys(messages).length === 0 && window.location.pathname !== '/ECMv6/auth') {
    return <AppLoading />;
  }

  // Wait Untill initial data is loaded..
  if (appLoading) {
    return <AppLoading />;
  }

  const closePreviewModal = () => {
    setPreviewTheme(false);
    dispatch(showPreviewModal(false));
    const uiConfigThemeModal = JSON.stringify({
      showPreviewTheme: false,
    });

    localStorage.setItem('uiConfigThemeModal', uiConfigThemeModal);
    window.location.href = '/ECMv6/admin/adminHome/uiConfig';
  };

  const redirectOnSuccess = () => {
    setPreviewTheme(false);
    dispatch(showPreviewModal(false));
    const uiConfigThemeModal = JSON.stringify({
      showPreviewTheme: false,
    });

    localStorage.setItem('uiConfigThemeModal', uiConfigThemeModal);
    window.location.href = '/ECMv6/admin/adminHome/uiConfig';
  };

  const onUpdateTheme = () => {
    dispatch(updateThemeConfigList(configThemeObj, redirectOnSuccess));
  };

  const hideNotifDialog = () => {
    dispatch(hideNotificationModal());
  };

  return (
    <IntlProvider locale="en" messages={messages}>
      <MuiThemeProvider theme={jsonEditorTheme.muiTheme}>
        {/* <Layout> */}
        <Routes dynamicModules={getModule} />
        {/* </Layout> */}
        <ConnectedResponseMessageHandling />
        <ConnectedLoadingHandling />
        <SnackBarToast />
        <ThemePreviewSnackbar
          open={previewTheme}
          onSnackBarClose={() => {
            closePreviewModal();
          }}
          onUpdateTheme={onUpdateTheme}
        />
        {CookieService.get(ACCESS_TOKEN) && envConfig && envConfig.FEEDBACK_SCRIPT ? (
          <ClientCustomScript customCode={envConfig.FEEDBACK_SCRIPT} />
        ) : null}
        <ConfirmProvider>
          <NotificationModal
            visible={visible}
            title={title}
            details={details}
            type={type}
            onClose={hideNotifDialog}
          />
        </ConfirmProvider>
      </MuiThemeProvider>
    </IntlProvider>
  );
};
const StoredApp = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

export default StoredApp;
