/* eslint-disable indent */
import './CheckOutCredential.css';

import {
  isObjectEmpty,
  getCurrentDate,
  InSeparator,
  InterPoint,
  isObjectEmpty,
  useDetectScroll,
} from '@saviynt/common';
import {
  AccordionStepper,
  AlertBanner,
  Box,
  Button,
  FooterActionBar,
  Icon,
  InlineMessage,
  ModalPageHeader,
  Typography,
} from '@saviynt/design-system';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  handleClearCOCForm,
  handleJustification,
} from '../../action/CredentialRequestFormAction';
import { requestSubmittedId } from '../../action/requestSubmittedDetailsAction';
import CheckOutCredentialAccountService from '../../services/CheckOutCredential/CheckOutCredentialAccountService';
import CheckOutCredentialDurationService from '../../services/CheckOutCredential/CheckOutCredentialDurationService';
import { postCheckOutCredentialRequest } from '../../utilities/api/getDataFromApi';
import AccountSelection from './AccordionContentPanels/AccountSelection/AccountSelection';
import JustificationDetails from './AccordionContentPanels/JustificationDetails/JustificationDetails';
import TimeAccessDuration from './AccordionContentPanels/TimeAccessDuration/TimeAccessDuration';
import AccountListModalIsland from './AccountListModalIsland/AccountListModalIsland';
import ViewScheduleLink from './ViewScheduleModalIsland/ViewScheduleLink/ViewScheduleLink';
import ViewScheduleModalIsland from './ViewScheduleModalIsland/ViewScheduleModalIsland';

// WIP TODO for the entire page:
// Form critical inline messages need to be Dynamic. (Maybe only duration)
// Page header subheading needs to have the correct variables
// Account RadioButton description needs API to provide desctption
// Set view Schedule link to open a ModalIsland that shows session schedules calender

const initialCriticalState = {
  accountId: false,
  businessjustification: false,
  startdate: false,
  enddate: false,
};

const REQUIRED_MESSAGE_TEXT = 'This required field is incomplete.';

function CheckOutCredential() {
  // Accordion expanded booleans
  const [isAccountSelection, setIsAccountSelection] = useState(false);
  const [isExpandedDuration, setIsExpandedDuration] = useState(false);
  const [isExpandedDetail, setIsExpandedDetail] = useState(false);
  // AccountSelection
  const [accountListArray, setAccountListArray] = useState(null);
  const [accountSelection, setAccountSelection] = useState(null);
  const [isAccountListModal, setIsAccountListModal] = useState(false);
  // TimeAccessDuration
  const [isScheduleViewOpen, setIsScheduleViewOpen] = useState(false);
  const [toggleChoice, setToggleChoice] = useState(1);
  const [durationContinue, setDurationContinue] = useState(false);
  const [startDateOfNextCredential, setStartDateOfNextCredential] =
    useState(null);
  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState(null);
  const [durationAccordionValue, setDurationAccordionValue] = useState(null);
  const [isTimeBlocked, setIsTimeBlocked] = useState(false);
  // Details
  const [justificationInputValue, setJustificationInputValue] = useState('');
  const [isUserSubmitting, setIsUserSubmitting] = useState(false);
  // Critical State
  const [isFormCritical, setIsFormCritical] = useState(initialCriticalState);
  const [isFormFailureAlertVisible, setIsFormFailureAlertVisible] =
    useState(false);
  const [submitResponseErrorMessage, setSubmitResponseErrorMessage] = useState(
    'Submit Response Error Message'
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const accordionContentRef = useRef(null);
  const accountListModalContentRef = useRef(null);
  const viewScheduleModalContentRef = useRef(null);
  const isNowTab = toggleChoice === 1;

  const [cookies] = useCookies(['user_name']);
  const userName = cookies.user_name;

  // Redux Selectors
  const endpointDetails = useSelector(
    (state) => state.privilegedAccess.SelectedListDetails
  );
  const checkOutCredentialForm = useSelector(
    (state) => state.credentialRequestForm
  );

  useEffect(() => {
    if (isObjectEmpty(endpointDetails)) {
      // Can't use goBack in react.strict
      history.push('/pam');
    }
  }, []);

  const {
    accountId: formAccountId,
    businessjustification: formJustification,
    startdate: formStartDate,
    enddate: formEndDate,
  } = useSelector((state) => state.credentialRequestForm.sessions[0]);

  CheckOutCredentialAccountService(
    accountSelection,
    accountListArray,
    setAccountListArray,
    setIsAccountSelection,
    dispatch,
    userName,
    endpointDetails?.endpointKey
  );

  const { checkedAccountSessions } = CheckOutCredentialDurationService(
    accountSelection,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isNowTab,
    setDurationAccordionValue,
    setStartDateOfNextCredential,
    dispatch
  );

  const { isScrolled: accordionContentRefIsScrolled } = useDetectScroll(
    null,
    accordionContentRef
  );
  const { isScrolled: accountModalSectionRefIsScrolled } = useDetectScroll(
    null,
    accountListModalContentRef
  );

  const { isScrolled: viewScheduleModalSectionRefIsScrolled } = useDetectScroll(
    null,
    viewScheduleModalContentRef
  );
  // Extracted Check Out Credential page comps
  const getSubHeadingContent = () => (
    <div className='ModalPage-subHeadingContent'>
      <Typography kind='body2' className='ModalPage-subHeadingContent-body'>
        {endpointDetails?.endpointName}
      </Typography>
      <InterPoint color='neutralSubtlest' />
      <Typography kind='body2' className='ModalPage-subHeadingContent-body'>
        {endpointDetails?.assetType}
      </Typography>
      <InSeparator color='neutralInverse' />
      <Typography kind='body2' className='ModalPage-subHeadingContent-body'>
        {endpointDetails?.platformType}
      </Typography>
    </div>
  );

  // Event handlers
  const closeHandler = () => {
    history.goBack();
  };

  // Close Account Accordion on selection
  useEffect(() => {
    if (accountSelection) {
      setIsAccountSelection(false);
      setIsExpandedDuration(true);
    }
  }, [accountSelection]);

  // Close Duration Accordion on selection
  useEffect(() => {
    if (isNowTab && durationAccordionValue && durationContinue) {
      setIsExpandedDuration(false);
      setIsExpandedDetail(true);
      setDurationContinue(false);
    }

    if (!isNowTab && durationAccordionValue && durationContinue) {
      setIsExpandedDuration(false);
      setIsExpandedDetail(true);
      setDurationContinue(false);
    }
  }, [durationAccordionValue, durationContinue]);

  // TODO: Refactor this with details accordion
  const preSubmitJustification = Boolean(
    checkOutCredentialForm.requestor &&
      checkOutCredentialForm.requestedfor &&
      formAccountId &&
      formStartDate &&
      formEndDate &&
      justificationInputValue
  );

  const postSubmitValidation = Boolean(
    preSubmitJustification && formJustification
  );

  const isValidInput = (input) => input !== null && input !== '';

  const handleIsCritical = (key, bool) => {
    setIsFormCritical((prevState) => ({
      ...prevState,
      [key]: bool,
    }));
  };

  const handleCOCRequest = () => {
    if (!isValidInput(formAccountId)) {
      console.error('Invalid Account ID');

      handleIsCritical('accountId', true);
    }

    if (
      !isValidInput(formStartDate) ||
      Number.isNaN(Date.parse(formStartDate))
    ) {
      console.error('Invalid Start Date');

      handleIsCritical('startdate', true);
    }

    if (!isValidInput(formEndDate) || Number.isNaN(Date.parse(formEndDate))) {
      console.error('Invalid End Date');

      handleIsCritical('enddate', true);
    }

    if (!isValidInput(justificationInputValue)) {
      console.error('Invalid Business Justification');

      handleIsCritical('businessjustification', true);
    }

    if (preSubmitJustification) {
      dispatch(
        handleJustification(justificationInputValue, 'Justification success')
      );
    }
  };

  // ! Happy path submit logic order
  // 1. all req fields complete
  // 2. user click bold submit button
  // 3. recalc start and end date
  // 4. Check form validation before request (should handle any startDate conflicts with schedule)
  // 5. make COC request call (handle errors branching cases)
  // 6. Upload API send and receive (Nathans portion)
  // 7. Store any data required for notifications or listRows in redux?
  // 8. Navigation to Landing Page, and set the checkOutCredentialForm blank again
  // Step 9: if (formJustification) history.push('/pam');
  // TODO: disable submit if duration conflict before step 4

  // Step 3:
  useEffect(() => {
    if (preSubmitJustification && formJustification) {
      setStartDate(getCurrentDate());
      // TODO: set this to true again if the user changes the date as well. Right now it only lets you submit if the Justification string changes.
      setIsUserSubmitting(true);
    }
  }, [formJustification]);

  useEffect(() => {
    // Make sure isTimeBlocked is updated properly with the updated StartDate from step 3, make isUserSubmitting more functional
    if (postSubmitValidation && !isTimeBlocked && isUserSubmitting) {
      // Convert formStartDate and formEndDate to Date objects
      const startDateUpdated = new Date(formStartDate);
      const endDateUpdated = new Date(formEndDate);

      // TODO: Add 2 minutes to each date if needed
      startDateUpdated.setMinutes(startDateUpdated.getMinutes() + 1);
      endDateUpdated.setMinutes(endDateUpdated.getMinutes() + 1);

      const commitTimeOffset = {
        accesstype: 'PRIVILEGEDACCESS',
        requestor: checkOutCredentialForm.requestor,
        requestedfor: checkOutCredentialForm.requestedfor,
        comments: 'Testing',
        sessions: [
          {
            accountId: formAccountId,
            businessjustification: formJustification,
            startdate: startDateUpdated.toISOString(),
            enddate: endDateUpdated.toISOString(),
          },
        ],
        pamtype: 'credentials',
      };

      postCheckOutCredentialRequest(commitTimeOffset)
        .then((res) => {
          // TODO: for Nathan 😁
          // Step 6:
          // Example res:
          // requestid: "9290912"
          // requestkey: 3341

          // Step 9:
          // Redirect after successful response
          if (res.status === 400) {
            res.json().then((errorBody) => {
              setIsFormFailureAlertVisible(true);
              setSubmitResponseErrorMessage(errorBody.message);
            });
          } else {
            dispatch(
              requestSubmittedId(res?.requests[0]?.requestid, 'success')
            );
            history.push('/pam');
            dispatch(handleClearCOCForm('Clear COC Request Form success'));
          }
        })
        .catch((error) => {
          console.error('Error during Check Out Request:', error);
        });

      setIsUserSubmitting(false);
    }
  }, [formEndDate, isUserSubmitting]);

  // Reset Crits OnChange
  useEffect(() => {
    if (formAccountId) handleIsCritical('accountId', false);
  }, [formAccountId]);

  useEffect(() => {
    if (formStartDate) handleIsCritical('startdate', false);
  }, [formStartDate]);

  useEffect(() => {
    if (formEndDate) handleIsCritical('enddate', false);
  }, [formEndDate]);

  useEffect(() => {
    if (isValidInput(justificationInputValue))
      handleIsCritical('businessjustification', false);
  }, [justificationInputValue]);

  const RequiredMessage = (
    <InlineMessage
      text={REQUIRED_MESSAGE_TEXT}
      colorTheme='critical'
      size='small'
      leftIcon={<Icon kind='AlertCritical' color='critical-700' />}
    />
  );

  return (
    <Box className='ModalPage-master'>
      <ModalPageHeader
        title='Check Out Credential'
        subHeadingContent={getSubHeadingContent()}
        closeHandler={() => closeHandler()}
        className='ModalPage-header'
      />
      <Box className='ModalPage-alertBanner'>
        <AlertBanner
          colorTheme='Critical'
          title='Submit Failure'
          description={submitResponseErrorMessage}
          isVisible={isFormFailureAlertVisible}
          onCancel={() => setIsFormFailureAlertVisible(false)}
        />
      </Box>
      <section className='ModalPage' ref={accordionContentRef}>
        <Box className='ModalPage-scrollingArea'>
          {/* Modals */}
          <AccountListModalIsland
            accountListArray={accountListArray}
            accountListModalContentRef={accountListModalContentRef}
            sectionRefIsScrolled={accountModalSectionRefIsScrolled}
            accountSelection={accountSelection}
            setAccountSelection={setAccountSelection}
            isAccountListModal={isAccountListModal}
            setIsAccountListModal={setIsAccountListModal}
          />
          <ViewScheduleModalIsland
            viewScheduleModalContentRef={viewScheduleModalContentRef}
            sectionRefIsScrolled={viewScheduleModalSectionRefIsScrolled}
            isScheduleViewOpen={isScheduleViewOpen}
            setIsScheduleViewOpen={setIsScheduleViewOpen}
            checkedAccountSessions={checkedAccountSessions}
          />
          <AccordionStepper
            isExpanded={isAccountSelection}
            setIsExpanded={setIsAccountSelection}
            prefixIconKind='account'
            headerText='Which account do you need?'
            primarySupportingText='Select an account or create a new one'
            accordionValue={accountSelection?.title}
            isCritical={isFormCritical.accountId}
            criticalMessage={isFormCritical.accountId && RequiredMessage}>
            {accountListArray?.length !== 0 ? (
              <AccountSelection
                accountListArray={accountListArray}
                accountSelection={accountSelection}
                setAccountSelection={setAccountSelection}
                setIsAccountListModal={setIsAccountListModal}
              />
            ) : (
              // TODO: Update this message, and case handling, if we find it necessary through testing.
              <Typography kind='body1'>
                There are no accounts to be displayed.
              </Typography>
            )}
          </AccordionStepper>
          {accountSelection && (
            <AccordionStepper
              isExpanded={isExpandedDuration}
              setIsExpanded={setIsExpandedDuration}
              prefixIconKind='time'
              headerText='When do you need access?'
              primarySupportingText='Select a timeframe'
              accordionValue={durationAccordionValue}
              suffixLink={
                <ViewScheduleLink
                  setIsScheduleViewOpen={setIsScheduleViewOpen}
                />
              }
              isCritical={isFormCritical.startdate || isFormCritical.enddate}
              criticalMessage={
                (isFormCritical.startdate || isFormCritical.enddate) &&
                RequiredMessage
              }>
              <TimeAccessDuration
                toggleChoice={toggleChoice}
                setToggleChoice={setToggleChoice}
                setDurationContinue={setDurationContinue}
                startDateOfNextCredential={startDateOfNextCredential}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                isTimeBlocked={isTimeBlocked}
                setIsTimeBlocked={setIsTimeBlocked}
                setIsScheduleViewOpen={setIsScheduleViewOpen}
                accountSelection={accountSelection}
              />
            </AccordionStepper>
          )}
          {startDate && endDate && formStartDate && formEndDate && (
            <AccordionStepper
              isExpanded={isExpandedDetail}
              setIsExpanded={setIsExpandedDetail}
              prefixIconKind='document'
              headerText='Do you have other details to include?'
              primarySupportingText='Provide optional comments, attachments, and ticket number'
              accordionValue={
                checkOutCredentialForm?.sessions[0].businessjustification
              }>
              <JustificationDetails
                isCritical={isFormCritical.businessjustification}
                setIsCritical={() => {}}
                justificationInputValue={justificationInputValue}
                setJustificationInputValue={setJustificationInputValue}
              />
            </AccordionStepper>
          )}
        </Box>
      </section>
      <FooterActionBar
        className='ModalPage-footer'
        isScrolled={accordionContentRefIsScrolled}>
        <Button
          kind={preSubmitJustification ? 'filled' : 'outlined'}
          isDisabled={isTimeBlocked}
          onClick={() => handleCOCRequest()}
          rightIcon={<Icon kind='arrowRight' />}>
          Submit
        </Button>
      </FooterActionBar>
    </Box>
  );
}

export default CheckOutCredential;
