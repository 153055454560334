import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../../Icon/Icon';
import CheckboxBase from '../CheckboxCore/CheckboxCore';
import { SIZES } from '../constants';

import './CheckboxCard.css';

function CheckboxCard({
  name,
  label,
  size,
  title,
  icon,
  isDisabled,
  isChecked,
  isIndeterminate,
  handleChange,
  showHoverEffect,
  dataTestId,
  error,
}) {
  const classes = classnames(
    'Checkbox',
    'Checkbox-card',
    isDisabled && 'Checkbox--disabled',
    icon && 'Checkbox--withIcon',
    isChecked && 'Checkbox--checked',
    !showHoverEffect && 'Checkbox--hoverEffectDisabled',
    error && 'Checkbox--error'
  );

  const renderedError = error && (
    <div className='Checkbox-errorWrapper'>
      <Icon kind='alertCritical' color='critical-700' size='xxxSmall' />
      {error}
    </div>
  );

  const renderedLabelTitleIcon = (title || label) && (
    <div className='Checkbox-iconTextWrapper'>
      {icon && <div className='Checkbox-iconWrapper'>{icon}</div>}

      <div className='Checkbox-textWrapper'>
        <div className='Checkbox-titleText'>{title}</div>
        <div className='Checkbox-labelText'>{label}</div>
      </div>
    </div>
  );

  return (
    <div className='Checkbox-container'>
      <div className={classes}>
        <label className='Checkbox-label'>
          {renderedLabelTitleIcon}
          <CheckboxBase
            name={name}
            size={size}
            isChecked={isChecked}
            isDisabled={isDisabled}
            isIndeterminate={isIndeterminate}
            handleChange={handleChange}
            dataTestId={dataTestId}
          />
        </label>
        <span className='Checkbox-overlay' />
      </div>
      {renderedError}
    </div>
  );
}

CheckboxCard.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf([SIZES.small, SIZES.medium, SIZES.large]),
  title: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  handleChange: PropTypes.func,
  showHoverEffect: PropTypes.bool,
  error: PropTypes.string,
  dataTestId: PropTypes.string,
};

CheckboxCard.defaultProps = {
  name: null,
  size: SIZES.medium,
  title: null,
  label: null,
  icon: null,
  isChecked: false,
  isDisabled: false,
  isIndeterminate: false,
  handleChange: null,
  showHoverEffect: false,
  error: null,
  dataTestId: null,
};

export default CheckboxCard;
