import {
  listAPIListReceivedActionType,
  listRowSelectedActionType,
} from '../action/PrivilegedAccessAction';
// TODO: set SelectedListDetails to null when not testing
const initialState = {
  ListRowItems: {},
  SelectedListDetails: {},
};

export const privilegedAccessReducer = (state = initialState, action) => {
  switch (action.type) {
    case listAPIListReceivedActionType: {
      return {
        ...state,
        ListRowItems: action.payload,
      };
    }

    case listRowSelectedActionType: {
      return {
        ...state,
        SelectedListDetails: action.payload,
      };
    }

    default:
      return state;
  }
};

export default privilegedAccessReducer;
