import { formatLocalizedDateTime } from '@saviynt/common';
import {
  ApplicationIcon,
  ButtonIcon,
  Chip,
  Icon,
} from '@saviynt/design-system';
import React from 'react';
import { useHistory } from 'react-router-dom';

import CountdownTimer from '../components/CountdownTimer/CountdownTimer';

const useCreateRowObject = (row, isModalOpen, setIsModalOpen, setIconClickedForRow, listRowData, setListRowData) => {
  const history = useHistory();

  const SESSION_STATUSES = {
    SESSION_APPROVED: 1,
    SESSION_ACTIVE: 2,
  };
  const SESSION_STARTED =
    new Date(row?.sessionStartDate).getTime() <= new Date().getTime() &&
    row?.sessionStatus === SESSION_STATUSES.SESSION_ACTIVE;
  const ENDPOINT = 'endpoint';
  const SESSION = 'session';
  const ACCOUNT = 'account';
  const CREDENTIALLESS = 'CREDENTIALLESS';

  const createIcon = (type, kind, color, size) => {
    if (type === ACCOUNT) {
      return <Icon kind='Account' color='neutral-1000' size='small' />;
    }

    return <Icon kind={kind} color={color} size={size} />;
  };

  const createButtonIcon = (actionMap, kind, icon, size, isDisabled) => (
    <ButtonIcon
      size={size}
      kind={kind}
      icon={icon}
      isDisabled={isDisabled}
      onClick={(e) => {
        e.stopPropagation();
        actionMap();
      }}
    />
  );

  const getObjectForCategory = (account, platform, type) => {
    const icon = createIcon(type);

    const obj = {
      account,
      platform,
      icon,
    };

    return obj;
  };

  const getObjectForTitle = (endpointName, accountName) => {
    const obj = [
      {
        kind: 'text',
        text: endpointName,
      },
      {
        kind: 'label-value',
        label: 'Account',
        value: accountName,
      },
    ];

    return obj;
  };

  const getObjectForDescription = (data) => {
    let obj = [];

    if (data.type === ACCOUNT) {
      obj = [
        {
          kind: 'text',
          text: data.accountDescription,
        },
      ];
    } else if (data.type === ENDPOINT || data.type === SESSION) {
      obj = [];

      switch (data?.assetType?.toLowerCase()) {
        case 'server':
        case 'application':
        case 'database':
          obj.push({
            kind: 'icon-text',
            icon: createIcon(data.type, data.assetType, null, 'small'),
            text: data.assetType,
          });
          break;
        default:
          break;
      }

      switch (data?.platformType?.toLowerCase()) {
        case 'aws':
        case 'gcp':
        case 'azure':
        case 'okta':
        case 'ad':
        case 'sap':
          obj.push({
            kind: 'avatar-text',
            avatar: (
              <ApplicationIcon
                size='xxSmall'
                kind='logo'
                logo={data.platformType}
              />
            ),
            text: data.platformType,
          });
          break;
        default:
          break;
      }

      if (data.epCustomproperty10) {
        obj.push({
          kind: 'label-value',
          label: 'Location',
          value: data.epCustomproperty10,
        });
      }

      if (data.endpointDescription) {
        obj.push({ kind: 'text', text: data.endpointDescription });
      }
    }

    return obj;
  };

  function getTitleObjectBasedOnType() {
    if (row.type === SESSION) {
      return getObjectForTitle(row.endpointName, row.accountName);
    }

    if (row.type === ACCOUNT) {
      return [{ kind: 'text', text: row.accountName }];
    }

    return [{ kind: 'text', text: row.endpointName }];
  }

  const createPrimaryIconOnClick = () => {
    let primaryIconOnClick = () => {};

    if (row.type === ENDPOINT) {
      primaryIconOnClick = () => {
        history.push('/pam/check-out-credential');
      };
    } else if (row.type === SESSION) {
      setIsModalOpen(!isModalOpen);
      setIconClickedForRow(row);
    }

    return primaryIconOnClick();
  };

  const createSecondaryIconOnClick = () => {
    const secondaryIconOnClick = () => {};

    if (row.type === ENDPOINT) {
      // todo : go to connect-to-session page
    } else if (row.type === SESSION) {
      // todo: perform connect to session launch
    }

    return secondaryIconOnClick();
  };

  function getPrimaryIconObject() {
    if (row.type === ENDPOINT) {
      return createIcon(null, 'ArrowRight', 'navy-900', 'medium');
    }

    if (row.type === SESSION) {
      const icon = createIcon(null, 'SubtractCircle', 'critical-700', 'medium');

      if (SESSION_STARTED) {
        return createButtonIcon(
          createPrimaryIconOnClick,
          'outlinedCritical',
          icon,
          'large'
        );
      }

      return createButtonIcon(
        createPrimaryIconOnClick,
        'bold',
        icon,
        'large',
        true
      );
    }

    return null;
  }

  function getSecondaryIconObject() {
    if (row.type === ENDPOINT) {
      return createIcon(null, 'ConnectPlug', 'navy-700', 'medium');
    }

    if (
      row.type === SESSION &&
      row?.pamType?.toUpperCase() === CREDENTIALLESS
    ) {
      const icon = createIcon(null, 'LaunchRocket', 'neutral-000', 'medium');

      if (SESSION_STARTED) {
        return createButtonIcon(
          createSecondaryIconOnClick,
          'bold',
          icon,
          'large'
        );
      }

      return createButtonIcon(
        createSecondaryIconOnClick,
        'bold',
        icon,
        'large',
        true
      );
    }

    return null;
  }

  function getStatusObjectBasedOnType() {
    if (row?.type?.toLowerCase() === SESSION) {
      if (SESSION_STARTED) {
        return (
          <Chip
            kind='subtleFilled'
            color='brandSecondary'
            label={(<CountdownTimer
              targetDate={row?.sessionEndDate}
              leftText='left'
              onTimerExpired={() => {
                // todo : add fade out transition
                const updatedListRowData = { ...listRowData };

                updatedListRowData.result.userAccesses =
                  updatedListRowData.result.userAccesses.filter(
                    (access) => access.requestAccessKey !== row.requestAccessKey
                  );
                setListRowData(updatedListRowData);
              }}
            />)}
            leftIcon={<Icon kind='Time' size='medium' />}
          />
        );
      }

      if (!row.sessionStatus) {
        return (
          <Chip kind='subtleFilled' color='info' label='Pending Approval' />
        );
      }

      if (row.sessionStatus === SESSION_STATUSES.SESSION_APPROVED) {
        return (
          <Chip
            kind='subtleFilled'
            color='brandSecondary'
            label={formatLocalizedDateTime(row?.sessionStartDate, false, false)}
            leftIcon={<Icon kind='Calendar' size='medium' />}
          />
        );
      }
    }

    return null;
  }

  const createObjectForRow = () => {
    const rowObject = {
      category:
        row.type === ACCOUNT
          ? getObjectForCategory(row.accountName, row.platformType, row.type)
          : null,
      title: getTitleObjectBasedOnType(),
      description: getObjectForDescription(row),

      status: getStatusObjectBasedOnType(row),

      primaryIcon: getPrimaryIconObject(),
      secondaryIcon: getSecondaryIconObject(),
      hasMoreOptions: row.type === SESSION,
      type: row.type,
    };

    return rowObject;
  };

  return createObjectForRow;
};

export default useCreateRowObject;

