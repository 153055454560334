import { format } from 'date-fns';

const initialState = {
  accesstype: 'PRIVILEGEDACCESS',
  requestor: '',
  requestedfor: '',
  comments: 'Testing',
  sessions: [
    {
      accountId: null,
      businessjustification: null,
      startdate: null,
      enddate: null,
    },
  ],
  pamtype: 'credentials',
};

export const credentialRequestFormReducer = (state = initialState, action) => {
  switch (action.type) {
    // Combined requestor and requestedfor as they should always be the same value
    case 'cocRequestRequestor': {
      return {
        ...state,
        requestor: action.payload,
        requestedfor: action.payload,
      };
    }

    case 'cocRequestAccountId': {
      return {
        ...state,
        sessions: [{ ...state.sessions[0], accountId: action.payload }],
      };
    }

    case 'cocRequestBusinessJustification': {
      return {
        ...state,
        sessions: [
          { ...state.sessions[0], businessjustification: action.payload },
        ],
      };
    }

    case 'cocRequestDateRange': {
      const getUserTimezoneOffset = (date) => {
        const offsetInMinutes = date.getTimezoneOffset();

        return offsetInMinutes * 60 * 1000; // Convert minutes to milliseconds
      };

      const applyTimezoneOffset = (date, offset) =>
        new Date(date.getTime() + offset * 2);

      const timezoneOffset = getUserTimezoneOffset(new Date());

      // Parse the dates from the payload
      const startDate = new Date(action.payload.start);
      const endDate = new Date(action.payload.end);

      // Apply the timezone offset
      const localStartDate = applyTimezoneOffset(startDate, timezoneOffset);
      const localEndDate = applyTimezoneOffset(endDate, timezoneOffset);

      // Format the dates
      const formattedStartDate = format(
        localStartDate,
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      );
      const formattedEndDate = format(
        localEndDate,
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      );

      return {
        ...state,
        sessions: [
          {
            ...state.sessions[0],
            startdate: formattedStartDate,
            enddate: formattedEndDate,
          },
        ],
      };
    }

    case 'clearCocRequestForm': {
      return initialState;
    }

    default:
      return state;
  }
};

export default credentialRequestFormReducer;
