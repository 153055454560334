/**
 * Formats the uploaded file size.
 *
 * @param {number} fileSizeInBytes - Size of the file in bytes.
 * @returns {string} - Formatted file size in megabytes (MB).
 *
 * @example
 * Returns 'Calculating...' if fileSizeInBytes is null or undefined.
 * formatUploadFileSize(null);
 *
 * Returns the formatted size in MB format.
 * formatUploadFileSize(1024);
 *
 * @remarks
 * This function is used to format the file size regardless of whether
 * the useUploadFile custom hook is used.
 */

const formatUploadFileSize = (fileSizeInBytes) => {
  const FILESIZE_DECIMAL_PLACES = 2;
  const BYTES_PER_MB = 1024 * 1024;

  if (fileSizeInBytes === null || fileSizeInBytes === undefined) {
    return 'Calculating...';
  }

  const fileSizeInMB = fileSizeInBytes / BYTES_PER_MB;

  return `${fileSizeInMB.toFixed(FILESIZE_DECIMAL_PLACES)}MB`;
};

// eslint-disable-next-line import/prefer-default-export
export { formatUploadFileSize };
