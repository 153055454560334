export const handleRequestor = (data, success) => ({
  type: 'cocRequestRequestor',
  payload: data,
  successMessage: success,
});

export const handleAccountId = (data, success) => ({
  type: 'cocRequestAccountId',
  payload: data,
  successMessage: success,
});

export const handleJustification = (data, success) => ({
  type: 'cocRequestBusinessJustification',
  payload: data,
  successMessage: success,
});

export const handleDateRange = (startDate, endDate, success) => ({
  type: 'cocRequestDateRange',
  payload: { start: startDate, end: endDate },
  successMessage: success,
});

export const handleClearCOCForm = (success) => ({
  type: 'clearCocRequestForm',
  successMessage: success,
});
