import { Global404, NotFound, Route404 } from '@saviynt/common';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import LocalizedWrapper from '../components/LandingPage/LocalizedWrapper';
import CheckOutCredential from './CheckOutCredential/CheckOutCredential';
import ConnectToSession from './ConnectToSession/ConnectToSession';
import LandingPage from './LandingPage/LandingPage';

const queryClient = new QueryClient();
const isPamLocalhostPort3001 =
  window.location.hostname === 'localhost' && window.location.port === '3001';

function Routes() {
  const allowedRoutes = {
    staticRoutes: [],
    dynamicRoutes: [],
  };

  const pamGen2Flag = window.localStorage.getItem('pamGen2');

  if (pamGen2Flag === '0') {
    allowedRoutes.staticRoutes = [];
  } else if (pamGen2Flag === '1') {
    allowedRoutes.staticRoutes = [
      '/pam',
      '/pam/check-out-credential',
      '/pam/connect-to-session',
    ];
  }

  return (
    <div>
      <LocalizedWrapper isPamLocalhostPort3001={isPamLocalhostPort3001}>
        <QueryClientProvider client={queryClient}>
          <Suspense key='PAM' fallback={() => {}}>
            <Router basename='/ECMv6'>
              <Route404
                routes={allowedRoutes}
                render={(path) => <Global404 path={path} />}>
                <Switch>
                  <Route exact path='/pam' component={LandingPage} />
                  <Route
                    exact
                    path='/pam/check-out-credential'
                    component={CheckOutCredential}
                  />
                  <Route
                    exact
                    path='/pam/connect-to-session'
                    component={ConnectToSession}
                  />
                  <Route component={NotFound} />
                </Switch>
              </Route404>
            </Router>
          </Suspense>
        </QueryClientProvider>
      </LocalizedWrapper>
    </div>
  );
}

export default Routes;
