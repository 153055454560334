import './SpfEndpoint.css';

import { Box, Button, Icon } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ModuleDetails } from '../../../../models/PamModels';
import { getAccountListApi } from '../../../../utilities/api/getDataFromApi';

const BUTTON_LABELS = {
  CHECKOUT_CREDENTIALS: 'Check Out Credentials',
  CONNECT_TO_SESSION: 'Connect to Session',
};

const PAM_TYPES = {
  CREDENTIAL: 'CREDENTIAL',
  CREDENTIALLESS: 'CREDENTIALLESS',
};

function SpfEndpoint({ data, accountOnClick, dataTestId, className }) {
  const classes = classnames('SpfEndpoint', className);
  const [credentialedArray, setCredentialedArray] = useState([]);
  const [credentiallessArray, setCredentiallessArray] = useState([]);

  const isCredentialed =
    credentialedArray.length > 0 && credentiallessArray.length === 0;

  const isCredentialless =
    credentialedArray.length === 0 && credentiallessArray.length > 0;

  const isBoth = credentialedArray.length > 0 && credentiallessArray.length > 0;

  const isNone =
    credentialedArray.length === 0 && credentiallessArray.length === 0;

  const parseAccountsForPamType = () => {
    const credArray = [];
    const credlessArray = [];

    getAccountListApi(data.endpointKey)
      .then((response) => {
        if (response.result.accounts.length > 0) {
          response.result.accounts.forEach((account) => {
            if (account.pamType?.toUpperCase() === PAM_TYPES.CREDENTIAL) {
              credArray.push(account);
            } else if (
              account.pamType?.toUpperCase() === PAM_TYPES.CREDENTIALLESS
            ) {
              credlessArray.push(account);
            }
          });
        }

        setCredentialedArray(credArray);
        setCredentiallessArray(credlessArray);
      })
      .catch((error) => {
        console.error(
          'An error occurred while fetching the account list:',
          error
        );
      });
  };

  useEffect(() => {
    parseAccountsForPamType();
  }, [data]);

  const renderBothButtons = () => (
    <Box className='SpfEndpoint-twoButtons'>
      <Button
        type='button'
        kind='outlined'
        className='SpfEndpoint-twoButtons--buttonStyle'
        onClick={() => accountOnClick()}
        leftIcon={(<Icon
          kind='PasswordCredentialUnlock'
          size='smallMedium'
          color='neutral-100'
        />)}>
        {BUTTON_LABELS.CHECKOUT_CREDENTIALS}
      </Button>
      <Button
        type='button'
        kind='outlined'
        className='SpfEndpoint-twoButtons--buttonStyle'
        leftIcon={<Icon kind='ConnectPlug' size='smallMedium' color='neutral-100' />}>
        {BUTTON_LABELS.CONNECT_TO_SESSION}
      </Button>
    </Box>
  );

  return (
    <Box className={classes} dataTestId={dataTestId}>
      {isNone && null}
      {isBoth && renderBothButtons()}
      {(isCredentialed || isCredentialless) && (
        <Button
          type='button'
          kind='outlined'
          leftIcon={(<Icon
            kind={isCredentialed ? 'PasswordCredentialUnlock' : 'ConnectPlug'}
            size='smallMedium'
            color='neutral-100'
          />)}
          onClick={() => (isCredentialed ? accountOnClick() : {})}>
          {isCredentialed
            ? BUTTON_LABELS.CHECKOUT_CREDENTIALS
            : BUTTON_LABELS.CONNECT_TO_SESSION}
        </Button>
      )}
    </Box>
  );
}

SpfEndpoint.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  accountOnClick: PropTypes.func,
  className: PropTypes.string,
};

SpfEndpoint.defaultProps = {
  data: null,
  dataTestId: null,
  accountOnClick: null,
  className: null,
};

export default SpfEndpoint;
