import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CheckboxBase from './CheckboxBase/CheckboxBase';
import CheckboxCard from './CheckboxCard/CheckboxCard';
import CheckboxWithText from './CheckboxWithText/CheckboxWithText';

const KINDS = {
  base: 'base',
  text: 'text',
  card: 'card',
};
const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};
/**
 * Icon props should have these colors:
 * - neutral-600 (disabled)
 * - navy-900 (all other states)
 */

function Checkbox(props) {
  const {
    kind,
    name,
    size,
    label,
    title,
    icon,
    isDisabled,
    isIndeterminate,
    textPosition,
    isChecked,
    onChange,
    onClick,
    defaultChecked,
    showHoverEffect,
    tabIndex,
    dataTestId,
    error,
  } = props;

  // local state for uncontrolled checkboxes.
  const [localChecked, setLocalChecked] = useState(defaultChecked);
  const isControlled = isChecked !== undefined;
  const effectiveChecked = isControlled ? isChecked : localChecked;

  const handleChange = (e) => {
    if (isControlled && onChange) {
      onChange(e);
    } else if (!isControlled) {
      setLocalChecked(!localChecked);
    }

    onClick(e);
  };

  switch (kind) {
    case KINDS.text:
      return (
        <CheckboxWithText
          name={name}
          size={size}
          label={label}
          title={title}
          icon={icon}
          isChecked={effectiveChecked}
          isDisabled={isDisabled}
          isIndeterminate={isIndeterminate}
          textPosition={textPosition}
          handleChange={handleChange}
          showHoverEffect={showHoverEffect}
          dataTestId={dataTestId}
          error={error}
        />
      );
    case KINDS.card:
      return (
        <CheckboxCard
          name={name}
          label={label}
          size={size}
          title={title}
          icon={icon}
          isDisabled={isDisabled}
          isChecked={effectiveChecked}
          isIndeterminate={isIndeterminate}
          handleChange={handleChange}
          showHoverEffect={showHoverEffect}
          dataTestId={dataTestId}
          error={error}
        />
      );

    case KINDS.base:
      return (
        <CheckboxBase
          name={name}
          size={size}
          isChecked={effectiveChecked}
          isDisabled={isDisabled}
          isIndeterminate={isIndeterminate}
          handleChange={handleChange}
          showHoverEffect={showHoverEffect}
          tabIndex={isDisabled ? '-1' : tabIndex}
          dataTestId={dataTestId}
          error={error}
        />
      );
    default:
      return null;
  }
}

Checkbox.propTypes = {
  name: PropTypes.string,
  kind: PropTypes.oneOf([KINDS.base, KINDS.text, KINDS.card]),
  size: PropTypes.oneOf([SIZES.small, SIZES.medium, SIZES.large]),
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  defaultChecked: PropTypes.bool,
  title: PropTypes.string,
  label: PropTypes.string,
  textPosition: PropTypes.oneOf(['before', 'after']),
  icon: PropTypes.element,
  showHoverEffect: PropTypes.bool,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.string,
  dataTestId: PropTypes.string,
};

Checkbox.defaultProps = {
  name: null,
  kind: KINDS.text,
  textPosition: 'before',
  size: SIZES.medium,
  isChecked: undefined,
  isDisabled: false,
  isIndeterminate: false,
  onChange: () => {},
  onClick: () => {},
  defaultChecked: false,
  title: null,
  label: null,
  icon: null,
  showHoverEffect: false,
  tabIndex: 0,
  error: null,
  dataTestId: null,
};

export default Checkbox;
