import { getCurrentDate } from '@saviynt/common';
import {
  addDays,
  differenceInSeconds,
  format,
  isBefore,
  parseISO,
} from 'date-fns';
import { useEffect, useState } from 'react';

import { handleDateRange } from '../../action/CredentialRequestFormAction';
import { getSessionsAccountSchedule } from '../../utilities/api/getDataFromApi';

const MS_PER_MINUTE = 60000;
const SECONDS_PER_MINUTE = 60;
const SECONDS_IN_FIVE_MINUTES = 60 * 5;
const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * 60;
const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24;
const SECONDS_PER_YEAR = SECONDS_PER_DAY * 365;

// TODO: update this with NOW duration leap year criteria information
const OFFSET_SECONDS_PER_YEAR = 31104000;
const AVG_DAYS_PER_MONTH = 30;

const CheckOutCredentialDurationService = (
  accountSelection,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isNowTab,
  setDurationAccordionValue,
  setStartDateOfNextCredential,
  dispatch
) => {
  const [checkedAccountSessions, setCheckedAccountSessions] = useState([]);
  const [range, setRange] = useState(null);
  const [checkScheduleEveryMinute, setCheckScheduleEveryMinute] =
    useState(false);

  const parseAccordionValueNowTime = (durInSecs) => {
    if (durInSecs === null) return '';

    const roundedDurInSecs = durInSecs + SECONDS_PER_MINUTE / 2;

    const days = Math.floor(roundedDurInSecs / SECONDS_PER_DAY);
    const hoursAfterDays = Math.floor(
      (roundedDurInSecs % SECONDS_PER_DAY) / SECONDS_PER_HOUR
    );
    const remainingMinutes = Math.floor(
      (roundedDurInSecs % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE
    );

    const dayText = days === 1 ? 'day' : 'days';
    const hourText = hoursAfterDays === 1 ? 'hour' : 'hours';
    const minuteText = remainingMinutes === 1 ? 'min' : 'mins';

    if (days > 0 && hoursAfterDays > 0) {
      return `Next ${days} ${dayText} ${hoursAfterDays} ${hourText}`;
    }

    if (days > 0) {
      return `Next ${days} ${dayText}`;
    }

    if (hoursAfterDays > 0 && remainingMinutes === 0) {
      return `Next ${hoursAfterDays} ${hourText}`;
    }

    if (hoursAfterDays === 0 && remainingMinutes > 0) {
      return `Next ${remainingMinutes} ${minuteText}`;
    }

    if (hoursAfterDays > 0 && remainingMinutes > 0) {
      return `Next ${hoursAfterDays} ${hourText} ${remainingMinutes} ${minuteText}`;
    }

    return 'Next < 1 min';
  };

  const parseAccordionValueFutureTime = (durInSecs) => {
    const months = Math.floor(
      durInSecs / (AVG_DAYS_PER_MONTH * SECONDS_PER_DAY)
    );
    const daysAfterMonths = Math.floor(
      (durInSecs % (AVG_DAYS_PER_MONTH * SECONDS_PER_DAY)) / SECONDS_PER_DAY
    );
    const hours = Math.floor((durInSecs % SECONDS_PER_DAY) / SECONDS_PER_HOUR);
    const minutes = Math.floor(
      (durInSecs % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE
    );

    let durationStr = '';

    if (months > 0) {
      durationStr += `${months} ${months === 1 ? 'month' : 'months'} `;
    }

    if (daysAfterMonths > 0) {
      durationStr += `${daysAfterMonths} ${
        daysAfterMonths === 1 ? 'day' : 'days'
      } `;
    }

    if (hours > 0) {
      durationStr += `${hours} ${hours === 1 ? 'hour' : 'hours'} `;
    }

    if (minutes > 0) {
      durationStr += `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
    }

    durationStr = durationStr.trim();

    const startFormatted = format(startDate, 'MM/dd/yyyy, h:mm a');
    const endFormatted = format(endDate, 'MM/dd/yyyy, h:mm a');

    return `From ${startFormatted} to ${endFormatted} (${durationStr})`;
  };

  useEffect(() => {
    let interval;

    if (isNowTab) {
      interval = setInterval(() => {
        const newStartDate = getCurrentDate();

        setStartDate(newStartDate);
        setCheckScheduleEveryMinute(true);

        // If range is set, calculate new end date based on the range
        if (range) {
          const newEndDate = new Date(newStartDate.getTime() + range * 1000);

          setEndDate(newEndDate);
        }
      }, MS_PER_MINUTE);
    } else {
      interval = setInterval(() => {
        setCheckScheduleEveryMinute(true);
      }, MS_PER_MINUTE);
    }

    return () => clearInterval(interval);
  }, [isNowTab, range]);

  useEffect(() => {
    if (endDate && !range) {
      const newStartDate = getCurrentDate();

      setRange(differenceInSeconds(endDate, newStartDate));
    }
  }, [endDate]);

  useEffect(() => {
    // Avoid dispatch if dates are not set
    if (!startDate || !endDate) return;
    const clipRange = differenceInSeconds(endDate, startDate);

    // Avoid dispatch if dates are to short
    if (clipRange < SECONDS_IN_FIVE_MINUTES) return;

    const clipRangeNow =
      clipRange > SECONDS_PER_YEAR ? SECONDS_PER_YEAR : clipRange;
    const clipRangeFuture =
      clipRange > OFFSET_SECONDS_PER_YEAR ? OFFSET_SECONDS_PER_YEAR : clipRange;

    if (isNowTab) {
      setDurationAccordionValue(parseAccordionValueNowTime(clipRangeNow));
    } else {
      setDurationAccordionValue(parseAccordionValueFutureTime(clipRangeFuture));
    }

    const formattedStartDate = format(
      startDate,
      "EEE, dd LLL yyyy HH:mm:ss 'GMT'",
      { timeZone: 'GMT' }
    );
    const formattedEndDate = format(
      endDate,
      "EEE, dd LLL yyyy HH:mm:ss 'GMT'",
      { timeZone: 'GMT' }
    );

    dispatch(
      handleDateRange(
        formattedStartDate,
        formattedEndDate,
        'Date Range success'
      )
    );
  }, [startDate, endDate]);

  // Get schedule. Should only be needed once per account switch as it is based solely on the day.
  useEffect(() => {
    if (accountSelection?.value) {
      const validDayStart = getCurrentDate();
      const formatDate = (date) => format(date, 'yyyy-MM-dd');

      const formattedStartDate = formatDate(validDayStart);
      const formattedEndDate = formatDate(addDays(validDayStart, 365));

      getSessionsAccountSchedule(
        accountSelection.value,
        formattedStartDate,
        formattedEndDate
      )
        .then((response) => {
          if (Array.isArray(response.result.accountSessions)) {
            const extractedDates = response.result.accountSessions.map(
              (session) => ({
                firstname: session.firstname,
                lastname: session.lastname,
                username: session.username,
                startDate: session.startDate,
                endDate: session.endDate,
                requestId: session.requestId,
                requestAccessStatus: session.requestAccessStatus,
              })
            );

            setCheckedAccountSessions(extractedDates);
          } else {
            console.error('Account sessions is not an array:', response);
          }
        })
        .catch((error) => {
          console.error('Error fetching sessions:', error);
        });
    }
  }, [accountSelection]);

  useEffect(() => {
    if (checkedAccountSessions?.length > 0) {
      const now = getCurrentDate();

      const filterExpiredCredentials = checkedAccountSessions.filter(
        (session) => {
          const sessionEndDate = parseISO(session.endDate);

          // Keep the session only if the end date is in the future
          return isBefore(now, sessionEndDate);
        }
      );

      const earliestSession =
        filterExpiredCredentials.length > 0
          ? filterExpiredCredentials.reduce((earliest, currentSession) => {
              const currentStartDate = parseISO(currentSession.startDate);

              if (
                !earliest ||
                isBefore(currentStartDate, parseISO(earliest.startDate))
              ) {
                return currentSession;
              }

              return earliest;
            }, null)
          : null;

      if (earliestSession) {
        setStartDateOfNextCredential(earliestSession.startDate);
      } else {
        setStartDateOfNextCredential(null);
      }
    }

    if (checkScheduleEveryMinute) {
      setCheckScheduleEveryMinute(false);
    }
  }, [checkedAccountSessions, checkScheduleEveryMinute]);

  // TODO: combine useEffect with TAD.js useEffect?
  // Reset Dates Of Next Credential on account switch
  useEffect(() => {
    setStartDateOfNextCredential(null);
  }, [accountSelection]);

  return { checkedAccountSessions };
};

export default CheckOutCredentialDurationService;
