import React from 'react';
import Chip from '@material-ui/core/Chip';
import ClearIcon from '@material-ui/icons/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { getResults, getSelectedServices, updateQuery } from '../../../actions/logsAction';
import styles from './Chips.module.css';
import { serviceNamesMapping } from '../../../../constant';
import { defaultServices } from '../../../reducers/logsReducer';

const Chips = ({ isDisplayed }) => {
  const dispatch = useDispatch();
  const checkedServices = useSelector((state) => state.admin?.logs.selectedServices);
  const query = useSelector((state) => state.admin?.logs.query);
  const { AUTHMS_ENABLED, PAM_SERVICES_ENABLED } = useSelector((state) => state.user.domainInfo);
  const getServiceName = (value) => serviceNamesMapping(AUTHMS_ENABLED, PAM_SERVICES_ENABLED).find((f) => f.value === value)?.name || value;
  const isChipsShown = defaultServices(AUTHMS_ENABLED, PAM_SERVICES_ENABLED).length !== checkedServices.length;

  const handleDelete = (chipToDelete) => () => {
    let updatedServices = [...checkedServices.filter((a) => a !== chipToDelete)];

    if (updatedServices.length === 0) {
      updatedServices = defaultServices(AUTHMS_ENABLED, PAM_SERVICES_ENABLED);
    }

    dispatch(getSelectedServices(updatedServices));
    dispatch(updateQuery(Object.assign(query, {
      servicename: updatedServices,
    })));
    dispatch(getResults(query));
  };

  if (isDisplayed && isChipsShown && checkedServices?.length > 0) {
    return (
      <div className={styles.container}>
        {checkedServices.map((data) => (
          <Chip
            data-testid={`chip-${data}`}
            className={styles.chip}
            key={data}
            label={getServiceName(data)}
            deleteIcon={<ClearIcon />}
            color="primary"
            variant="outlined"
            onDelete={data === 'React' ? undefined : handleDelete(data)}
          />
        ))}
      </div>
    );
  }

  return null;
};

export default Chips;
