/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-curly-spacing */
import './LandingPage.css';

import { localize, useDetectScroll } from '@saviynt/common';
import {
  AlertBanner,
  Badge,
  ButtonSelect,
  Checkbox,
  Header,
  Icon,
  InlineMessage,
  InputField,
  Link,
  MenuMulti,
} from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  listAPIListReceived,
  selectedListDetails,
} from '../../action/PrivilegedAccessAction';
import { clearRequestSubmittedId } from '../../action/requestSubmittedDetailsAction';
import CheckInModal from '../../components/CheckInModal/CheckInModal';
import ListRowWrapper from '../../components/LandingPage/ListRowWrapper';
import LandingPageSidePanel from '../../components/LandingPageSidePanel/LandingPageSidePanel';
// TODO: remove mock data after merging and QA testing
import { mockData } from '../../models/MockData/MockData';
import LandingPageFilterService from '../../services/LandingPage/LandingPageFilterService';
import LandingPagePaginationService from '../../services/LandingPage/LandingPagePaginationService';
import {
  getListRowApi,
  getListRowApiFilterAndSearch,
} from '../../utilities/api/getDataFromApi';
import debounce from '../../utilities/debounce';
import displayAlertBannerForRequestSubmit from '../../utilities/displayAlertBannerForRequestSubmit';

function LandingPage({ intl }) {
  const [currentListItem, setCurrentListItem] = useState(null);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [listRowData, setListRowData] = useState({});
  const [filterPillVisibility, setFilterPillVisibility] = useState(true);
  // Filter / Search
  const [searchBarValue, setSearchBarValue] = useState('');
  const [filteredEntities, setFilteredEntities] = useState(null);
  // Check In Modal
  const [isCheckInModalOpen, setIsCheckInModalOpen] = useState(false);
  const [iconClickedForRow, setIconClickedForRow] = useState(null);
  // Hide Session Checkbox
  const [isHideSessionsChecked, setisHideSessionsChecked] = useState(false);
  const [alertBannerData, setAlertBannerData] = useState(null);

  const requestId = useSelector(
    (state) => state.requestSubmittedDetails.requestId
  );

  const [cookies] = useCookies(['user_name']);
  const userName = cookies.user_name;

  const handleChange = (event) => {
    setisHideSessionsChecked(event.target.checked);
  };

  // References
  const landingPageContentRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  // TODO: once beta version ready, remove this constant as well.
  const ENABLE_FOR_BETA = false;
  // Filter Dependencies //
  const {
    platform,
    asset,
    isFilterChecked,
    clearAllFilters,
    checkedPlatforms,
    checkedAssets,
  } = LandingPageFilterService(setIsSidePanelOpen, setCurrentListItem);

  // Pagination Dependencies //
  const { pageIndex, paginatedEntities } =
    LandingPagePaginationService(filteredEntities);

  const contentClasses = classnames(
    'LandingPage-content',
    isSidePanelOpen && 'LandingPage-content--withSidePanel'
  );

  const titleContainerClasses = classnames(
    'LandingPage-titleContainer',
    isSidePanelOpen && 'LandingPage-titleContainer--withSidePanel'
  );

  const listRowContainerClasses = classnames(
    'LandingPage-listRows',
    isSidePanelOpen && 'LandingPage-listRows--withSidePanel'
  );

  const rowIsSelected = (listItem) => currentListItem === listItem;

  const listRowOnClick = (row) => {
    setIsSidePanelOpen(true);
    setCurrentListItem(row);
  };

  useEffect(() => {
    if (requestId) {
      displayAlertBannerForRequestSubmit(
        requestId,
        userName,
        setAlertBannerData
      );
      dispatch(clearRequestSubmittedId('success'));
    }
  }, []);

  useEffect(() => {
    // TODO: Ask the team, should we use this for all the state handling for this page now that we have it stored in redux? So we don't have repeated selected listRow states. I need it in Redux for the COC and session headers and account endpointKey.
    dispatch(selectedListDetails(currentListItem, 'success'));
  }, [currentListItem]);

  const sidePanelOnClose = () => {
    setIsSidePanelOpen(false);
    setCurrentListItem(null);
  };

  useEffect(() => {
    // Close the sidepanel when page is changed or search made
    setIsSidePanelOpen(false);
    setCurrentListItem(null);
  }, [pageIndex, searchBarValue]);

  const { isScrolled: landingPageContentIsScrolled } = useDetectScroll(
    null,
    landingPageContentRef
  );

  useEffect(() => {
    if (listRowData && Object.keys(listRowData).length === 0) return;

    getListRowApiFilterAndSearch(
      checkedPlatforms,
      checkedAssets,
      searchBarValue
    ).then((response) => {
      setListRowData(response);
    });
  }, [checkedPlatforms, checkedAssets]);

  const delayedSearch = useRef(
    debounce((platforms, assets, query) => {
      if (query.trim().length > 0) {
        getListRowApiFilterAndSearch(platforms, assets, query).then(
          (response) => {
            setListRowData(response);
          }
        );
      }
    }, 300)
  ).current;

  useEffect(() => {
    // TODO: remove mock data after merging and QA testing
    const shouldUseMockData =
      window.location.href === 'http://localhost:3001/' ||
      window.location.href === 'http://localhost:3001/pam' ||
      window.location.href === 'http://localhost:3001/ECMv6/pam';

    if (shouldUseMockData) {
      console.log('Using mock data on localhost');
      setListRowData(mockData);
    }

    document.body.style.overflow = 'hidden';
    delayedSearch.cancel();

    return () => {
      document.body.style.overflow = 'auto';
      delayedSearch.cancel();
    };
  }, []);

  useEffect(() => {
    if (listRowData && Object.keys(listRowData).length !== 0) {
      setFilteredEntities(listRowData);
    }

    // To get data stored in redux store on getting response from API
    if (listRowData && Object.keys(listRowData).length > 0) {
      dispatch(listAPIListReceived(listRowData, 'success'));
    }
  }, [listRowData]);

  // Navigation functionality
  const checkoutCredentialNavigation = () => {
    history.push('/pam/check-out-credential');
  };

  // Search functionality
  useEffect(() => {
    if (searchBarValue.trim().length > 0) {
      delayedSearch(checkedPlatforms, checkedAssets, searchBarValue);
    } else if (checkedPlatforms.length > 0 || checkedAssets.length > 0) {
      getListRowApiFilterAndSearch(
        checkedPlatforms,
        checkedAssets,
        searchBarValue
      ).then((response) => {
        setListRowData(response);
      });
    } else {
      delayedSearch.cancel();
      getListRowApi().then((response) => {
        setListRowData(response);
      });
    }
  }, [searchBarValue]);

  const renderListOrNoData = () => {
    if (!paginatedEntities) {
      return null;
    }

    if (paginatedEntities.length === 0) {
      return (
        <div className='LandingPage-noData'>
          <Icon kind='alertCriticalOutline' />
          <span className='LandingPage-noData-title'>
            Your search doesn&apos;t match any records
          </span>
        </div>
      );
    }

    return (
      <div>
        {paginatedEntities.filter((item) => item.type === 'session').length >
          0 &&
          !isHideSessionsChecked && (
            <section>
              <div className='LandingPage-listrow-section-title'>
                <div className='LandingPage-listrow-section-title-startLine' />
                <div className='LandingPage-listrow-section-title-label'>
                  Sessions
                </div>
                <div className='LandingPage-listrow-section-title-endLine' />
              </div>

              <div className='LandingPage-listrow-section'>
                {paginatedEntities
                  .filter((item) => item.type === 'session')
                  .map((listItemObj, i) => (
                    <ListRowWrapper
                      key={listItemObj.requestAccessKey}
                      index={i}
                      kind='session'
                      listItemObj={listItemObj}
                      isCheckInModalOpen={isCheckInModalOpen}
                      setIsCheckInModalOpen={setIsCheckInModalOpen}
                      setIconClickedForRow={setIconClickedForRow}
                      rowIsSelected={rowIsSelected}
                      listRowOnClick={listRowOnClick}
                      ENABLE_FOR_BETA={ENABLE_FOR_BETA}
                      listRowData={listRowData}
                      setListRowData={setListRowData}
                    />
                  ))}
              </div>
            </section>
          )}

        {paginatedEntities.filter((item) => item.type === 'endpoint').length >
          0 && (
          <section>
            <div className='LandingPage-listrow-section-title'>
              <div className='LandingPage-listrow-section-title-startLine' />
              <div className='LandingPage-listrow-section-title-label'>
                Available Assets
              </div>
              <div className='LandingPage-listrow-section-title-endLine' />
            </div>

            <div className='LandingPage-listrow-section'>
              {paginatedEntities
                .filter((item) => item.type === 'endpoint')
                .map((listItemObj, i) => (
                  <ListRowWrapper
                    key={listItemObj.endpointKey}
                    index={i}
                    kind='endpoint'
                    listItemObj={listItemObj}
                    isCheckInModalOpen={isCheckInModalOpen}
                    setIsCheckInModalOpen={setIsCheckInModalOpen}
                    setIconClickedForRow={setIconClickedForRow}
                    rowIsSelected={rowIsSelected}
                    listRowOnClick={listRowOnClick}
                    ENABLE_FOR_BETA={ENABLE_FOR_BETA}
                    listRowData={listRowData}
                    setListRowData={setListRowData}
                  />
                ))}
            </div>
          </section>
        )}
      </div>
    );
  };

  return (
    <div className='LandingPage' data-testid='LandingPage'>
      <Header
        helpOnClick={() => {}}
        notificationsOnClick={() => {}}
        avatarOnClick={() => {}}
        user={{
          name: 'Test User',
          url: 'https://www.vhv.rs/dpng/d/15-155087_dummy-image-of-user-hd-png-download.png',
        }}
        isScrolled={landingPageContentIsScrolled}
        className='LandingPage-header'
      />
      <div className='LandingPage-layout'>
        <section ref={landingPageContentRef} className={contentClasses}>
          <section className={titleContainerClasses}>
            <div className='LandingPage-title'>
              {localize(intl, 'sidemenu.Group.PRIVILEGEDACCESS')}
            </div>
            <div className='LandingPage-description'>
              Connect to your privileged assets so you can launch live sessions
              and check credentials out when you need them.
            </div>
          </section>
          <section className='LandingPage-barPillsAndPage'>
            <div className='LandingPage-barAndPage'>
              <div className='LandingPage-searchBarContainer'>
                <div className='LandingPage-searchBar'>
                  <InputField
                    name='basic'
                    kind='search'
                    placeholder='Search by target system, application, account name, IP, etc.'
                    prefixIcon={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Icon
                        kind='search'
                        color='neutral-600'
                        size='smallMedium'
                      />
                    }
                    value={searchBarValue}
                    setValue={setSearchBarValue}
                  />
                </div>
                <div className='LandingPage-filterButton'>
                  <ButtonSelect
                    label='Filter'
                    isOpen={filterPillVisibility}
                    size='large'
                    prefixIcon={<Icon kind='Filter' color='neutral-600' />}
                    onClick={() =>
                      setFilterPillVisibility(!filterPillVisibility)
                    }
                    BadgeComp={
                      isFilterChecked ? (
                        <Badge kind='dot' color='primary' size='small' />
                      ) : null
                    }
                  />
                </div>
              </div>
            </div>
            {filterPillVisibility && (
              <section
                className={`LandingPage-filterPills ${
                  !filterPillVisibility && 'LandingPage-filterPills--isClosed'
                }`}>
                <MenuMulti
                  options={platform.options}
                  label='Platform'
                  trigger={<ButtonSelect kind='pill' label='Platform' />}
                  onChange={platform.setSelected}
                />
                <MenuMulti
                  options={asset.options}
                  label='Asset Type'
                  trigger={<ButtonSelect kind='pill' label='Asset Type' />}
                  onChange={asset.setSelected}
                />
                {isFilterChecked ? (
                  <Link
                    href='Filter Clear Button'
                    text='Clear'
                    kind='button'
                    onClick={() => {
                      clearAllFilters();
                    }}
                  />
                ) : null}
                {paginatedEntities?.filter((item) => item?.type === 'session')
                  .length > 0 && (
                  <div className='LandingPage-hideSessions'>
                    <Checkbox
                      name='hideSessions'
                      kind='base'
                      isChecked={isHideSessionsChecked}
                      onChange={handleChange}
                      dataTestId='hideSessions'
                      size='small'
                    />
                    <span className='LandingPage-hideSessions-label'>
                      Hide Sessions
                    </span>
                  </div>
                )}
              </section>
            )}
          </section>
          <section className={listRowContainerClasses}>
            {renderListOrNoData()}
          </section>
        </section>
      </div>
      {isSidePanelOpen && (
        <aside className='LandingPage-sidePanelContainer'>
          <LandingPageSidePanel
            data={currentListItem}
            isOpen={isSidePanelOpen}
            onClose={sidePanelOnClose}
            accountOnClick={() => {
              checkoutCredentialNavigation();
            }}
            setIsCheckInModalOpen={setIsCheckInModalOpen}
            setIconClickedForRow={setIconClickedForRow}
            className='LandingPage-sidePanel'
          />
        </aside>
      )}

      <CheckInModal
        setIsModalOpen={setIsCheckInModalOpen}
        isModalOpen={isCheckInModalOpen}
        row={iconClickedForRow}
        setListRowData={setListRowData}
      />
      {alertBannerData && (
        <AlertBanner
          colorTheme={alertBannerData.colorTheme}
          title={alertBannerData.title}
          description={alertBannerData.description}
          isVisible
          onCancel={() => setAlertBannerData(null)}
          isColonVisible={false}
          isFloating
          position={{
            type: 'fixed',
            top: '4.25rem',
            left: '3rem',
            right: '3rem',
          }}
        />
      )}
    </div>
  );
}

LandingPage.defaultProps = { intl: { locale: 'en' } };

LandingPage.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string,
    formatMessage: PropTypes.func.isRequired,
  }),
};

export default injectIntl(LandingPage);
