import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../../Icon/Icon';
import CheckboxCore from '../CheckboxCore/CheckboxCore';
import { SIZES } from '../constants';

import './CheckboxWithText.css';

function CheckboxWithText({
  name,
  size,
  label,
  title,
  icon,
  isDisabled,
  isIndeterminate,
  textPosition,
  isChecked,
  handleChange,
  defaultChecked,
  showHoverEffect,
  dataTestId,
  error,
  tabIndex,
}) {
  const classes = classnames(
    'Checkbox',
    'Checkbox--withText',
    isDisabled && 'Checkbox--disabled',
    icon && 'Checkbox--withIcon',
    isChecked && ' Checkbox--checked',
    !showHoverEffect && 'Checkbox--hoverEffectDisabled',
    error && 'Checkbox--error'
  );

  const renderedError = error && (
    <div className='Checkbox-errorWrapper'>
      <Icon kind='alertCritical' color='critical-700' size='xxxSmall' />
      {error}
    </div>
  );

  const labelTextClass = `Checkbox-labelText Checkbox-labelText--${textPosition}`;
  const titleTextClass = `Checkbox-titleText Checkbox-titleText--${textPosition}`;
  const renderedLabelAndTitle = (title || label) && (
    <div className='Checkbox-iconTextWrapper'>
      {icon && <div className='Checkbox-iconWrapper'>{icon}</div>}
      {title ? (
        <div className={titleTextClass}>{title}</div>
      ) : (
        label && <div className={labelTextClass}>{label}</div>
      )}
    </div>
  );

  return (
    <div className='Checkbox-container' data-testid={dataTestId}>
      <div className={classes}>
        <label className='Checkbox-label'>
          {textPosition === 'before' && renderedLabelAndTitle}
          <CheckboxCore
            name={name}
            size={size}
            isChecked={isChecked}
            isDisabled={isDisabled}
            isIndeterminate={isIndeterminate}
            handleChange={handleChange}
            dataTestId={dataTestId}
            tabIndex={isDisabled ? -1 : tabIndex}
          />
          {textPosition === 'after' && renderedLabelAndTitle}
        </label>
        <span className='Checkbox-overlay' />
      </div>
      {renderedError}
    </div>
  );
}

CheckboxWithText.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf([SIZES.small, SIZES.medium, SIZES.large]),
  title: PropTypes.string,
  label: PropTypes.string,
  textPosition: PropTypes.oneOf(['before', 'after']),
  icon: PropTypes.element,
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  handleChange: PropTypes.func,
  showHoverEffect: PropTypes.bool,
  error: PropTypes.string,
  dataTestId: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CheckboxWithText.defaultProps = {
  name: null,
  size: SIZES.medium,
  title: null,
  label: null,
  textPosition: 'before',
  icon: null,
  isDisabled: false,
  isChecked: undefined,
  isIndeterminate: false,
  showHoverEffect: false,
  handleChange: null,
  error: null,
  dataTestId: null,
  tabIndex: 0,
};

export default CheckboxWithText;
