/* eslint-disable react/jsx-wrap-multilines */
import {
  Box,
  DateAndTimePicker,
  Icon,
  InlineMessage,
  InputField,
  MenuInput,
  Typography,
} from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  SECONDS_PER_DAY,
  SECONDS_PER_HOUR,
  SECONDS_PER_MINUTE,
} from '../../../../../utilities/timeConstants';

const TIMES = {
  interval: 60,
  min: { minutes: 0, hours: 0 },
  max: { minutes: 0, hours: 23 },
};

const LABELS = {
  END_DATE: 'End Date',
  END_TIME: 'End Time',
};

function CustomDuration({
  isNowTab,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isDurationCustom,
  rangeMaxDate,
  rangeMinDate,
  setDurationChipValue,
  customDurationInputValue,
  setCustomDurationInputValue,
  intervalOptions,
  setIntervalOptions,
  isCustomUnderFiveMin,
  userMaxTime,
}) {
  const userMaxTimeAsNumber = parseInt(userMaxTime, 10);
  const customInputValueAsNumber = parseFloat(customDurationInputValue, 10);

  const [intervalMaxTime, setIntervalMaxTime] = useState(
    userMaxTimeAsNumber / SECONDS_PER_MINUTE
  );
  const roundedIntervalMaxTime = Math.floor(intervalMaxTime * 100) / 100;

  const isCustomAtMaxTime = customInputValueAsNumber === roundedIntervalMaxTime;

  const isFuturePredefinedChipWithDates =
    !isNowTab && !isDurationCustom && startDate && endDate;

  const getCheckedInterval = (options) => {
    const checkedOption = options.find((option) => option.isChecked);

    return checkedOption ? checkedOption.value : null;
  };

  useEffect(() => {
    if (isDurationCustom) {
      const checkedOption = getCheckedInterval(intervalOptions);

      if (checkedOption === 'minutes') {
        setDurationChipValue(customInputValueAsNumber * SECONDS_PER_MINUTE);
      } else if (checkedOption === 'hours') {
        setDurationChipValue(customInputValueAsNumber * SECONDS_PER_HOUR);
      } else {
        setDurationChipValue(customInputValueAsNumber * SECONDS_PER_DAY);
      }
    }
  }, [customDurationInputValue, intervalOptions]);

  useEffect(() => {
    if (isDurationCustom) {
      setCustomDurationInputValue('');
      const checkedOption = getCheckedInterval(intervalOptions);

      if (checkedOption === 'minutes') {
        setIntervalMaxTime(userMaxTimeAsNumber / SECONDS_PER_MINUTE);
      } else if (checkedOption === 'hours') {
        setIntervalMaxTime(userMaxTimeAsNumber / SECONDS_PER_HOUR);
      } else {
        setIntervalMaxTime(userMaxTimeAsNumber / SECONDS_PER_DAY);
      }
    }
  }, [intervalOptions]);

  const handleIntervalChange = (selectedOption) => {
    setIntervalOptions((currentOptions) =>
      currentOptions.map((option) => ({
        ...option,
        isChecked: option.value === selectedOption.value,
      }))
    );
  };

  const handleCustomInputChange = (event) => {
    const input = event.target.value;

    if (input === '') {
      setCustomDurationInputValue('');

      return;
    }

    const regex = /^\d*(\.\d{0,2})?$/;

    if (regex.test(input)) {
      const numericInput = input === '' ? '' : parseFloat(input);

      if (Number.isNaN(numericInput)) {
        return;
      }

      const clippedValue =
        numericInput > roundedIntervalMaxTime
          ? roundedIntervalMaxTime
          : numericInput;

      setCustomDurationInputValue(clippedValue);
    }
  };

  const parseInlineMaxTime = () => {
    const days = Math.floor(userMaxTimeAsNumber / SECONDS_PER_DAY);
    const hours = Math.floor(
      (userMaxTimeAsNumber % SECONDS_PER_DAY) / SECONDS_PER_HOUR
    );
    const minutes = Math.floor(
      (userMaxTimeAsNumber % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE
    );

    const dayText = days === 1 ? 'day' : 'days';
    const hourText = hours === 1 ? 'hour' : 'hours';
    const minuteText = minutes === 1 ? 'minute' : 'minutes';

    let timeString = '';

    if (days > 0) timeString += `${days} ${dayText} `;
    if (hours > 0) timeString += `${hours} ${hourText} `;
    if (minutes > 0) timeString += `${minutes} ${minuteText}`;

    return timeString.trim();
  };

  if (isNowTab && isDurationCustom)
    return (
      <section className='TimeAccessDuration-customDuration'>
        <Box className='TimeAccessDuration-inputFields'>
          <Box className='TimeAccessDuration-enterNumber-input'>
            <InputField
              type='text'
              inputmode='numeric'
              name='Interval Number'
              placeholder='Enter number'
              label={
                <Typography kind='label' htmlFor='Custom duration'>
                  Custom duration
                </Typography>
              }
              backgroundColor='secondary'
              value={customDurationInputValue}
              onChange={handleCustomInputChange}
              isCritical={
                (customDurationInputValue && isCustomUnderFiveMin) ||
                isCustomAtMaxTime
              }
              CriticalHelperText={
                <InlineMessage
                  text={`Duration must be a minimum of 5 minutes and a maximum of ${parseInlineMaxTime()}.`}
                  colorTheme='critical'
                  size='small'
                  leftIcon={<Icon kind='AlertCritical' color='critical-700' />}
                />
              }
            />
          </Box>
          <Box className='TimeAccessDuration-enterNumber-input TimeAccessDuration-MenuInput'>
            <MenuInput
              options={intervalOptions}
              onChange={handleIntervalChange}
              backgroundColor='secondary'
              isCritical={
                (customDurationInputValue && isCustomUnderFiveMin) ||
                isCustomAtMaxTime
              }
              // CriticalHelperText text is left blank to help align the MenuInput with InputField when the isCustomUnderFiveMin is true
              CriticalHelperText={
                <InlineMessage
                  leftIcon={<div />}
                  text=''
                  colorTheme='critical'
                  size='small'
                />
              }
              isReadOnly
            />
          </Box>
        </Box>
      </section>
    );

  if (isFuturePredefinedChipWithDates)
    return (
      <section className='TimeAccessDuration-customDuration'>
        <div className='TimeAccessDuration-inputFields'>
          <div className='TimeAccessDuration-enterNumber-input'>
            <DateAndTimePicker
              kind='dateEnd'
              label={LABELS.END_DATE}
              isReadOnly={!isDurationCustom}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              maxDate={rangeMaxDate}
              minDate={rangeMinDate}
            />
          </div>
          <div className='TimeAccessDuration-enterNumber-input'>
            <DateAndTimePicker
              kind='timeEnd'
              label={LABELS.END_TIME}
              isReadOnly={!isDurationCustom}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              maxDate={rangeMaxDate}
              minDate={rangeMinDate}
              timeIntervals={TIMES.interval}
              dateFormat='h:mm aa'
              timeHeaderText=''
              timeMin={TIMES.min}
              timeMax={TIMES.max}
            />
          </div>
        </div>
      </section>
    );

  return null;
}

CustomDuration.propTypes = {
  isNowTab: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(Date),
  setEndDate: PropTypes.func.isRequired,
  isDurationCustom: PropTypes.bool.isRequired,
  rangeMaxDate: PropTypes.instanceOf(Date).isRequired,
  rangeMinDate: PropTypes.instanceOf(Date).isRequired,
  setDurationChipValue: PropTypes.func.isRequired,
  customDurationInputValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  setCustomDurationInputValue: PropTypes.func.isRequired,
  intervalOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      isChecked: PropTypes.bool,
    })
  ).isRequired,
  setIntervalOptions: PropTypes.func.isRequired,
  isCustomUnderFiveMin: PropTypes.bool.isRequired,
  userMaxTime: PropTypes.string,
};

CustomDuration.defaultProps = {
  startDate: null,
  endDate: null,
  customDurationInputValue: '',
  userMaxTime: null,
};

export default CustomDuration;
