import { getRequestDetails } from "./api/getDataFromApi";

const STATUS_PENDING = 1;
const STATUS_APPROVED = 3;

// Todo: Work on localisation once DB MR is merged

function displayAlertBannerForRequestSubmit(requestId, userName, setAlertBannerData) {
  getRequestDetails(userName, requestId).then((response) => {
    if (response?.status === STATUS_PENDING) {
      setAlertBannerData({
        colorTheme: 'Success',
        title: 'Success!',
        description: `Your request for ${response?.endpoints[0]?.accountName} is Pending Approval.`,
      });
    } else if (response?.status === STATUS_APPROVED) {
      setAlertBannerData({
        colorTheme: 'Success',
        title: 'Success!',
        description: `Your request for ${response?.endpoints[0]?.accountName} has been approved and will be available soon. Refresh your list in a few minutes.`,
      });
    }
  });
}

export default displayAlertBannerForRequestSubmit;