import { useEffect } from 'react';

import {
  handleAccountId,
  handleRequestor,
} from '../../action/CredentialRequestFormAction';
import { MockAccountListSingleEndpointKeyFour } from '../../models/MockData/MockAccountList';
import { getAccountListApi } from '../../utilities/api/getDataFromApi';

const CheckOutCredentialAccountService = (
  accountSelection,
  accountListArray,
  setAccountListArray,
  setIsAccountSelection,
  dispatch,
  userName,
  serviceEndpointKey
) => {
  useEffect(() => {
    if (!accountSelection) return;

    dispatch(handleAccountId(accountSelection?.value, 'Account Id success'));
  }, [accountSelection]);

  // Open Account accordion when list is ready
  // TODO: 5323 bring back after
  useEffect(() => {
    if (accountListArray?.length !== 0) setIsAccountSelection(true);
  }, [accountListArray]);

  // Getting relavent info from accounts to send to Radio buttons
  const extractAccountDetails = (accountList) => {
    if (!accountList.result || !accountList.result.accounts) return;

    const { accounts } = accountList.result;
    const extractedDetails = accounts.map((account) => ({
      value: account.accountKey,
      title: account.name,
      description: account.pamAccountType,
      maxtime: account.accountConfig.maxrequestabletimeinsecs,
    }));

    setAccountListArray(extractedDetails);
  };

  useEffect(() => {
    // Update the redux requestor on mount
    if (userName) dispatch(handleRequestor(userName, 'Requestor success'));
  }, [userName]);

  useEffect(() => {
    // Update the accountListArray on mount
    if (serviceEndpointKey) {
      getAccountListApi(serviceEndpointKey, 'CREDENTIAL')
        .then((response) => {
          extractAccountDetails(response);
        })
        .catch((error) => {
          console.error(
            'An error occurred while fetching the account list:',
            error
          );
        });
    }
  }, [serviceEndpointKey]);

  return {};
};

export default CheckOutCredentialAccountService;
