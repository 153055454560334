import { DateAndTimePicker } from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React from 'react';

const TIMES = {
  interval: 15,
  min: { minutes: 0, hours: 0 },
  max: { minutes: 0, hours: 23 },
};

const LABELS = {
  startDate: 'Start Date',
  endDate: 'End Date',
  startTime: 'Start Time',
  endTime: 'End Time',
};

function ByTypeDateAndTimePicker({
  type,
  isNowTab,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isDurationCustom,
  rangeMaxDate,
  rangeMinDate,
}) {
  if (isNowTab) return null;

  if (type === 'start') {
    return (
      <div className='TimeAccessDuration-dateInputs'>
        {/* Start Date */}
        <DateAndTimePicker
          kind='dateStart'
          label={LABELS.startDate}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={isDurationCustom ? endDate : null}
          maxDate={rangeMaxDate}
          minDate={rangeMinDate}
        />
        {/* Start Time */}
        <DateAndTimePicker
          kind='timeStart'
          label={LABELS.startTime}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={isDurationCustom ? endDate : null}
          maxDate={rangeMaxDate}
          minDate={rangeMinDate}
          timeIntervals={TIMES.interval}
          dateFormat='h:mm aa'
          timeHeaderText=''
          timeMin={TIMES.min}
          timeMax={TIMES.max}
        />
      </div>
    );
  }

  if (type === 'end' && isDurationCustom) {
    return (
      <div className='TimeAccessDuration-dateInputs'>
        {/* End Date */}
        <DateAndTimePicker
          kind='dateEnd'
          label={LABELS.endDate}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          maxDate={rangeMaxDate}
          minDate={rangeMinDate}
        />
        {/* Start Time */}
        <DateAndTimePicker
          kind='timeEnd'
          label={LABELS.endTime}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          maxDate={rangeMaxDate}
          minDate={rangeMinDate}
          timeIntervals={TIMES.interval}
          dateFormat='h:mm aa'
          timeHeaderText=''
          timeMin={TIMES.min}
          timeMax={TIMES.max}
        />
      </div>
    );
  }

  return null;
}

ByTypeDateAndTimePicker.propTypes = {
  type: PropTypes.string,
  isNowTab: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func,
  endDate: PropTypes.instanceOf(Date),
  setEndDate: PropTypes.func,
  isDurationCustom: PropTypes.bool,
  rangeMaxDate: PropTypes.instanceOf(Date),
  rangeMinDate: PropTypes.instanceOf(Date),
};

ByTypeDateAndTimePicker.defaultProps = {
  type: null,
  isNowTab: false,
  startDate: null,
  setStartDate: () => {},
  endDate: null,
  setEndDate: () => {},
  isDurationCustom: false,
  rangeMaxDate: null,
  rangeMinDate: null,
};

export default ByTypeDateAndTimePicker;
