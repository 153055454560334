import React from 'react';
import MenuList from '../MenuList/MenuList';
import findAssociatedMenuForNavigatedUrl from '../../utils/findAssociatedMenuForNavigatedUrl';
import SessionStorageService from 'ui-common/src/utils/SessionStorageService';

const ChildMenu = ({
  data, selected, handleBackClick, topLevelMenuOpen, expandSideMenu, collapseSideMenu, selectedMenuId,
  isSearchActive, handleDrawerClose,
}) => {
  if (!topLevelMenuOpen) {
    return (
      <MenuList
        data={data}
        handleBackClick={handleBackClick}
        isParentOpen={topLevelMenuOpen}
        expandSideMenu={expandSideMenu}
        selected={selected}
        selectedMenuId={selectedMenuId}
        collapseSideMenu={collapseSideMenu}
        isSearchActive={isSearchActive}
        handleDrawerClose={handleDrawerClose}
      />
    );
  }
  let menu = findAssociatedMenuForNavigatedUrl(data, selected);
  if (!menu) {
    menu = JSON.parse(localStorage.getItem('currentMenu'));
    SessionStorageService.set('currentMenu', localStorage.getItem('currentMenu'));
  }


  let filteredData;
  const stillBrowsingThruMenu = () => selectedMenuId !== '';
  const filterForSelectedMenu = () => data.filter((f) => f.id === selectedMenuId);
  const isNavigatedUrlAssociatedWithMenu = () => menu !== 'null' && menu !== null;
  const filterForAllParentMenus = () => data.filter((f) => menu?.parentID?.includes(f.id));

  if (isSearchActive) {
    filteredData = data;
  } else if (stillBrowsingThruMenu()) {
    filteredData = filterForSelectedMenu();
  } else if (isNavigatedUrlAssociatedWithMenu()) {
    filteredData = filterForAllParentMenus();
  } else {
    handleBackClick(selectedMenuId);
    filteredData = data;
  }

  return (
    <MenuList
      data={filteredData}
      selected={selected}
      handleBackClick={handleBackClick}
      isParentOpen={topLevelMenuOpen}
      expandSideMenu={expandSideMenu}
      collapseSideMenu={collapseSideMenu}
      selectedMenuId={selectedMenuId}
      isSearchActive={isSearchActive}
      handleDrawerClose={handleDrawerClose}
    />
  );
};

export default ChildMenu;
