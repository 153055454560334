import { Cookies } from 'react-cookie';

class HttpService {
  constructor() {
    this.cookies = new Cookies();
    this.headers = {
      'Content-Type': 'application/json',
      Authorization: `${this.cookies.get('token_type')} ${this.cookies.get('access_token')}`,
    };
  }

  get(url) {
    return fetch(url, {
      method: 'GET',
      headers: this.headers,
    }).then((res) => res.json());
  }

  post(url, req, errorCallback) {
    return fetch(url, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(req),
    }).then((res) => {
      if (res.status === 200) {
        return res.json();
      }

      errorCallback(res);

      return res;
    });
  }

  put(url, req) {
    return fetch(url, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify(req),
    }).then((res) => res.json());
  }

  delete() {
    //    TODO: Enhance this when needed!
  }
}

export default new HttpService();
