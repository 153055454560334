import './SidePanelView.css';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';

import { ModuleDetails } from '../../../models/PamModels';
import {
  getAccountDetailsApi,
  getEndpointDetailsApi,
  getSessionDetailsApi,
} from '../../../utilities/api/getDataFromApi';
import SpvAccounts from './SpvAccounts/SpvAccounts';
import SpvSession from './SpvSession/SpvSession';
import SpvEndpoint from "./SpvEndpoint/SpvEndpoint";

const TYPES = {
  ACCOUNT: 'account',
  SESSION: 'session',
  ENDPOINT: 'endpoint',
};

function SidePanelView({ data, dataTestId, className }) {
  const classes = classnames('SidePanelView', className);

  const [platformDetails, setPlatformDetails] = useState(data);
  const [session, setSession] = useState(data);
  const [sessionDetails, setSessionDetails] = useState(null);

  const cookies = new Cookies();

  const callApiByType = () => {
    switch (data.type) {
      case TYPES.ACCOUNT:
        getAccountDetailsApi(data.accountKey).then((response) => {
          setPlatformDetails(response);
        });
        break;
      case TYPES.SESSION:
        getAccountDetailsApi(data.accountKey).then((response) => {
          setPlatformDetails(response);
        });

        if (data.pamType?.toUpperCase() === 'CREDENTIAL') {
          setSession(data);
          getSessionDetailsApi(
            data.requestAccessKey,
            cookies.get('userKey')
          ).then((response) => {
            setSessionDetails(response);
          });
        } else {
          setSession(data);
          setSessionDetails(null);
        }

        break;
      case TYPES.ENDPOINT:
        getEndpointDetailsApi(data.endpointKey).then((response) => {
          setPlatformDetails(response);
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // only make 1 API call at a time
    callApiByType();
  }, [data]);

  const renderSidePanelView = () => {
    switch (data.type) {
      case TYPES.ACCOUNT:
        return <SpvAccounts data={platformDetails} />;
      case TYPES.SESSION:
        return (
          <SpvSession
            data={platformDetails}
            session={session}
            sessionDetails={sessionDetails}
          />
        );
      case TYPES.ENDPOINT:
        return <SpvEndpoint data={platformDetails} />;
      default:
        return null;
    }
  };

  return (
    <aside className={classes} data-testid={dataTestId}>
      {renderSidePanelView()}
    </aside>
  );
}

SidePanelView.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SidePanelView.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SidePanelView;
