import './SpfSession.css';

import { Box, Button, Icon, Typography } from '@saviynt/design-system';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ModuleDetails } from '../../../../models/PamModels';
import CountdownTimer from '../../../CountdownTimer/CountdownTimer';

const BUTTON_LABELS = {
  CHECK_IN: 'Check In',
  EXTEND: 'Extend',
  // TODO: Remove after getting requirements for Credentialless
  CHECKOUT_CREDENTIALS: 'Check Out Credentials',
  CONNECT_TO_SESSION: 'Connect to Session',
};

function SpfSession({
  data,
  accountOnClick,
  dataTestId,
  setIsCheckInModalOpen,
  setIconClickedForRow,
  className,
}) {
  const classes = classnames('SpfSession', className);
  const isCredentialed = data.pamType?.toUpperCase() === 'CREDENTIAL';

  const renderCredentialedFooter = () => (
    <Box className='SpfSession-credentialedFooter'>
      <Box className='SpfSession-countDownTimerContainer'>
        <Typography kind='body1-bold' className='SpfSession-countDownTimer'>
          <CountdownTimer
            targetDate={data?.sessionEndDate}
            leftText=''
            item={data}
          />
        </Typography>
        <Typography kind='body1' className='SpfSession-countDownTimer-leftText'>
          left
        </Typography>
      </Box>
      <Box className='SpfSession-credentialedButtons'>
        <Button
          type='button'
          kind='outlined'
          className='SpfSession-twoButtons--buttonStyle'
          isEnabled
          onClick={() => {
            setIsCheckInModalOpen(true);
            setIconClickedForRow(data);
          }}>
          {BUTTON_LABELS.CHECK_IN}
        </Button>
        <Button
          type='button'
          kind='outlined'
          isCritical
          className='SpfSession-twoButtons--buttonStyle'>
          {BUTTON_LABELS.EXTEND}
        </Button>
      </Box>
    </Box>
  );

  // TODO: Remove after getting requirements for Credentialless
  const renderBothButtons = () => (
    <Box className='SpfAccounts-twoButtons'>
      <Button
        type='button'
        kind='outlined'
        className='SpfAccounts-twoButtons--buttonStyle'
        onClick={() => accountOnClick()}
        leftIcon={(<Icon
          kind='PasswordCredentialUnlock'
          size='smallMedium'
          color='neutral-100'
        />)}>
        {BUTTON_LABELS.CHECKOUT_CREDENTIALS}
      </Button>
      <Button
        type='button'
        kind='outlined'
        className='SpfAccounts-twoButtons--buttonStyle'
        leftIcon={<Icon kind='ConnectPlug' size='smallMedium' color='neutral-100' />}>
        {BUTTON_LABELS.CONNECT_TO_SESSION}
      </Button>
    </Box>
  );

  return (
    <Box className={classes} dataTestId={dataTestId}>
      {isCredentialed ? renderCredentialedFooter() : renderBothButtons()}
    </Box>
  );
}

SpfSession.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  accountOnClick: PropTypes.func,
  className: PropTypes.string,
  setIsCheckInModalOpen: PropTypes.func,
  setIconClickedForRow: PropTypes.func,
};

SpfSession.defaultProps = {
  data: null,
  dataTestId: null,
  accountOnClick: null,
  className: null,
  setIsCheckInModalOpen: null,
  setIconClickedForRow: null,
};

export default SpfSession;
