import { takeLatest, call, put, select, takeEvery } from 'redux-saga/effects';
import { checkEnvironment } from 'ui-common/src/lib/utils';
import { generateQueryParam } from 'ui-common/src/utils/apiUtils';
import { showNotificationModal } from 'ui-common/src/utils/Loading-actions';
import { isEqual } from 'lodash';
import { Cookies } from 'react-cookie';
import {
  FEATURE_LIST,
  featureListSuccess,
  UPDATE_FEATURE,
  UPDATE_THEME_CONFIG_LIST,
  updateThemeConfigListSuccess,
  updateThemeConfigListFailure,
  DOWNLOAD_ATTACHEMENT,
  UPLOAD_AS_FILE,
  REQUEST_MANAGE_LOGS_LIST,
  requestManageLogsListSuccess,
  requestManageLogsListFailure,
  GET_ALL_THEMES_SUCCESS,
  GET_ALL_THEMES_FAILURE,
  CREATE_THEME,
  CREATE_THEME_SUCCESS,
  CREATE_THEME_FAILURE,
  ACTIVATE_THEME,
  ACTIVATE_THEME_SUCCESS,
  ACTIVATE_THEME_FALIURE,
  UPLOAD_LOGO,
  GET_ALL_THEMES,
  GET_ALL_LOGOS,
  GET_ALL_LOGOS_FAILURE,
  GET_ALL_LOGOS_SUCCESS,
  UPDATE_THEME,
  UPDATE_THEME_SUCCESS,
  UPDATE_THEME_FAILURE,
  GET_LOGO,
  GET_LOGO_TO_SHOW,
  GET_LOGO_FAILURE,
  GET_LOGO_SUCCESS,
  GET_LOGO_TO_SHOW_FAILURE,
  GET_LOGO_TO_SHOW_SUCCESS,
  DELETE_THEME,
  DELETE_THEME_SUCCESS,
  DELETE_THEME_FAILURE,
  DELETE_LOGO,
  DELETE_LOGO_SUCCESS,
  DELETE_LOGO_FAILURE,
  GET_FAVICON,
  GET_FAVICON_SUCCESS,
  GET_FAVICON_FAILURE,
  UPLOAD_LOGO_SUCCESS,
  UPLOAD_LOGO_FAILURE,
  UPLOAD_FAVICON,
  UPLOAD_FAVICON_SUCCESS,
  UPLOAD_FAVICON_FAILURE,
  DOWNLOAD_LOGIN_LOGOUT_LOGO,
  GET_ACTIVE_LOGIN_LOGOUT_SUCCESS,
} from './adminHome-actions';
import {
  GET_MESSAGE_LABELS,
  SAVE_MESSAGE_PROP,
  getMessageLabelsSuccess,
  saveMessagePropSuccess,
} from '../views/LabelChangeManagement/actions';

import { getIntl } from '../../../misc/IntlUtil';
import { defaultTheme } from '../views/UIBrandConfiguration/ConfigurationTypes/defaultTheme';
import { getHostUrl } from '../views/BrandingConfig/utils/helper';

const cookies = new Cookies();

// gen2 branding saga

function* getFeatureList() {
  try {
    const { GATEWAY_API_URL } = checkEnvironment();
    let url = null;
    if (window.DEMO_MODE) {
      url = `http://${window.location.hostname}:3100/api/usermssecurityfeatures`;
    } else {
      url = `${GATEWAY_API_URL}/api/userms/security/features`;
    }
    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = yield call([response, response.json]);
      yield put(featureListSuccess(json));
    }
  } catch (err) {
    console.log(err);
  }
}

function* updateFeature(action) {
  const { GATEWAY_API_URL } = checkEnvironment();
  let url = null;
  if (window.DEMO_MODE) {
    url = `http://${window.location.hostname}:3100/api/usermssecurityfeatures/${action.payload.id}`;
  } else {
    url = `${GATEWAY_API_URL}/api/userms/security/features/${action.payload.id}`;
  }
  const response = yield call(fetch, url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(action.payload),
  });
  if (response.status === 200) {
    yield call([response, response.json]);
    yield put({ type: FEATURE_LIST });
  }
}

function* updateThemeConfig(action) {
  const SERVICE_ENDPOINT_IDWMS = checkEnvironment().IDWMS_API_URL;
  const modifiedThemeItems = {};
  Object.keys(action.configThemeObj).reduce((diff, key) => {
    if (!isEqual(defaultTheme[key], action.configThemeObj[key])) {
      modifiedThemeItems[key] = action.configThemeObj[key];
    }
    return true;
  }, {});
  try {
    const defaultBody = {
      fileName: 'theme.json',
      folderPath: '/app/usr',
      resourceBody: JSON.stringify(modifiedThemeItems),
    };
    const url = `${SERVICE_ENDPOINT_IDWMS}/res/content`;
    const response = yield call(fetch, url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(defaultBody),
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      if (json) {
        yield put(updateThemeConfigListSuccess(json));
        action.handleSuccess();
      }
    }
  } catch {
    yield put(updateThemeConfigListFailure());
  }
}

function* downloadAttachment(action) {
  let response = '';
  try {
    const url = action.urlType;
    if (action.method === 'POST') {
      response = yield call(fetch, url, {
        method: action.method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.data),
      });
    } else {
      response = yield call(fetch, url, {
        method: action.method,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    if (response.status === 200) {
      response.arrayBuffer().then((buffer) => {
        const responseUrl = window.URL.createObjectURL(new Blob([buffer]));

        action.handleDownloadResponse(responseUrl, action.fileName);
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function* downloadLoginLogoutLogo(action) {
  let response = '';
  try {
    const url = action.urlType;
    if (action.method === 'POST') {
      response = yield call(fetch, url, {
        method: action.method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(action.data),
      });
    } else {
      response = yield call(fetch, url, {
        method: action.method,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    if (response.status === 200) {
      response.arrayBuffer().then((buffer) => {
        const responseUrl = window.URL.createObjectURL(new Blob([buffer]));
        action.handleDownloadLoginLogoutLogoResponse(responseUrl, action.fileName);
      });
      yield put({
        type: GET_ACTIVE_LOGIN_LOGOUT_SUCCESS,
        payload: {
          success: true,
          loginLogoutLogoName: action?.fileName,
          fileData: action?.data,
        },
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function* uploadAsFile(action) {
  try {
    const formData = new FormData();
    formData.append('file', action.fileData);
    formData.append('path', action.path);

    const url = action.urlType;
    const response = yield call(fetch, url, {
      method: 'POST',
      headers: {
        Authorization: `${localStorage.getItem('token_type')} ${localStorage.getItem(
          'access_token'
        )}`,
      },
      body: formData,
    });
    if (response.status === 200) {
      action.handleUploadResponse(action.fileData.name, action.fileData);
    } else {
      action.handleFailureResponse(response);
    }
  } catch (err) {
    action.handleFailureResponse(err);
    console.log(err);
  }
}

function* uploadLogo(action) {
  try {
    const formData = new FormData();
    formData.append('file', action.fileData);
    formData.append('path', action.path);

    const url = action.urlType;
    const response = yield call(fetch, url, {
      method: 'POST',
      headers: {
        Authorization: `${localStorage.getItem('token_type')} ${localStorage.getItem(
          'access_token'
        )}`,
      },
      body: formData,
    });
    const json = yield call([response, response?.json]);
    if (!json?.error) {
      action.handleUploadResponse(action.fileData.name, action.fileData);
      yield put({
        type: UPLOAD_LOGO_SUCCESS,
        payload: { success: true, logoName: action.fileData.name, fileData: action.fileData },
      });
    } else {
      action.handleFailureResponse(json);
      yield put({
        type: UPLOAD_LOGO_FAILURE,
        payload: json,
      });
    }
  } catch (err) {
    action.handleFailureResponse(err);
  }
}

function* uploadFavIcon(action) {
  try {
    const formData = new FormData();
    formData.append('file', action.fileData);
    formData.append('path', action.path);

    const url = action.urlType;
    const response = yield call(fetch, url, {
      method: 'POST',
      headers: {
        Authorization: `${localStorage.getItem('token_type')} ${localStorage.getItem(
          'access_token'
        )}`,
      },
      body: formData,
    });
    const json = yield call([response, response?.json]);
    if (!json?.error) {
      action.handleUploadResponse(action.fileData.name, action.fileData);
      yield put({
        type: UPLOAD_FAVICON_SUCCESS,
        payload: { success: true, favIconName: action.fileData.name, fileData: action.fileData },
      });
    } else {
      action.handleFailureResponse(json);
      yield put({
        type: UPLOAD_FAVICON_FAILURE,
        payload: json,
      });
    }
  } catch (err) {
    action.handleFailureResponse(err);
  }
}

function* getMessageLabels(action) {
  const { payload } = action;
  const SERVICE_ENDPOINT_IDWMS = checkEnvironment().IDWMS_API_URL;
  try {
    // let url = `http://localhost:5000/messageProperties`;
    const url = `${SERVICE_ENDPOINT_IDWMS}/messageProperties`;
    let queryParam = {};
    if (payload && payload.reqBodyOptions) {
      queryParam = { ...payload.reqBodyOptions };
    }
    const response = yield call(fetch, `${url}${generateQueryParam(queryParam)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const json = yield call([response, response.json]);
      if (json) {
        yield put(getMessageLabelsSuccess(json));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

const editedLabels = (state) => state.adminHome.messageProps.editedLabels;
const selectedLanguages = (state) => state.adminHome.messageProps.selectedLanguages;

const getLabelSaveSuccessMsg = (intl) => ({
  type: 'SUCCESS',
  title: intl.formatMessage({ id: 'MANAGE.LABEL.SAVE.SUCCESS.TITLE' }),
  details: intl.formatMessage({ id: 'MANAGE.LABEL.SAVE.SUCCESS.DETAILS' }),
});

const getLabelSaveFailureMsg = (intl) => ({
  type: 'FAILURE',
  title: intl.formatMessage({ id: 'MANAGE.LABEL.SAVE.FAILURE.TITLE' }),
  details: intl.formatMessage({ id: 'MANAGE.LABEL.SAVE.FAILURE.DETAILS' }),
});

function* updateMessageLabels() {
  const modifiedLabels = yield select(editedLabels);
  const selectedLanguage = yield select(selectedLanguages);
  const modifiedBody = [];
  modifiedLabels.map((item) => {
    selectedLanguage.map((language) => {
      item.messageValues.map((value) => {
        if (language === value.language) {
          modifiedBody[language] = {
            ...modifiedBody[language],
            [item.messageKey]: value.content,
          };
        }
        return true;
      });
      return true;
    });
    return true;
  });
  const payload = { ...modifiedBody };

  const SERVICE_ENDPOINT_IDWMS = checkEnvironment().IDWMS_API_URL;
  try {
    // let url = `http://localhost:5000/messageProperties`;
    const url = `${SERVICE_ENDPOINT_IDWMS}/messageProperties`;
    const response = yield call(fetch, url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ i18ndata: payload }),
    });
    if (response && response.status === 204) {
      yield put(saveMessagePropSuccess());
      yield put(showNotificationModal(getLabelSaveSuccessMsg(getIntl())));
    } else {
      yield put(showNotificationModal(getLabelSaveFailureMsg(getIntl())));
    }
  } catch (err) {
    yield put(showNotificationModal(getLabelSaveFailureMsg(getIntl())));
  }
}
function* requestManageLogs(action) {
  const { GATEWAY_API_URL } = checkEnvironment();
  try {
    const url = `${GATEWAY_API_URL}/api/logms/logsearch`;
    const response = yield call(fetch, url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.data),
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      if (json) {
        if (json.status === 'OK') {
          yield put(requestManageLogsListSuccess(json.logs));
        } else {
          yield put(requestManageLogsListFailure());
        }
      }
    } else {
      yield put(requestManageLogsListFailure());
    }
  } catch {
    yield put(requestManageLogsListFailure());
  }
}

function* getAllThemesSaga(action) {
  try {
    const { ECM_URL } = checkEnvironment();
    const url = `${getHostUrl()}/themes?type=navigation`;
    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
      },
    });
    if (response && response.status === 200) {
      const jsonString = yield call([response, response.text]);
      let res = JSON.parse(jsonString);

      // Convert styles field to JavaScript object
      if (res.length > 0) {
        res = res.map((theme) => ({
          ...theme,
          styles: JSON.parse(theme.styles),
        }));
      }
      yield put({ type: GET_ALL_THEMES_SUCCESS, response: res });
    } else {
      yield put({ type: GET_ALL_THEMES_FAILURE });
    }
  } catch (error) {
    yield put({ type: GET_ALL_THEMES_FAILURE });
  }
}

function* getAllLogosSaga(action) {
  try {
    const { ECM_URL } = checkEnvironment();
    const url = `${getHostUrl()}/logos?type=navigation`;
    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
      },
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      yield put({ type: GET_ALL_LOGOS_SUCCESS, payload: json }); // todo need to check payload
    } else {
      yield put({ type: GET_ALL_LOGOS_FAILURE, payload: [] });
    }
  } catch (error) {
    yield put({ type: GET_ALL_LOGOS_FAILURE, payload: [] });
  }
}

function* createThemeSaga(action) {
  try {
    const { ECM_URL } = checkEnvironment();
    const url = `${getHostUrl()}/themes`;
    const response = yield call(fetch, url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
      },
      body: JSON.stringify(action.payload),
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      if (!json?.error) {
        const resStyles = JSON.parse(json?.styles);

        // Convert styles field to JavaScript object
        if (json?.styles) {
          json.styles = resStyles;
        }
        yield put({ type: CREATE_THEME_SUCCESS, response: json });
      } else {
        yield put({ type: CREATE_THEME_FAILURE, error: json });
      }
    } else {
      const jsonString = yield call([response, response.text]);
      yield put({ type: CREATE_THEME_FAILURE, error: jsonString });
    }
  } catch (error) {
    yield put({ type: CREATE_THEME_FAILURE, error });
  }
}

function* activateThemeSaga(action) {
  const { name, isOOTB, appearance } = action.payload;
  try {
    const { ECM_URL } = checkEnvironment();
    const appearanceParam = isOOTB ? `appearance=${appearance}` : '';
    const url = `${getHostUrl()}/themes/${name}/activate?${appearanceParam}&type=navigation`;
    const response = yield call(fetch, url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
      },
      body: JSON.stringify(action.payload),
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      if (!json?.error) {
        const resStyles = JSON.parse(json?.styles);

        // Convert styles field to JavaScript object
        if (json?.styles) {
          json.styles = resStyles;
        }
        yield put({ type: ACTIVATE_THEME_SUCCESS, response: json });
      } else {
        yield put({ type: ACTIVATE_THEME_FALIURE, error: json });
      }
    } else {
      const jsonString = yield call([response, response.text]);
      yield put({ type: ACTIVATE_THEME_FALIURE, error: jsonString });
    }
  } catch (error) {
    yield put({ type: ACTIVATE_THEME_FALIURE });
  }
}

function* updateThemeSaga(action) {
  const { isThemeActive } = action;
  try {
    const { ECM_URL } = checkEnvironment();
    const url = `${getHostUrl()}/themes`;
    const response = yield call(fetch, url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
      },
      body: JSON.stringify(action.payload),
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      if (!json?.error) {
        const resStyles = JSON.parse(json?.styles);

        // Convert styles field to JavaScript object
        if (json?.styles) {
          json.styles = resStyles;
        }
        yield put({ type: UPDATE_THEME_SUCCESS, response: json, isThemeActive });
      } else {
        yield put({ type: UPDATE_THEME_FAILURE, error: json });
      }
    } else {
      const jsonString = yield call([response, response.text]);
      yield put({ type: UPDATE_THEME_FAILURE, error: jsonString });
    }
  } catch (error) {
    yield put({ type: UPDATE_THEME_FAILURE, error });
  }
}

function* getLogoSaga(action) {
  try {
    const { logo, logotype } = action.payload;
    const { ECM_URL } = checkEnvironment();
    const url = `${getHostUrl()}/logos/${logo}?type=navigation`;
    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
      },
    });
    if (response && response.status === 200) {
      try {
        const json = yield call([response, response.blob]);
        if (json && json?.type !== 'text/json') {
          yield put({ type: GET_LOGO_SUCCESS, response: { json, logotype } });
        } else {
          yield put({ type: GET_LOGO_FAILURE, error: json });
        }
      } catch (e) {
        yield put({ type: GET_LOGO_FAILURE, error: e });
      }
    } else {
      yield put({ type: GET_LOGO_FAILURE, error: { error: 'error' } });
    }
  } catch (error) {
    yield put({ type: GET_LOGO_FAILURE, error });
  }
}

function* getLogoToShowSaga(action) {
  try {
    const { name } = action.payload;
    const { ECM_URL } = checkEnvironment();
    const url = `${getHostUrl()}/logos/${name}?type=navigation`;
    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
      },
    });
    if (response && response.status === 200) {
      try {
        const json = yield call([response, response.blob]);
        if (json && json?.type !== 'text/json') {
          yield put({ type: GET_LOGO_TO_SHOW_SUCCESS, response: { json, name } });
        } else {
          yield put({ type: GET_LOGO_TO_SHOW_FAILURE, error: json });
        }
      } catch (e) {
        yield put({ type: GET_LOGO_TO_SHOW_FAILURE, error: e });
      }
    } else {
      yield put({ type: GET_LOGO_TO_SHOW_FAILURE, error: { error: 'error' } });
    }
  } catch (error) {
    yield put({ type: GET_LOGO_TO_SHOW_FAILURE, error });
  }
}

function* getFavIcon() {
  try {
    const url = `${getHostUrl()}/navigation/favicon`;
    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
      },
    });
    if (response && response.status === 200) {
      const fileName = response.headers.get('Content-Disposition');
      yield put({ type: GET_FAVICON_SUCCESS, payload: { fileName: fileName?.split('=')[1] } });
    }
  } catch (error) {
    yield put({ type: GET_FAVICON_FAILURE, error });
  }
}

function* deleteThemeSaga(action) {
  const { isThemeActive, name } = action.payload;
  try {
    const { ECM_URL } = checkEnvironment();
    const url = `${getHostUrl()}/themes/${name}?type=navigation`;
    const response = yield call(fetch, url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
      },
    });
    const contentLength = response.headers.get('Content-Length');
    const isEmptyResponse = contentLength !== null && parseInt(contentLength, 10) === 0;
    if (response.status === 200 && isEmptyResponse) {
      yield put({ type: DELETE_THEME_SUCCESS, response: { name, isThemeActive } });
    } else if (response.status === 200) {
      const json = yield call([response, response.json]);
      yield put({ type: DELETE_THEME_FAILURE, error: json });
    }
  } catch (error) {
    yield put({ type: DELETE_THEME_FAILURE, error });
  }
}

function* deleteLogo(action) {
  const { logoKey } = action;
  try {
    const url = `${getHostUrl()}/logos/${logoKey}?type=navigation`;
    const response = yield call(fetch, url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
        Authorization: `${cookies.get('token_type')} ${cookies.get('access_token')}`,
      },
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      if (!json?.error) {
        yield put({
          type: DELETE_LOGO_SUCCESS,
          response: { message: json?.message, name: logoKey },
        });
      } else {
        yield put({ type: DELETE_LOGO_FAILURE, error: json });
      }
    } else {
      yield put({ type: DELETE_LOGO_FAILURE, error: 'Error while deleting logo.' });
    }
  } catch (error) {
    yield put({ type: DELETE_LOGO_FAILURE, error });
  }
}

export default function* adminHomeSaga() {
  yield takeLatest(FEATURE_LIST, getFeatureList);
  yield takeLatest(UPDATE_FEATURE, updateFeature);
  yield takeLatest(UPDATE_THEME_CONFIG_LIST, updateThemeConfig);
  yield takeLatest(DOWNLOAD_ATTACHEMENT, downloadAttachment);
  yield takeLatest(DOWNLOAD_LOGIN_LOGOUT_LOGO, downloadLoginLogoutLogo);
  yield takeLatest(UPLOAD_AS_FILE, uploadAsFile);
  yield takeLatest(UPLOAD_LOGO, uploadLogo);
  yield takeLatest(UPLOAD_FAVICON, uploadFavIcon);
  yield takeLatest(GET_MESSAGE_LABELS, getMessageLabels);
  yield takeLatest(SAVE_MESSAGE_PROP, updateMessageLabels);
  yield takeLatest(REQUEST_MANAGE_LOGS_LIST, requestManageLogs);

  yield takeLatest(GET_ALL_THEMES, getAllThemesSaga);
  yield takeLatest(GET_ALL_LOGOS, getAllLogosSaga);
  yield takeLatest(CREATE_THEME, createThemeSaga);
  yield takeLatest(ACTIVATE_THEME, activateThemeSaga);
  yield takeLatest(UPDATE_THEME, updateThemeSaga);
  yield takeLatest(GET_LOGO, getLogoSaga);
  yield takeEvery(GET_LOGO_TO_SHOW, getLogoToShowSaga);
  yield takeEvery(DELETE_THEME, deleteThemeSaga);
  yield takeEvery(DELETE_LOGO, deleteLogo);
  yield takeEvery(GET_FAVICON, getFavIcon);
}
