import { AlertBanner, Button, ModalIsland } from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  getCheckInApi,
  getListRowApi,
} from '../../utilities/api/getDataFromApi';

function CheckInModal({ isModalOpen, setIsModalOpen, row, setListRowData }) {
  const [alertData, setAlertData] = useState(null);

  const CHECKED_IN_MESSAGE = {
    'SUCCESS': "You've checked in the credential and your session has ended.",
    'FAILED': 'This session has already ended.',
    'ERROR': 'Error in check-in API:'
  }
  const ALERT_BANNER_TITLE ='Are you done with the credential and ready to check in?';

  const handleCheckIn = () => {
    setIsModalOpen(false);
    getCheckInApi(row)
      .then((response) => {
        const isSuccess = response.status === 200;
        const isEnded = response.status === 400;

        function getDescription() {
          if (isSuccess) {
            return CHECKED_IN_MESSAGE.SUCCESS;
          }

          if (isEnded) {
            return CHECKED_IN_MESSAGE.FAILED;
          }

          return response.message;
        }

        const alertDataContent = {
          colorTheme: isSuccess ? 'Success' : 'Critical',
          title: isSuccess ? 'Success!' : 'Failed!',
          description: getDescription(),
        };

        setAlertData(alertDataContent);

        if (isSuccess || isEnded) {
          getListRowApi().then((res) => {
            setListRowData(res);
          });
        }
      })
      .catch((error) => {
        console.error(CHECKED_IN_MESSAGE.ERROR, error);
      })
  };

  return (
    <>
      <ModalIsland
        kind='warning'
        alertTitle={ALERT_BANNER_TITLE}
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        primaryButton={(<Button
          type='button'
          kind='outlined'
          size='medium'
          onClick={handleCheckIn}>
            Check In
        </Button>)}
        secondaryButton={(<Button
          type='button'
          kind='ghost'
          size='medium'
          onClick={() => {
            setIsModalOpen(false);
          }}>
            Cancel
        </Button>)}
      />
      {alertData && (
        <AlertBanner
          colorTheme={alertData.colorTheme}
          title={alertData.title}
          description={alertData.description}
          isVisible
          onCancel={() => setAlertData(null)}
          isColonVisible={false}
          isFloating
          position={{
            type: 'fixed',
            top: '4.25rem',
            left: '3rem',
            right: '3rem',
          }}
        />
      )}
    </>
  );
}

CheckInModal.defaultProps = { isModalOpen: false };

CheckInModal.propTypes = {
  setIsModalOpen: PropTypes.func.isRequired,
  setListRowData: PropTypes.func.isRequired,
  row: PropTypes.shape({
    requestAccessKey: PropTypes.number,
    accountName: PropTypes.string,
    endpointDisplayName: PropTypes.string,
  }).isRequired,
  isModalOpen: PropTypes.bool,
};

export default CheckInModal;
