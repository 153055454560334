import React from 'react';
import classnames from 'classnames';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { push } from 'react-router-redirect';
import Fade from '@material-ui/core/Fade';
import replaceLinksWithCookies from '../../../../ui-common/src/lib/replaceLinksWithCookies';
import styles from './MenuItem.module.css';
import ExpandableIcons from '../ExpandableIcons/ExpandableIcons';
import BackChevron from '../BackChevron/BackChevron';
import GetIcons from '../../utils/GetIcons';
import findAssociatedMenuForNavigatedUrl from '../../utils/findAssociatedMenuForNavigatedUrl';
import { whiteListedLinks } from '../../Constants';

const useStyles = makeStyles({
  root: {
    color: 'var(--SIDEMENU-TEXT-COLOR)',
    // color: getTextColor(),
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '0',
    paddingLeft: '20px',
    // paddingRight: 16,
    '&:hover': {
      background: 'var(--SIDEMENU-HOVER-BACKGROUND)',
      color: 'var(--SIDEMENU-HOVER-TEXT-COLOR)',
      '&:before': {
        background: 'var(--SIDEMENU-HOVER-BACKGROUND)',
        transform: 'scale(1)',
      },
    },
  },
  primary: {
    color: 'var(--SIDEMENU-TEXT-COLOR)',
    // color: getTextColor(),
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'initial',
    visibility: 'visible',
    fontSize: '13px',
    width: 185,
  },
  secondary: {
    color: 'var(--SIDEMENU-SUBTEXT-COLOR)',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'initial',
    visibility: 'visible',
  },
  title: {
    color: 'var(--SIDEMENU-TEXT-COLOR)',
    // color: getTextColor(),
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'initial',
    visibility: 'visible',
    margin: '12px 0',
    paddingLeft: '6px',
  },
  childMenuItem: {
    fontSize: '12px',
  },
  doNotDisplay: {
    display: 'none',
  },
  icon: {
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: '0',
    marginRight: '6px',
    // paddingLeft: '14px',
    // marginLeft: '6px',
  },
  iconWithoutMargin: {
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: '0',
    // marginLeft: '6px',
  },
  iconWithLeftMargin: {
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: '0',
    // marginLeft: '6px',
  },
});

const MenuItem = ({
  intl,
  icon,
  title,
  subTitle,
  selected,
  isOpen,
  isExpandable,
  nestedClassName,
  isParentOpen,
  level,
  handleClick,
  handleBackClick,
  to,
  collapseSideMenu,
  isSideMenuOpen,
  color,
  isSearchActive,
  handleDrawerClose,
  isTranslated,
}) => {
  const classes = useStyles();
  // TODO - To be deleted once API is ready
  const getTranslatedText = (text) => intl.formatMessage({ id: `gen2.leftnav.label.${text}` }); // gen2.leftnav.label
  const data = useSelector((state) => state.app.sideMenu);
  const isBackIconVisible =
    isParentOpen && isOpen !== false && !isSearchActive ? !collapseSideMenu : false;
  const isSubtitle = subTitle ? getTranslatedText(subTitle) : null;
  const secondaryText = isTranslated ? subTitle : isSubtitle;
  const getCSSForTitle = (cssClass) => {
    if (isSideMenuOpen) {
      if (GetIcons({ id: icon }) === null || level===3 ) {
        return classnames(cssClass, styles.level3Text, selected && styles.bold);
      }
      return `${cssClass} 123`;
    }
    return classes.doNotDisplay;
  };
  const getCSSForIcon = () => {
    if (isSearchActive && isSideMenuOpen && level === 1) {
      return classes.iconWithLeftMargin;
    }
    if (isSideMenuOpen && !isBackIconVisible) {
      return classes.icon;
    }
    if (isBackIconVisible) {
      return classes.iconWithoutMargin;
    }
    return classes.iconWithLeftMargin;
  };
  const getFontSize = (level) => level !== 1 && classes.childMenuItem;
  const classname = classnames(
    styles.menuItem,
    selected ? styles.selectedMenuItem : styles.notSelectedMenuItem
  );
  const onClick = () => {
    if (to) {
      let link = replaceLinksWithCookies(to);
      findAssociatedMenuForNavigatedUrl(data, link);
      if (link[0] !== '/') {
        link = `/${to}`;
      }
      const checkUrlIsECM = (url) => whiteListedLinks.includes(`/${url.split('/')[1]}`);
      if (checkUrlIsECM(link) || checkUrlIsECM(window.location.pathname)) {
        window.location.assign(`${window.location.origin}${link}`);
      } else {
        push(link);
        handleDrawerClose();
      }
    }
    if (handleClick) handleClick();
  };
  return (
    <div data-testid={selected ? 'selected' : 'not-selected'} className={classname}>
      <div
        className={selected ? styles.selected : styles.notSelected}
        // style={{ borderColor: color }}
        // style={{ borderColor: getPrimaryColor() }}
        style={{ borderColor: '--SIDEMENU-ICON-PRIMARY' }}
      />
      <ListItem
        button
        selected={selected}
        classes={classes}
        className={[nestedClassName, selected ? styles.selectedMenuBackground : '']}
        onClick={onClick}
      >
        <BackChevron isVisible={isBackIconVisible} handleBackClick={handleBackClick} />
        <ListItemIcon classes={{ root: getCSSForIcon() }}>
          {(level === 1 || level === 2) && <GetIcons id={icon} />}
        </ListItemIcon>
        <Fade in={isSideMenuOpen} timeout={500}>
          <ListItemText
            primary={getTranslatedText(title)}
            secondary={secondaryText}
            classes={{
              root: getCSSForTitle(classes.title),
              primary: `${getCSSForTitle(classes.primary, selected)} ${getFontSize(level)}`,
              secondary: getCSSForTitle(classes.secondary),
            }}
          />
        </Fade>
        <Fade in={isSideMenuOpen} timeout={1000}>
          <div className={styles.expandIcon}>
            <ExpandableIcons
              isExpandable={isSideMenuOpen ? isExpandable : false}
              isOpen={isOpen}
              level={level}
            />
          </div>
        </Fade>
      </ListItem>
    </div>
  );
};

MenuItem.defaultProps = {
  isParentOpen: false,
  level: 1,
  isTranslated: false,
};
export default injectIntl(MenuItem);
