/**
 * Localizes a message using the intl object param.
 * @param {object} intl - The intl object used for message formatting.
 * @param {string} messageId - The ID of the message to be localized.
 * @param {object} placeholders - Optional object containing placeholder values.
 * @returns {string} The localized message or id if not found.
 */

// eslint-disable-next-line import/prefer-default-export
export const localize = (intl, messageId, placeholders = {}) =>
  intl.formatMessage({ id: messageId }, placeholders);
