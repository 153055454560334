/* eslint-disable object-curly-newline */
import './AccountSelection.css';

import { Link, RadioButton } from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React from 'react';

const ACCOUNT_OUT_OF_SLICE = 5;
const MAX_ACCOUNTS_TO_SHOW = 6;

function AccountSelection({
  accountListArray,
  accountSelection,
  setAccountSelection,
  setIsAccountListModal,
}) {
  const handleClick = (e, value) => {
    e.stopPropagation();
    setAccountSelection(value);
  };

  const handleAccountList = () => {
    setIsAccountListModal(true);
  };

  const newAccountListArray = Array.isArray(accountListArray)
    ? [...accountListArray]
    : [];

  if (newAccountListArray.length > 0) {
    const selectedIndex = newAccountListArray.findIndex(
      (account) => account.value === accountSelection?.value
    );

    if (selectedIndex > ACCOUNT_OUT_OF_SLICE) {
      const [selectedItem] = newAccountListArray.splice(selectedIndex, 1);

      newAccountListArray.unshift(selectedItem);
    }
  }

  return (
    <div className='AccountSelection'>
      <div className='AccountSelection-radioButtons'>
        {newAccountListArray
          ?.slice(0, MAX_ACCOUNTS_TO_SHOW)
          .map((account, index) => (
            <RadioButton
              key={`accountRadio-${account.value}`}
              id={`accountRadio-${index}`}
              name='accountSelectionRadioGroup'
              title={account.title}
              description={account.description}
              value={account}
              radioSelection={accountSelection}
              onClick={handleClick}
              isTruncated
            />
          ))}
      </div>
      {accountListArray?.length > MAX_ACCOUNTS_TO_SHOW ? (
        <Link
          href='Account More Modal Button'
          text='Show More'
          size='large'
          onClick={() => handleAccountList()}
        />
      ) : null}
    </div>
  );
}

AccountSelection.propTypes = {
  accountListArray: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  accountSelection: PropTypes.shape({
    value: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  setAccountSelection: PropTypes.func,
  setIsAccountListModal: PropTypes.func,
};

AccountSelection.defaultProps = {
  accountListArray: null,
  accountSelection: null,
  setAccountSelection: () => {},
  setIsAccountListModal: () => {},
};

export default AccountSelection;
