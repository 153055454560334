import { useEffect, useState } from 'react';

import { getRequestUploadMetaDataApi } from '../utilities/api/getDataFromApi';

/**
 * Custom hook for requesting upload metadata.
 * @param {boolean} mockData - Mock data object used temporarily until the API is ready.
 *
 * @returns {Object|null} - The upload metadata response data or null if not available.
 */

function useRequestUploadsMetaData(mockData) {
  const [data, setData] = useState(null);

  useEffect(() => {
    getRequestUploadMetaDataApi(mockData)
      .then((responseData) => {
        setData(responseData);
      })
      .catch(() => {
        // TODO: consider error options when API is ready
        // eslint-disable-next-line no-console
        console.error('Error requesting upload metadata');
      });
  }, []);

  return data;
}

export default useRequestUploadsMetaData;
