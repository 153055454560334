import { Cookies } from 'react-cookie';

class HttpService {
  constructor() {
    this.cookies = new Cookies();
    this.headers = {
      'Content-Type': 'application/json',
      Authorization: `${this.cookies.get('token_type')} ${this.cookies.get(
        'access_token'
      )}`,
    };
  }

  get(url) {
    return fetch(url, {
      method: 'GET',
      headers: this.headers,
    }).then((res) => {
      if (res.status === 200) {
        return res.json();
      }

      return res;
    });
  }

  post(url, req, errorCallback) {
    return fetch(url, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(req),
    }).then((res) => {
      if (res.status === 200) {
        const contentType = res.headers.get('content-type');

        if (contentType && contentType.includes('application/json')) {
          return res.json();
        }

        return res;
      }

      // Check if errorCallback is a function before calling it
      if (typeof errorCallback === 'function') {
        errorCallback();
      } else {
        console.error('Error: errorCallback is not a function.');
      }

      return res;
    });
  }

  put() {
    // TODO: Enhance this when needed!
  }

  delete() {
    // TODO: Enhance this when needed!
  }
}

export default new HttpService();
