import './Scheduler.css';

import { formatSchedulerLocalizedDateString } from '@saviynt/common';
import { Box } from '@saviynt/design-system';
import classnames from 'classnames';
import { isSameDay } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { Session } from '../../models/PamModels';
import SchedulerDay from './ScheduleDay/SchedulerDay';

function Scheduler({
  sessions,
  numOfDaysDisplayed,
  timeFormat,
  isModalView,
  dataTestId,
  className,
}) {
  const classes = classnames('Scheduler', className);

  const renderBlock = () => {
    const weekdays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const daysArray = [];

    for (let i = 0; i < numOfDaysDisplayed; i += 1) {
      const currentDate = new Date();

      currentDate.setDate(currentDate.getDate() + i);
      daysArray.push(currentDate);
    }

    return daysArray.map((day) => {
      const reservedSessions = [];

      sessions.forEach((session) => {
        const sessionStartDate = new Date(session.startDate);
        const currentDay = new Date(day);

        if (isSameDay(sessionStartDate, currentDay)) {
          reservedSessions.push(session);
        }
      });

      const availableStartDate = new Date(day);

      availableStartDate.setHours(0, 0, 0, 0);

      return (
        <Box key={daysArray.indexOf(day)} className='Scheduler-block'>
          <section className='Scheduler-blockLabel'>
            <div className='Scheduler-blockLabel--weekday'>
              {`${
                daysArray.indexOf(day) === 0 ? 'Today' : weekdays[day.getDay()]
              }, `}
            </div>
            <div className='Scheduler-blockLabel--date'>
              {formatSchedulerLocalizedDateString(day)}
            </div>
          </section>
          <SchedulerDay
            sessions={reservedSessions}
            timeFormat={timeFormat}
            isModalView={isModalView}
            availableStartDate={availableStartDate}
          />
        </Box>
      );
    });
  };

  return (
    <section className={classes} data-testid={dataTestId}>
      {renderBlock()}
    </section>
  );
}

Scheduler.propTypes = {
  sessions: PropTypes.arrayOf(Session).isRequired,
  numOfDaysDisplayed: PropTypes.number,
  timeFormat: PropTypes.shape({
    roundToNearest: PropTypes.string,
    is24HourFormat: PropTypes.bool,
  }).isRequired,
  isModalView: PropTypes.bool,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

Scheduler.defaultProps = {
  numOfDaysDisplayed: 10,
  isModalView: false,
  dataTestId: null,
  className: null,
};

export default Scheduler;
