import './ConnectToSession.css';

import { handleScroll, InSeparator, InterPoint } from '@saviynt/common';
import {
  AccordionStepper,
  Button,
  FooterActionBar,
  InputField,
  Link,
  ModalPageHeader,
  Typography,
} from '@saviynt/design-system';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

function ConnectToSession() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [savedValue1, setSavedValue1] = useState({
    SERVER: '',
    PORT: '3389',
  });
  const [savedValue2, setSavedValue2] = useState({
    SERVER: '',
    PORT: '3389',
  });
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('3389');

  const modalContentClass = classnames('ConnectToSession');

  const onScrollCallback = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    handleScroll(onScrollCallback);
  }, [isScrolled]);

  const closeHandler = () => {
    window.history.back();
  };

  const handleSubmit1 = () => {
    setIsExpanded1(false);
    setSavedValue1({ SERVER: value1, PORT: value2 });
    setIsExpanded2(true);
  };

  const subHeadingContent = () => (
    <div className='ModalPage-subHeadingContent'>
      <p>WIN-LA-breakglass</p>
      <InterPoint color='neutralSubtlest' />
      <p>Account</p>
      <InSeparator color='neutralInverse' />
      <p>Vault</p>
    </div>
  );

  const suffixLink = () => (
    <Link
      href='http://localhost:3001/modal-example'
      url='http://localhost:3001/modal-example'
      text='Optional Link'
      prefixIconKind='LinkChain'
      target='_self'
      shouldStopPropagation
    />
  );

  const accordionSupportingValue = () =>
    savedValue1.SERVER && !isExpanded1 ? (
      <div className='accordionValueFlexBox'>
        <div className='accordionValueBody'>
          <p className='accordionValueHeading'>
            Server:
            <span className='accordionValue'>{savedValue1.SERVER}</span>
          </p>
        </div>
        <div className='accordionValueBody'>
          <p className='accordionValueHeading'>
            Port:
            <span className='accordionValue'>{savedValue1.PORT}</span>
          </p>
        </div>
      </div>
    ) : null;

  const accordionPanelContent = () => (
    <div className='contentFlexBox'>
      <div className='inputFlexBox'>
        <InputField
          name='Target IP'
          placeholder='Enter the name or IP address of the remote system'
          label={
            <Typography kind='label' htmlFor='Target Windows Server'>
              Target Windows Server
            </Typography>
          }
          backgroundColor='secondary'
          isRequired
          value={value1}
          setValue={setValue1}
        />
        <InputField
          name='Port Number'
          placeholder='Port Number'
          label={
            <Typography kind='label' htmlFor='Port Number'>
              Port Number
            </Typography>
          }
          backgroundColor='secondary'
          value={value2}
          setValue={setValue2}
        />
      </div>
      {isExpanded1 && value1.length > 0 ? (
        <div className='buttonFlexBox'>
          <Button type='button' kind='filled' onClick={() => handleSubmit1()}>
            Continue
          </Button>
        </div>
      ) : null}
    </div>
  );

  return (
    <div className='ModalPage-master' data-testid='ConnectToSession'>
      <ModalPageHeader
        title='Check Out Credential'
        subHeadingContent={subHeadingContent()}
        closeHandler={() => closeHandler()}
        className='ModalPage-header'
      />
      <section className={modalContentClass}>
        <AccordionStepper
          isExpanded={isExpanded1}
          setIsExpanded={setIsExpanded1}
          prefixIconKind='Server'
          headerText='What server would you like to connect to?'
          supportingText='Enter the Target Server Details'
          accordionValue={accordionSupportingValue()}
          suffixLink={suffixLink()}>
          {accordionPanelContent()}
        </AccordionStepper>
        {savedValue1.SERVER && (
          <AccordionStepper
            isExpanded={isExpanded2}
            setIsExpanded={setIsExpanded2}
            prefixIconKind='Server'
            headerText='What server would you like to connect to?'
            supportingText='Enter the Target Server Details'
            accordionValue={accordionSupportingValue()}
            suffixLink={suffixLink()}>
            {accordionPanelContent()}
          </AccordionStepper>
        )}
      </section>
      {/* TODO: replace/ remove this when the footer is updated. It should be passed as a classname. */}
      <div className='ModalPage-footer'>
        <FooterActionBar
          size='large'
          primaryButtonText=''
          secondaryButtonText='Submit ->'
          primaryButtonOnClick={() => {}}
          secondaryButtonOnClick={() => console.log('Submit attempted.')}
          isScrolled={isScrolled}
        />
      </div>
    </div>
  );
}

export default ConnectToSession;
