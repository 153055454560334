import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalIsland, FooterActionBar, Upload } from '@saviynt/design-system';
import { injectIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from 'ui-components/src';
import { ShowMessageModal } from 'ui-common/src/utils/ResponseHandler-actions';
import classes from './BrandingConfig.module.scss';
import { TabGroup, TabContent, TabItem } from './Components/Tabs/Tabs';
import Button from './Components/Button/Button';
import SidePanel from './Components/SidePanel/SidePanel';
import Icon from './Components/Icons/Icon';
import ThemeCard from './Components/ThemeCard/ThemeCard';
import {
  convertDisplayNametoId,
  getDisplayedTheme,
  getHostUrl,
  isEmpty,
  isInvalid,
  loadImage,
  message,
} from './utils/helper';
import {
  MODAL_TYPES,
  NEW_THEME_INITIAL_DATA,
  NEW_THEME_PAYLOAD,
  TABS_DATA,
  THEME_CHANGEABLE_ITEMS_LOGOS,
  THEME_CHANGEABLE_ITEMS_STYLES,
} from './utils/constants';
import DynamicModal from './Components/DynamicModal/DynamicModal';
import {
  deleteTheme,
  getAllThemes,
  createTheme,
  activateTheme,
  uploadLogo,
  updateTheme,
  getAllLogos,
  getLogoToShow,
  getFavIcon,
  downloadAttachment,
  uploadFavIcon,
  uploadAsFile,
  downloadLoginLogoutLogo,
} from '../../redux/adminHome-actions';
import InputField from './Components/Input/InputField/InputField';
import ColorPickerTile from './Components/ColourPicker/ColourPickerTile';
import ExampleModals from './Components/StaticComponents/ExampleModals';
import LogoCard from './LogoCard';
import LogoStrip from './LogoStrip';
import AppLogo from 'ui-home/src/features/MainLayout/Header/AppLogo/AppLogo';
import { checkEnvironment } from 'ui-common/src/lib/utils';

const LinkButton = ({ onClick, label }) => (
  <button type="button" className={classes.linkButton} onClick={onClick}>
    {' '}
    {label}
  </button>
);

const BrandingConfig = (props) => {
  const { intl } = props;
  const SERVICE_ENDPOINT_IDWMS = checkEnvironment().IDWMS_API_URL;
  const logoUploadUrl = `${getHostUrl()}/logos?type=navigation`;
  const favIconUploadUrl = `${getHostUrl()}/logos/favicon`;
  const loginLogoutLogoUrl = `${SERVICE_ENDPOINT_IDWMS}/image/getAsFile?path=/app/SaviyntR_White-Original.png`;
  const imageUploadUrl = `${SERVICE_ENDPOINT_IDWMS}/image/uploadAsFile`;
  const initialActiveTab = TABS_DATA[0].value;
  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedThemeVariant, setSelectedThemeVariant] = useState({});
  const [selectedThemeVariantBackup, setSelectedThemeVariantBackup] = useState({});
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [showIslandModal, setShowIslandModal] = useState(false);
  const [showSelectLogoModal, setShowSelectLogoModal] = useState(false);
  const [isDoneDisabled, setIsDoneDisabled] = useState(true);
  const [modalType, setModalType] = useState(false);
  const [themeName, setThemeName] = useState('');
  const [openColourPickerType, setOpenColourPickerType] = useState('');
  const [showToast, setToast] = useState(false);
  const [showFaviconUploaded, setShowFaviconUploaded] = useState(false);
  const [showLoginLogoutLogoUploaded, setShowLoginLogoutLogoUploaded] = useState(false);
  const [showLogoUploaded, setShowLogoUploaded] = useState(false);
  const [faviconErrorMessage, setFaviconErrorMessage] = useState('');
  const [logoErrorMessage, setLogoErrorMessage] = useState('');
  const [uploadedLogoFile, setUploadedLogoFile] = useState([]);
  const [uploadedFavIconFile, setUploadedfavIconFile] = useState([]);
  const [uploadedLoginLogoutLogoFile, setUploadedLoginLogoutLogoFile] = useState([]);
  const [nameValidationError, setNameValidationError] = useState('');
  const [showThemeCreationAlert, setShowThemeCreationAlert] = useState(false);
  const [primaryAction, setPrimaryAction] = useState(() => () => {});
  const logos = useSelector((state) => state.adminHome?.brandingConfig?.logos);
  const favIconFileName = useSelector((state) => state.adminHome?.brandingConfig?.favIconFileName);
  const favIcon = useSelector((state) => state.adminHome?.brandingConfig?.favIcon);
  const loginLogoutLogo = useSelector((state) => state.adminHome?.brandingConfig?.loginLogoutLogo);
  const [favIconImage, setFavIconImage] = useState('');
  const [loginLogoutLogoImage, setLoginLogoutLogoImage] = useState('');
  const [uploadedFaviconFileName, setUploadedFaviconFileName] = useState(favIconFileName);
  const [uploadedLoginLogoutFileName, setUploadedLoginLogoutFileName] = useState('');
  const [showFavIconContainer, setShowFavIconContainer] = useState(true);
  const [showLoginLogoutLogoContainer, setShowLoginLogoutLogoContainer] = useState(true);
  const [uploadedLogoFileName, setUploadedLogoFileName] = useState(null);
  const [uploadedLogoFileDetails, setUploadedLogoFileDetails] = useState(null);
  const [showExampleModalVariant, setShowExampleModalVariant] = useState('');
  const [logoPopupMode, setLogoPopupMode] = useState();
  const [callUploadLogoAPI, setCallUploadLogoAPI] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllThemes());
    dispatch(getAllLogos());
    dispatch(getFavIcon());
  }, []);

  function handleFileDownloadResponse(responseUrl, fileName) {
    //set this blob received to UploadFile component svg
    setFavIconImage(responseUrl);
  }

  function handleDownloadLoginLogoutLogoResponse(responseUrl, fileName) {
    //set this blob received to UploadFile component svg
    setLoginLogoutLogoImage(responseUrl);
  }

  useEffect(() => {
    dispatch(
      downloadAttachment(
        `${getHostUrl()}/navigation/favicon`,
        {},
        'GET',
        'Icon.svg',
        handleFileDownloadResponse
      )
    );
    setUploadedFaviconFileName(favIcon);
  }, [favIcon]);

  useEffect(() => {
    dispatch(
      downloadLoginLogoutLogo(
        loginLogoutLogoUrl,
        {},
        'GET',
        'SaviyntR_White-Original.png',
        handleDownloadLoginLogoutLogoResponse
      )
    );
    setUploadedLoginLogoutFileName(loginLogoutLogo);
  }, [loginLogoutLogo, showLoginLogoutLogoUploaded]);

  const groupThemesByDisplayName = (themes) => {
    const groupedThemes = {};

    if (themes) {
      themes.forEach((theme) => {
        const { name, appearance, isOOTB, styles, logos, isActive } = theme;
        const { displayName } = styles || {};

        if (!groupedThemes[displayName]) {
          groupedThemes[displayName] = [];
        }

        const themeObject = {
          name,
          appearance,
          isOOTB,
          isActive,
          styles,
          logos,
        };

        if (appearance === 'dark') {
          // Add theme with 'dark' appearance as first item
          groupedThemes[displayName].unshift(themeObject);
        } else {
          groupedThemes[displayName].push(themeObject);
        }
      });
    }

    return groupedThemes;
  };

  const themeData = useSelector((state) => state.adminHome?.brandingConfig?.themes);
  const createdTheme = useSelector((state) => state.adminHome?.brandingConfig?.createdTheme);
  const activatedTheme = useSelector((state) => state.adminHome?.brandingConfig?.activatedTheme);
  const updatedTheme = useSelector((state) => state.adminHome?.brandingConfig?.updatedTheme);
  const activeTheme = useSelector((state) => state.adminHome?.brandingConfig?.activeTheme);
  const deletedTheme = useSelector((state) => state.adminHome?.brandingConfig?.deletedTheme);
  const deletedThemeError = useSelector(
    (state) => state.adminHome?.brandingConfig?.deletedThemeError
  );
  const uploadLogoSuccess = useSelector(
    (state) => state.adminHome?.brandingConfig?.uploadLogoSuccess
  );
  const uploadFavIconFailure = useSelector(
    (state) => state.adminHome?.brandingConfig?.uploadFavIconFailure
  );
  const deleteLogoSuccess = useSelector(
    (state) => state.adminHome?.brandingConfig?.deleteLogoSuccess
  );
  const deleteLogoSuccessMsg = useSelector(
    (state) => state.adminHome?.brandingConfig?.deleteLogoSuccessMsg
  );
  const deletedLogoError = useSelector(
    (state) => state.adminHome?.brandingConfig?.deletedLogoError
  );

  useEffect(() => {
    if (deleteLogoSuccess) showSubtleMessage(deleteLogoSuccessMsg, 'success');
    else {
      if (deletedLogoError) {
        const { error } = deletedLogoError;
        if (error) showSubtleMessage(error[0]?.defaultMessage, 'failure');
      }
    }
  }, [deleteLogoSuccess, deletedLogoError]);

  useEffect(() => {
    if (logoErrorMessage) {
      if (logoErrorMessage) showSubtleMessage(logoErrorMessage, 'failure');
    }
  }, [logoErrorMessage]);

  useEffect(() => {
    if (faviconErrorMessage) {
      if (faviconErrorMessage) showSubtleMessage(faviconErrorMessage, 'failure');
    }
  }, [faviconErrorMessage]);

  useEffect(() => {
    if (uploadedLogoFileName) setIsDoneDisabled(false);
    else setIsDoneDisabled(true);
  }, [uploadedLogoFileName]);

  const resetPage = () => {
    setShowThemeCreationAlert(false);
    setShowSelectLogoModal(false);
    setFaviconErrorMessage('');
    setNameValidationError('');
    setSelectedTheme([]);
    setSelectedThemeVariant({});
    setIsCreateMode(false);
    setThemeName('');
    setIsSidePanelOpen(false);
    setShowIslandModal(false);
  };

  const [allThemes, setAllThemes] = useState(groupThemesByDisplayName(themeData) || []);
  const showSubtleMessage = (message, status) =>
    dispatch(
      ShowMessageModal({
        message,
        encrypted: false,
        status,
      })
    );

  useEffect(() => {
    setAllThemes(groupThemesByDisplayName(themeData));
  }, [themeData]);

  useEffect(() => {
    if (!isEmpty(createdTheme)) {
      if (createdTheme?.error) {
        showSubtleMessage(
          message(intl, 'UI.Gen2.Admin.Branding.Theme.Creation.Error.Title'),
          'failure'
        );
      } else {
        setSelectedTheme([createdTheme]);
        setSelectedThemeVariant(createdTheme);
        setIsCreateMode(false);
        setThemeName('');
        setShowThemeCreationAlert(true);
      }
    }
  }, [createdTheme]);

  useEffect(() => {
    if (!isEmpty(updatedTheme)) {
      if (updatedTheme?.error) {
        showSubtleMessage(
          message(intl, 'UI.Gen2.Admin.Branding.Theme.Update.Error.Title'),
          'failure'
        );
      } else {
        setSelectedTheme(themeData.filter((theme) => theme.name === updatedTheme?.name));
        setSelectedThemeVariant(updatedTheme);
        setIsCreateMode(false);
        setThemeName('');
        showSubtleMessage(
          message(intl, 'UI.Gen2.Admin.Branding.Theme.Update.Success.Title'),
          'success'
        );
      }
    }
  }, [updatedTheme]);

  useEffect(() => {
    if (!isEmpty(activatedTheme)) {
      if (activatedTheme?.error) {
        showSubtleMessage(
          message(intl, 'UI.Gen2.Admin.Branding.Theme.Activate.Error.Title'),
          'failure'
        );
      } else {
        setIsCreateMode(false);
        setThemeName('');
        showSubtleMessage(
          message(intl, 'UI.Gen2.Admin.Branding.Theme.Activate.Success.Title'),
          'success'
        );
        resetPage();
      }
    }
  }, [activatedTheme]);

  useEffect(() => {
    if (!isEmpty(selectedThemeVariant)) {
      const backup = selectedTheme.filter(
        (theme) =>
          selectedThemeVariant?.name === theme.name &&
          selectedThemeVariant?.appearance === theme?.appearance
      );
      setSelectedThemeVariantBackup(backup[0] || {});
    } else {
      setSelectedThemeVariantBackup({});
    }
  }, [selectedThemeVariant]);

  const handleTabChange = (value) => {
    setActiveTab(value);
    resetPage();
  };

  const hasThemeChanged = () => {
    let changed = false;
    if (isCreateMode) return false;
    if (!isEmpty(selectedThemeVariant) && !isEmpty(selectedThemeVariantBackup)) {
      if (selectedThemeVariant?.styles) {
        THEME_CHANGEABLE_ITEMS_STYLES.forEach((item) => {
          if (selectedThemeVariantBackup?.styles?.[item] !== selectedThemeVariant.styles?.[item]) {
            changed = true;
          }
        });
      }
      if (selectedThemeVariant?.logos) {
        THEME_CHANGEABLE_ITEMS_LOGOS.forEach((item) => {
          if (selectedThemeVariantBackup?.logos?.[item] !== selectedThemeVariant?.logos?.[item]) {
            changed = true;
          }
        });
      }
    }
    return changed;
  };

  const closeSideBar = (val) => {
    if (!val) {
      resetPage();
    }
  };

  const handleSideBarMode = (val) => {
    if (!val) {
      const isEdited = hasThemeChanged();
      if (isCreateMode) {
        // setModalType('createtheme');
        // setShowIslandModal(true);
        closeSideBar(false);
      } else if (isEdited) {
        setModalType('unsaved');
        setShowIslandModal(true);
        setPrimaryAction(() => () => closeSideBar(false));
      } else {
        closeSideBar(false);
      }
    }
  };

  const themeSelection = (data, isDiscard) => {
    const isEdited = hasThemeChanged();
    if (isEdited && !isDiscard) {
      setModalType('unsaved');
      setShowIslandModal(true);
      setPrimaryAction(() => () => themeSelection(data, true));
    } else if (!isDiscard && isCreateMode) {
      setModalType('createtheme');
      setShowIslandModal(true);
      setPrimaryAction(() => () => themeSelection(data, true));
    } else {
      setIsCreateMode(false);
      setIsSidePanelOpen(true);
      setShowIslandModal(false);
      const enabledTheme = Object.values(data).find(
        (theme) =>
          theme?.name === activeTheme?.name && theme?.appearance === activeTheme?.appearance
      );
      setSelectedThemeVariant(enabledTheme || getDisplayedTheme(data));
      setSelectedTheme(data);
    }
  };

  const handleVariantSelection = (variant) => {
    setSelectedThemeVariant(variant);
  };

  const deleteThemeAction = (name) => {
    dispatch(deleteTheme({ name }));
  };

  useEffect(() => {
    if (deletedTheme) {
      resetPage();
    }
  }, [deletedTheme]);

  useEffect(() => {
    if (deletedThemeError) {
      showSubtleMessage(message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.CannotDelete.Title'), 'failure');
      setShowIslandModal(false);
    }
  }, [deletedThemeError]);

  const handleDeleteTheme = (val, theme) => {
    const { name } = theme || {};
    const isActive = isActiveVariant(theme);
    const modalType = isActive ? 'cannotDelete' : val;
    setPrimaryAction(() => () => (isActive ? setShowIslandModal(false) : deleteThemeAction(name)));
    setShowIslandModal(true);
    setModalType(modalType);
  };

  const enableThemeCreation = () => {
    setIsCreateMode(true);
    setSelectedTheme([]);
    setSelectedThemeVariant(NEW_THEME_INITIAL_DATA);
    setIsSidePanelOpen(true);
  };

  const handleChange = (theme, type, subtype) => (val) => {
    const updatedTheme = JSON.parse(JSON.stringify(selectedThemeVariant));
    if (type === 'name') {
      let convertedName = selectedThemeVariant?.name || '';
      const newThemeName = val?.replace(/\s{2,}/g, ' ');
      setThemeName(newThemeName);
      const newVal = val?.replace(/\s{2,}/g, ' ')?.trim();
      const error = isInvalid(type, newVal);
      if (error && updatedTheme?.name) {
        const errorMessage = message(intl, error);
        setNameValidationError(errorMessage);
      } else {
        setNameValidationError('');
        convertedName = convertDisplayNametoId(newVal) || '';
      }
      updatedTheme.name = convertedName;
      updatedTheme.styles = { ...updatedTheme.styles, displayName: newThemeName.trim() };
    } else if (type === 'color') {
      const newColor = `rgba(${val.rgb.r}, ${val.rgb.g}, ${val.rgb.b}, ${val.rgb.a})`;
      updatedTheme.styles[subtype] = newColor;
    } else if (type === 'logos') {
      updatedTheme.logos[subtype] = val;
    }
    setSelectedThemeVariant(updatedTheme);
  };

  useEffect(() => {
    if (isCreateMode) {
      handleChange(selectedTheme, 'name')(themeName);
    }
  }, [themeName]);

  const generatePayload = () => {
    const { name, styles, logos } = { ...selectedThemeVariant };

    const payload = {
      ...NEW_THEME_PAYLOAD,
      name,
      styles: {
        ...selectedThemeVariant.styles,
        displayName: styles?.displayName,
        isMulticolor: false,
      },
      logos: logos || NEW_THEME_PAYLOAD.logos,
    };

    payload.styles = JSON.stringify(payload.styles);
    return payload;
  };

  const createThemeHandler = () => {
    const payload = generatePayload();
    dispatch(createTheme(payload));
  };

  const updateThemeHandler = () => {
    const payload = generatePayload();
    const { appearance, isOOTB } = { ...selectedThemeVariant };
    if (isOOTB) payload.appearance = appearance;
    payload.type = 'navigation';
    dispatch(updateTheme(payload, isActiveVariant(selectedThemeVariant)));
  };

  const activateThemeHandler = () => {
    const payload = { ...selectedThemeVariant };
    dispatch(activateTheme(payload));
  };

  const handleColourPickerToggle = (type) => (val) => {
    setOpenColourPickerType(val ? type : '');
  };

  const MODAL_ACTIONS = {
    delete: {
      primaryAction: () => {},
      secondaryAction: () => setShowIslandModal(false),
    },
    unsaved: {
      primaryAction: () => closeSideBar(false),
      secondaryAction: () => setShowIslandModal(false),
    },
    createtheme: {
      primaryAction: () => closeSideBar(false),
      secondaryAction: () => setShowIslandModal(false),
    },
  };

  const CreateThemeButton = () => (
    <Button
      type="button"
      kind="outlined"
      size="small"
      leftIcon={<Icon kind="plus" />}
      className={classes.createThemeButton}
      onClick={enableThemeCreation}
    >
      {message(intl, 'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Footer.Button.Create')}
    </Button>
  );

  const resetColourType = (type) => {
    let updatedTheme = { ...selectedThemeVariant };
    updatedTheme = {
      ...updatedTheme,
      styles: {
        ...updatedTheme?.styles,
        [type]: selectedThemeVariantBackup?.styles?.[type],
      },
    };
    setSelectedThemeVariant(updatedTheme);
  };

  const isEditedColourType = (type) =>
    selectedThemeVariant &&
    selectedThemeVariantBackup &&
    selectedThemeVariant?.styles?.[type] !== selectedThemeVariantBackup?.styles?.[type];

  const isSelectedVariant = (variant) =>
    selectedThemeVariant &&
    selectedThemeVariant?.name === variant?.name &&
    selectedThemeVariant?.appearance === variant?.appearance;

  const isActiveVariant = (variant) =>
    activeTheme &&
    activeTheme?.name === variant?.name &&
    activeTheme?.appearance === variant?.appearance;

  const isMandatoryFieldEmpty = () => {
    const {
      name,
      styles: { primary, secondary, background },
      logos: { logo, headerlogo },
    } = selectedThemeVariant || {};
    const mandatoryFields = [name, primary, secondary, background, logo, headerlogo];

    return isCreateMode && mandatoryFields.every((field) => field);
  };

  const resetColorTooltip = message(
    intl,
    'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Colour.ResetTooltip'
  );

  const contentLayout = () => {
    const {
      isOOTB,
      styles: { primary, secondary, background } = {},
      name,
      appearance,
      logos: { logo, headerlogo } = {},
    } = selectedThemeVariant || {};
    return (
      <div className={classes.contentLayout}>
        {isOOTB && (
          <>
            <div className={classes.sidePanelSection}>
              <p className={`${classes.subSectionHeaderText} ${classes.darkText} ${classes.mb_4}`}>
                {message(intl, 'UI.Gen2.Admin.Branding.Theme.SideBar.Section.ThemeOption')}
              </p>
              <div>
                <div className={classes.dFlex}>
                  {selectedTheme &&
                    selectedTheme.map((theme) => {
                      const { primary } = theme?.styles || {};
                      return (
                        <>
                          {theme?.appearance === 'dark' && (
                            <div
                              className={`${classes.OOTBVariant} ${classes.darkVariant} ${
                                isSelectedVariant(theme) && classes.selected
                              }`}
                              onClick={() => handleVariantSelection(theme)}
                              onKeyDown={(e) => e.key === 'Enter' && handleVariantSelection(theme)}
                              role="button"
                              tabIndex={0}
                            >
                              <div>
                                {!theme?.styles?.isMulticolor && (
                                  <Icon
                                    kind="framedark"
                                    color="neutral-100"
                                    size="full"
                                    colours={[primary]}
                                  />
                                )}
                                {theme?.styles?.isMulticolor && (
                                  <Icon kind="framedarkmulti" color="neutral-100" size="full" />
                                )}
                              </div>
                              <p className={`${classes.typography_body_1} ${classes.text_center}`}>
                                {message(
                                  intl,
                                  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.ThemeOption.Dark'
                                )}
                              </p>
                            </div>
                          )}
                          {theme?.appearance === 'light' && (
                            <div
                              className={`${classes.OOTBVariant} ${classes.lightVariant} ${
                                isSelectedVariant(theme) && classes.selected
                              }`}
                              onClick={() => handleVariantSelection(theme)}
                              onKeyDown={(e) => e.key === 'Enter' && handleVariantSelection(theme)}
                              role="button"
                              tabIndex={0}
                            >
                              <div>
                                {!theme?.styles?.isMulticolor && (
                                  <Icon
                                    kind="framelight"
                                    color="neutral-100"
                                    size="full"
                                    colours={[primary]}
                                  />
                                )}
                                {theme?.styles?.isMulticolor && (
                                  <Icon kind="framelightmulti" color="neutral-100" size="full" />
                                )}
                              </div>
                              <p className={`${classes.typography_body_1} ${classes.text_center}`}>
                                {message(
                                  intl,
                                  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.ThemeOption.Light'
                                )}
                              </p>
                            </div>
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className={classes.sidePanelSection}>
              <p className={`${classes.subSectionHeaderText} ${classes.darkText} ${classes.mb_4}`}>
                {message(intl, 'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Colour.Title')}
              </p>
              <div>
                <div className={`${classes.dFlex} ${classes.colourSwatchGroup}`}>
                  <div className={`${classes.colourSwatch} ${classes.mt_8}`}>
                    {selectedThemeVariant?.styles?.isMulticolor && (
                      <Icon kind="multicolourswatch" color="neutral-100" size="full" />
                    )}
                    {!selectedThemeVariant?.styles?.isMulticolor && (
                      <Icon
                        kind="colourswatch"
                        color="neutral-100"
                        size="full"
                        colours={[primary]}
                      />
                    )}
                  </div>
                  <div className={`${classes.colourSwatch} ${classes.mt_8}`}>
                    <Icon
                      kind="colourswatch"
                      color="neutral-100"
                      size="full"
                      colours={[secondary]}
                    />
                  </div>
                  <div className={`${classes.colourSwatch} ${classes.mt_8}`}>
                    <Icon
                      kind="colourswatch"
                      color="neutral-100"
                      size="full"
                      colours={[background]}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.sidePanelSection}>
              <p className={`${classes.subSectionHeaderText} ${classes.darkText} ${classes.mb_4}`}>
                {message(
                  intl,
                  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Logo.Navigation.Title'
                )}
              </p>
              <p className={`${classes.description} ${classes.mb_4}`}>
                {message(
                  intl,
                  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Logo.Navigation.Description'
                )}
              </p>
              <div className={classes.mb_24}>
                <LinkButton
                  onClick={() => setShowExampleModalVariant('navLogo')}
                  label={message(intl, 'UI.Gen2.Admin.Branding.Button.SeeExample')}
                />
              </div>
              <div>
                {logo ? (
                  <LogoStrip
                    key={logo}
                    name={logo}
                    setShowSelectLogoModal={() => {
                      setShowSelectLogoModal(true);
                      setIsDoneDisabled(true);
                      setLogoPopupMode('logo');
                    }}
                    selectionCallback={handleLogoSelection}
                    logoPopupMode={logoPopupMode}
                  />
                ) : (
                  <Button
                    type="button"
                    kind="filled"
                    data-dismiss="modal"
                    leftIcon={<Icon kind="upload" />}
                    onClick={() => {
                      setShowSelectLogoModal(true);
                      setIsDoneDisabled(true);
                      setLogoPopupMode('logo');
                    }}
                  >
                    <span className={classes.selectLogoBtnText}>
                      {message(intl, 'UI.Gen2.Admin.Branding.Logo.BrandLogo.Button')}
                    </span>
                  </Button>
                )}
              </div>
            </div>

            <div className={classes.sidePanelSection}>
              <p className={`${classes.subSectionHeaderText} ${classes.darkText} ${classes.mb_4}`}>
                {message(intl, 'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Logo.Header.Title')}
              </p>
              <p className={`${classes.description} ${classes.mb_4}`}>
                {message(
                  intl,
                  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Logo.Header.Description'
                )}
              </p>
              <div className={classes.mb_24}>
                <LinkButton
                  onClick={() => setShowExampleModalVariant('headerLogo')}
                  label={message(intl, 'UI.Gen2.Admin.Branding.Button.SeeExample')}
                />
              </div>
              <div>
                {headerlogo ? (
                  <LogoStrip
                    key={headerlogo}
                    name={headerlogo}
                    setShowSelectLogoModal={() => {
                      setShowSelectLogoModal(true);
                      setIsDoneDisabled(true);
                      setLogoPopupMode('headerlogo');
                    }}
                    selectionCallback={handleLogoSelection}
                    logoPopupMode={logoPopupMode}
                  />
                ) : (
                  <Button
                    type="button"
                    kind="filled"
                    data-dismiss="modal"
                    leftIcon={<Icon kind="upload" />}
                    onClick={() => {
                      setShowSelectLogoModal(true);
                      setIsDoneDisabled(true);
                      setLogoPopupMode('headerlogo');
                    }}
                  >
                    <span className={classes.selectLogoBtnText}>
                      {message(intl, 'UI.Gen2.Admin.Branding.Logo.BrandLogo.Button')}
                    </span>
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
        {!isOOTB && (
          <>
            {isCreateMode && (
              <div className={classes.sidePanelSection}>
                <p
                  className={`${classes.subSectionHeaderText} ${classes.darkText} ${classes.mb_4}`}
                >
                  {message(intl, 'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Name.Title')}
                </p>
                <div className={classes.themeName}>
                  <InputField
                    name="basic"
                    placeholder="My Company Theme"
                    value={themeName}
                    setValue={setThemeName}
                    isRequired
                    isCritical={!!nameValidationError}
                    CriticalHelperText={
                      <span className={classes.errorMessage}>
                        <Icon kind="critical" size="xsmall" />
                        {nameValidationError}
                      </span>
                    }
                  />
                </div>
              </div>
            )}

            <div className={classes.sidePanelSection}>
              <p className={`${classes.subSectionHeaderText} ${classes.darkText} ${classes.mb_4}`}>
                {message(intl, 'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Colour.Title')}
              </p>
              <p className={`${classes.description} ${classes.mb_4}`}>
                {message(intl, 'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Colour.Description')}
              </p>
              <div className={classes.mb_24}>
                <LinkButton
                  onClick={() => setShowExampleModalVariant('navMenuColors')}
                  label={message(intl, 'UI.Gen2.Admin.Branding.Button.SeeExample')}
                />
              </div>
              <div>
                <div
                  className={`${classes.dFlex} ${classes.colourSwatchGroup} ${classes.dFlexRow}`}
                >
                  <ColorPickerTile
                    openColourPickerType={openColourPickerType}
                    type="primary"
                    handleColourChange={handleChange}
                    callBack={handleColourPickerToggle}
                    text={message(
                      intl,
                      'UI.Gen2.Admin.Branding.Theme.Modal.SideBar.Section.Color.Primary.Label'
                    )}
                    color={primary}
                    themeName={name}
                    classes={classes}
                    isEdited={!isCreateMode && isEditedColourType('primary')}
                    resetColourType={resetColourType}
                    resetTooltip={resetColorTooltip}
                  />

                  <ColorPickerTile
                    openColourPickerType={openColourPickerType}
                    type="background"
                    handleColourChange={handleChange}
                    callBack={handleColourPickerToggle}
                    text={message(
                      intl,
                      'UI.Gen2.Admin.Branding.Theme.Modal.SideBar.Section.Color.Background.Label'
                    )}
                    color={background}
                    themeName={name}
                    classes={classes}
                    isEdited={!isCreateMode && isEditedColourType('background')}
                    resetColourType={resetColourType}
                    resetTooltip={resetColorTooltip}
                  />

                  <ColorPickerTile
                    openColourPickerType={openColourPickerType}
                    type="secondary"
                    handleColourChange={handleChange}
                    callBack={handleColourPickerToggle}
                    text={message(
                      intl,
                      'UI.Gen2.Admin.Branding.Theme.Modal.SideBar.Section.Color.Secondary.Label'
                    )}
                    color={secondary}
                    themeName={name}
                    classes={classes}
                    isEdited={!isCreateMode && isEditedColourType('secondary')}
                    resetColourType={resetColourType}
                    resetTooltip={resetColorTooltip}
                  />
                </div>
              </div>
            </div>

            <div className={classes.sidePanelSection}>
              <p className={`${classes.subSectionHeaderText} ${classes.darkText} ${classes.mb_4}`}>
                {message(
                  intl,
                  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Logo.Navigation.Title'
                )}
              </p>
              <p className={`${classes.description} ${classes.mb_4}`}>
                {message(
                  intl,
                  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Logo.Navigation.Description'
                )}
              </p>
              <div className={classes.mb_24}>
                <LinkButton
                  onClick={() => setShowExampleModalVariant('navLogo')}
                  label={message(intl, 'UI.Gen2.Admin.Branding.Button.SeeExample')}
                />
              </div>
              <div>
                {logo ? (
                  <LogoStrip
                    key={logo}
                    name={logo}
                    setShowSelectLogoModal={() => {
                      setShowSelectLogoModal(true);
                      setIsDoneDisabled(true);
                      setLogoPopupMode('logo');
                    }}
                    selectionCallback={handleLogoSelection}
                    logoPopupMode={logoPopupMode}
                  />
                ) : (
                  <Button
                    type="button"
                    kind="filled"
                    data-dismiss="modal"
                    leftIcon={<Icon kind="upload" />}
                    onClick={() => {
                      setShowSelectLogoModal(true);
                      setIsDoneDisabled(true);
                      setLogoPopupMode('logo');
                    }}
                  >
                    <span className={classes.selectLogoBtnText}>
                      {message(intl, 'UI.Gen2.Admin.Branding.Logo.BrandLogo.Button')}
                    </span>
                  </Button>
                )}
              </div>
            </div>

            <div className={classes.sidePanelSection}>
              <p className={`${classes.subSectionHeaderText} ${classes.darkText} ${classes.mb_4}`}>
                {message(intl, 'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Logo.Header.Title')}
              </p>
              <p className={`${classes.description} ${classes.mb_4}`}>
                {message(
                  intl,
                  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Logo.Header.Description'
                )}
              </p>
              <div className={classes.mb_24}>
                <LinkButton
                  onClick={() => setShowExampleModalVariant('headerLogo')}
                  label={message(intl, 'UI.Gen2.Admin.Branding.Button.SeeExample')}
                />
              </div>
              <div>
                {headerlogo ? (
                  <LogoStrip
                    key={headerlogo}
                    name={headerlogo}
                    setShowSelectLogoModal={() => {
                      setShowSelectLogoModal(true);
                      setIsDoneDisabled(true);
                      setLogoPopupMode('headerlogo');
                    }}
                    selectionCallback={handleLogoSelection}
                    logoPopupMode={logoPopupMode}
                  />
                ) : (
                  <Button
                    type="button"
                    kind="filled"
                    data-dismiss="modal"
                    leftIcon={<Icon kind="upload" />}
                    onClick={() => {
                      setShowSelectLogoModal(true);
                      setIsDoneDisabled(true);
                      setLogoPopupMode('headerlogo');
                    }}
                  >
                    <span className={classes.selectLogoBtnText}>
                      {message(intl, 'UI.Gen2.Admin.Branding.Logo.BrandLogo.Button')}
                    </span>
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const footerActionBar = () => (
    <div className={classes.FooterActionBar}>
      <div className={classes.FooterActionBarExtraContent}>
        {!selectedThemeVariant?.isOOTB && !isCreateMode && (
          <Button
            type="button"
            kind="subtle"
            size="medium"
            onClick={() => handleDeleteTheme('delete', selectedThemeVariant)}
          >
            {message(
              intl,
              'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Footer.Button.Delete'
            )}
          </Button>
        )}
        <div className={classes.FooterActionBarInlineMessage} />
      </div>
      <div className={classes.FooterActionBarActionButtons}>
        {isActiveVariant(selectedThemeVariant) && !hasThemeChanged() ? (
          <p className={classes.activeThemeFooterText}>
            <Icon kind="filledstar" size="full" />{' '}
            {message(
              intl,
              'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Footer.Active.Label'
            )}
          </p>
        ) : (
          <>
            {/* <Button type="button" kind="outlined" size="medium">
              {message(
                intl,
                'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Footer.Button.Preview',
              )}
            </Button> */}
            {isCreateMode && (
              <Button
                type="button"
                kind="filled"
                size="medium"
                onClick={createThemeHandler}
                isDisabled={!isMandatoryFieldEmpty() || nameValidationError || !themeName}
              >
                {message(
                  intl,
                  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Footer.Button.Create'
                )}
              </Button>
            )}
            {hasThemeChanged() && (
              <Button type="button" kind="filled" size="medium" onClick={updateThemeHandler}>
                {message(
                  intl,
                  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Footer.Button.Save'
                )}
              </Button>
            )}
            {!hasThemeChanged() && !isCreateMode && !isActiveVariant(selectedThemeVariant) && (
              <Button
                type="button"
                kind="filled"
                size="medium"
                leftIcon={<Icon kind="staroutline" />}
                onClick={activateThemeHandler}
              >
                {message(
                  intl,
                  'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Footer.Button.Activate'
                )}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );

  const allThemesArray = Object.values(allThemes);

  const { customThemes, saviyntThemes } = allThemesArray.reduce(
    (themes, theme) => {
      const themeExtract = getDisplayedTheme(theme);
      if (themeExtract?.isOOTB) {
        themes.saviyntThemes[themeExtract.name] = theme;
      } else {
        themes.customThemes[themeExtract.name] = theme;
      }
      return themes;
    },
    { customThemes: {}, saviyntThemes: {} }
  );

  const customThemesCount = Object.keys(customThemes).length;
  const saviyntThemesCount = Object.keys(saviyntThemes).length;

  const handleUploadFaviconResponse = (fileName, fileObj) => {
    setUploadedFaviconFileName(fileName);
    setShowFaviconUploaded(true);
    setFaviconErrorMessage('');
    setToast(true);
    setUploadedfavIconFile([
      {
        fileName: fileObj?.name,
        fileSize: fileObj?.size,
        errorMessage: '',
      },
    ]);
  };

  const handleUploadLoginLogoutLogoResponse = (fileName, fileObj) => {
    setUploadedFaviconFileName(fileName);
    setShowLoginLogoutLogoUploaded(p => !p);
    setFaviconErrorMessage('');
    setToast(true);
    setUploadedLoginLogoutLogoFile([
      {
        fileName: fileObj?.name,
        fileSize: fileObj?.size,
        errorMessage: '',
      },
    ]);
  };

  const handleUploadLogoResponse = (fileName, fileObj) => {
    setUploadedLogoFileName(fileName);
    setShowLogoUploaded(true);
    setLogoErrorMessage('');
    setToast(true);
  };

  const handleFaviconFailureResponse = (response) => {
    const { error } = response;
    if (error) setFaviconErrorMessage(error[0]?.defaultMessage);
  };

  const handleLoginLogoutFailureResponse = (response) => {
    const { error } = response;
    //todo create a separate holder for login logout logo
    if (error) setFaviconErrorMessage(error[0]?.defaultMessage);
  };

  const handleLogoFailureResponse = (response) => {
    const { error } = response;
    if (error) setLogoErrorMessage(error[0]?.defaultMessage);
  };

  const [selectedNavLogo, setSelectedNavLogo] = useState();
  const [selectedHeaderLogo, setSelectedHeaderLogo] = useState();

  const handleLogoCompleteSelection = () => {
    const selectedLogoName = logoPopupMode === 'logo' ? selectedNavLogo : selectedHeaderLogo;
    handleChange(selectedThemeVariant, 'logos', logoPopupMode)(selectedLogoName);
  };

  const handleLogoSelection = (logoname, type) => {
    if (type === 'logo') {
      setSelectedNavLogo(logoname);
    } else if (type === 'headerlogo') {
      setSelectedHeaderLogo(logoname);
    }
  };

  useEffect(() => {
    const fetchLogos = async () => {
      if (logos && logos.length > 0) {
        for (const item of logos) {
          await dispatch(getLogoToShow({ name: item?.name }));
        }
      }
    };

    fetchLogos();
  }, [logos]);

  /*
   * Component: SelectLogoModalContent
   * Purpose: displays modal with 2 tabs, Library and  upload.
   */
  const count = logos?.length || 0;
  const SELECT_LOGO_TABS_DATA = [
    {
      value: 'tab_library',
      label: `${message(
        intl,
        'UI.Gen2.Admin.Branding.Theme.Modal.LogoUpload.Tabs.Library.Label'
      )} (${count})`,
    },
    {
      value: 'tab_upload',
      label: message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.LogoUpload.Tabs.Upload.Label'),
    },
  ];
  const initialActiveTabLogo = SELECT_LOGO_TABS_DATA[0].value;
  const [activeLogoTab, setLogoActiveTab] = useState(initialActiveTabLogo);

  useEffect(() => {
    if (uploadLogoSuccess) {
      setLogoActiveTab('tab_library');
      setCallUploadLogoAPI(false);
      setIsDoneDisabled(true);
    } else {
      setCallUploadLogoAPI(true);
      setLogoActiveTab('tab_upload');
      setIsDoneDisabled(false);
    }
  }, [uploadLogoSuccess]);

  useEffect(() => {
    if (activeLogoTab === 'tab_upload') {
      setUploadedLogoFile([]);
      setIsDoneDisabled(true);
    }
  }, [activeLogoTab]);

  const selectLogoModalContent = () => {
    const handleTabChange = (value) => {
      setLogoActiveTab(value);
      if (value === 'tab_upload') setCallUploadLogoAPI(true);
      else setCallUploadLogoAPI(false);
    };

    return (
      <div className={classes.brandingSelectLogo}>
        <TabGroup value={activeLogoTab} onChange={handleTabChange}>
          {SELECT_LOGO_TABS_DATA.map((_tab) => (
            <TabItem key={_tab.value} label={_tab.label} value={_tab.value} />
          ))}
        </TabGroup>
        <div className={classes.contentSection}>
          <div className={`${classes.tabsPanel} ${classes.responsiveTabsPanel}`}>
            <TabContent className={classes.libraryTab} value={activeLogoTab} tabValue="tab_library">
              <div className={classes.displayedLogos}>
                {logos && logos?.length ? (
                  logos?.map((logo) => (
                    <LogoCard
                      key={logo?.name}
                      name={logo?.name}
                      loadImage={loadImage}
                      setIsDoneDisabled={setIsDoneDisabled}
                      selectionCallback={handleLogoSelection}
                      logoPopupMode={logoPopupMode}
                      selectedLogoName={
                        logoPopupMode === 'logo' ? selectedNavLogo : selectedHeaderLogo
                      }
                    />
                  ))
                ) : (
                  <div className={classes.emptyLogoElementContainer}>
                    <div className={classes.emptyLogoContainer} />
                    <p className={classes.noLogoUploadedText}>
                      {message(
                        intl,
                        'UI.Gen2.Admin.Branding.Theme.Modal.LogoUpload.Tabs.Library.Placeholder'
                      )}
                    </p>
                  </div>
                )}
              </div>
            </TabContent>
            <TabContent
              className={classes.uploadLogoTab}
              value={activeLogoTab}
              tabValue="tab_upload"
            >
              {count && count >= 20 ? (
                <div className={classes.libraryFullContainer}>
                  <div className={classes.libraryFullWarning} />
                  <div className={classes.libraryTextFull}>
                    {message(
                      intl,
                      'UI.Gen2.Admin.Branding.Theme.Modal.LogoUpload.Tabs.Upload.Full.Title'
                    )}
                  </div>
                  <p className={classes.warningDescription}>
                    {message(
                      intl,
                      'UI.Gen2.Admin.Branding.Theme.Modal.LogoUpload.Tabs.Upload.Full.Description'
                    )}
                  </p>
                </div>
              ) : (
                <div className={classes.favIconUploadFrame}>
                  <Upload
                    kind="dropzone"
                    isMultiple={false}
                    onFileSelect={(acceptedFiles) => {
                      setUploadedLogoFileDetails(acceptedFiles[0]);
                      setUploadedLogoFileName(acceptedFiles[0]?.name);
                      setUploadedLogoFile([
                        {
                          fileName: acceptedFiles[0]?.name,
                          fileSize: acceptedFiles[0]?.size,
                          errorMessage: '',
                        },
                      ]);
                    }}
                    onFileDelete={() => setUploadedLogoFile([])}
                    onFileReupload={() => setUploadedLogoFile([])}
                    uploadedFiles={uploadedLogoFile}
                    acceptTypes=".png,.jpg,.svg"
                  />
                </div>
              )}
            </TabContent>
          </div>
        </div>
      </div>
    );
  };

  const onFavIconDeleteClick = () => {
    setShowFavIconContainer(false);
    setUploadedfavIconFile([]);
  };

  const onLoginLogoutLogoEdit = () => {
    setShowLoginLogoutLogoContainer(false);
    setUploadedLoginLogoutLogoFile([]);
  };

  return (
    <div className={classes.container}>
      <section className={classes.brandingContainer}>
        <div className={`${classes.sectionPanel} ${isSidePanelOpen && classes.sidePanelOpen}`}>
          <header className={classes.pageHeader}>
            <h2 className={classes.header}>{message(intl, 'UI.Gen2.Admin.Branding.Header')}</h2>
            <p className={classes.description}>
              {message(intl, 'UI.Gen2.Admin.Branding.Description')}
            </p>
            <TabGroup value={activeTab} onChange={handleTabChange}>
              {TABS_DATA.map((_tab) => (
                <TabItem key={_tab.value} label={message(intl, _tab.label)} value={_tab.value} />
              ))}
            </TabGroup>
          </header>{' '}
          <div className={classes.contentSection}>
            <div className={`${classes.tabsPanel} ${classes.tabsPanelWidthByDesign}`}>
              <TabContent value={activeTab} tabValue="bc_tab_logos">
                <div className={classes.menuContent}>
                  <div className={classes.textpadding}>
                    {/* favIcon section */}
                    <div className={classes.faviconFrame}>
                      <h3 className={classes.frameHeaderText}>
                        {message(intl, 'UI.Gen2.Admin.Branding.Logo.FavIcon.Header')}
                      </h3>
                      <div className={classes.textWithSeeExamplesLink}>
                        <p className={classes.frameDescriptionText}>
                          {message(intl, 'UI.Gen2.Admin.Branding.Logo.FavIcon.Description')}
                        </p>
                        <div className={classes.mb_24}>
                          <LinkButton
                            onClick={() => setShowExampleModalVariant('favIcon')}
                            label={message(intl, 'UI.Gen2.Admin.Branding.Button.SeeExample')}
                          />
                        </div>
                      </div>
                      <div className={classes.favIconUploadFrame}>
                        {showFavIconContainer || uploadedFavIconFile?.length ? (
                          <div className={classes.favIconContainer}>
                            <div className={classes.favIconImage}>
                              <AppLogo src={favIconImage} isSideNav />
                            </div>
                            <div className={classes.favIconName}>
                              {uploadedFavIconFile?.length
                                ? uploadedFaviconFileName
                                : favIconFileName}
                            </div>
                            <div
                              className={classes.favIconDelete}
                              onClick={onFavIconDeleteClick}
                            ></div>
                          </div>
                        ) : (
                          <Upload
                            kind="dropzone"
                            isMultiple={false}
                            onFileSelect={(acceptedFiles) => {
                              dispatch(
                                uploadFavIcon(
                                  favIconUploadUrl,
                                  acceptedFiles[0],
                                  handleUploadFaviconResponse,
                                  handleFaviconFailureResponse
                                )
                              );
                            }}
                            onFileDelete={() => setUploadedfavIconFile([])}
                            onFileReupload={() => setUploadedfavIconFile([])}
                            uploadedFiles={uploadedFavIconFile}
                            acceptTypes=".png"
                            fileTypesAllowedText="Only .PNG Max 100 KB. 16x16"
                          />
                        )}
                      </div>
                    </div>
                    {/* logos section */}
                    <div className={classes.brandLogoFrame}>
                      <h3 className={classes.frameHeaderText}>
                        {message(intl, 'UI.Gen2.Admin.Branding.Logo.LoginLogout.Header')}
                      </h3>
                      <div className={classes.textWithSeeExamplesLink}>
                        <p className={classes.frameDescriptionText}>
                          {message(intl, 'UI.Gen2.Admin.Branding.Logo.LoginLogout.Description')}
                        </p>
                        <div className={classes.mb_24}>
                          <LinkButton
                            onClick={() => setShowExampleModalVariant('brandLogo')}
                            label={message(intl, 'UI.Gen2.Admin.Branding.Button.SeeExample')}
                          />
                        </div>
                      </div>
                      <div className={classes.loginLogoutUploadFrame}>
                        {showLoginLogoutLogoContainer || uploadedLoginLogoutLogoFile?.length ? (
                          <div className={classes.loginLogoutUploadFrameContainer}>
                            <div className={classes.loginLogoutUploadFrameImage}>
                              <AppLogo src={loginLogoutLogoImage} isSideNav />
                            </div>
                            <div className={classes.loginLogoutLogoName}>
                              {uploadedLoginLogoutLogoFile?.length
                                ? uploadedLoginLogoutFileName
                                : 'SaviyntR_White-Original.png'}
                            </div>
                            <div
                              className={classes.favIconDelete}
                              onClick={onLoginLogoutLogoEdit}
                            ></div>
                          </div>
                        ) : (
                          <Upload
                            kind="dropzone"
                            isMultiple={false}
                            onFileSelect={(acceptedFiles) => {
                              let myNewFile = new File(
                                [acceptedFiles[0]],
                                'SaviyntR_White-Original.png'
                              );
                              dispatch(
                                uploadAsFile(
                                  imageUploadUrl,
                                  myNewFile,
                                  '/app',
                                  handleUploadLoginLogoutLogoResponse,
                                  handleLoginLogoutFailureResponse
                                )
                              );
                            }}
                            onFileDelete={() => setUploadedLoginLogoutLogoFile([])}
                            onFileReupload={() => setUploadedLoginLogoutLogoFile([])}
                            uploadedFiles={uploadedLoginLogoutLogoFile}
                            acceptTypes=".png"
                            fileTypesAllowedText="Only .PNG Max 1 MB"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </TabContent>
              <TabContent value={activeTab} tabValue="bc_tab_themes">
                <div>
                  <div>
                    {customThemesCount > 0 && (
                      <div className={classes.themeGroup}>
                        <div
                          className={`${classes.subSectionHeader} ${classes.dFlex} ${classes.dFlexSpaceBetween} ${classes.dFlexAlignCenter}`}
                        >
                          <h5 className={`${classes.subSectionHeaderText} ${classes.m_0}`}>
                            {`${message(intl, 'UI.Gen2.Admin.Branding.Theme.Type.Custom')} ${
                              customThemesCount && `(${customThemesCount})`
                            }`}
                          </h5>
                          <CreateThemeButton />
                        </div>
                        <div className={classes.themeGrid}>
                          {Object.entries(customThemes).map(([themeName, themes]) => (
                            <ThemeCard
                              key={themeName}
                              data={themes}
                              handleSelection={themeSelection}
                              isSelected={
                                selectedTheme &&
                                getDisplayedTheme(selectedTheme)?.name ===
                                  getDisplayedTheme(themes)?.name
                              }
                              activeTheme={activeTheme}
                              activeLabel={message(
                                intl,
                                'UI.Gen2.Admin.Branding.Theme.ThemeGrid.Active.Tooltip'
                              )}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    {saviyntThemesCount > 0 && (
                      <div>
                        <div
                          className={`${classes.subSectionHeader} ${classes.dFlex} ${classes.dFlexSpaceBetween} ${classes.dFlexAlignCenter}`}
                        >
                          <h5 className={`${classes.subSectionHeaderText} ${classes.m_0}`}>
                            {`${message(intl, 'UI.Gen2.Admin.Branding.Theme.Type.OOTB')} ${
                              saviyntThemesCount && `(${saviyntThemesCount})`
                            }`}
                          </h5>
                          {!(customThemesCount > 0) && <CreateThemeButton />}
                        </div>
                        <div className={classes.themeGrid}>
                          {Object.entries(saviyntThemes).map(([themeName, themes]) => (
                            <ThemeCard
                              key={themeName}
                              data={themes}
                              handleSelection={themeSelection}
                              isSelected={
                                selectedTheme &&
                                getDisplayedTheme(selectedTheme)?.name ===
                                  getDisplayedTheme(themes)?.name
                              }
                              activeTheme={activeTheme}
                              activeLabel={message(
                                intl,
                                'UI.Gen2.Admin.Branding.Theme.ThemeGrid.Active.Tooltip'
                              )}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </TabContent>
              <TabContent value={activeTab} tabValue="bc_tab_images">
                Item Three Content
              </TabContent>
            </div>
          </div>
        </div>
        {showSelectLogoModal && (
          <ModalIsland
            key="showSelectLogoModal"
            kind="default"
            onClose={() => {
              setShowSelectLogoModal(false);
              setIsDoneDisabled(true);
            }}
            className="showSelectLogoModal"
            isOpen={showSelectLogoModal}
            title={message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.SelectLogo.Title')}
            contentBody={selectLogoModalContent()}
            FooterActionBarComp={
              <FooterActionBar size="large">
                <div
                  style={{ display: 'flex', gap: '30.5rem' }}
                  className={`${classes.selectLogoFooter} FooterActionBar-extraContent`}
                >
                  <Button
                    kind="subtle"
                    size="medium"
                    onClick={() => {
                      setIsDoneDisabled(true);
                      setShowSelectLogoModal(false);
                    }}
                  >
                    {message(
                      intl,
                      'UI.Gen2.Admin.Branding.Theme.Modal.Alert.SelectLogo.Button.Secondary'
                    )}
                  </Button>
                  <Button
                    kind="filled"
                    size="medium"
                    isDisabled={isDoneDisabled}
                    onClick={() => {
                      if (!callUploadLogoAPI) {
                        handleLogoCompleteSelection();
                        setShowSelectLogoModal(false);
                      } else {
                        dispatch(
                          uploadLogo(
                            logoUploadUrl,
                            uploadedLogoFileDetails,
                            handleUploadLogoResponse,
                            handleLogoFailureResponse
                          )
                        );
                      }
                    }}
                  >
                    {message(
                      intl,
                      'UI.Gen2.Admin.Branding.Theme.Modal.Alert.SelectLogo.Button.Primary'
                    )}
                  </Button>
                </div>
              </FooterActionBar>
            }
          />
        )}
        <SidePanel
          header={{
            title: isCreateMode
              ? message(intl, 'UI.Gen2.Admin.Branding.Theme.SideBar.Section.Theme.Header')
              : getDisplayedTheme(selectedTheme)?.styles?.displayName,
            description: '',
          }}
          layout={contentLayout()}
          isOpen={isSidePanelOpen}
          onClose={() => handleSideBarMode(false)}
          footerActionBarChildren={footerActionBar()}
        />
        {showIslandModal && modalType && (
          <DynamicModal
            isOpen={showIslandModal}
            onClose={() => setShowIslandModal(false)}
            modalType={modalType}
            selectedVariant={selectedThemeVariant}
            onPrimaryAction={primaryAction || MODAL_ACTIONS[modalType].primaryAction}
            onSecondaryAction={
              MODAL_ACTIONS[modalType]?.secondaryAction
                ? MODAL_ACTIONS[modalType].secondaryAction
                : () => {}
            }
            intl={intl}
          />
        )}
        {showThemeCreationAlert && (
          <ModalIsland
            key="showThemeCreationAlert"
            kind="default"
            onClose={() => setShowThemeCreationAlert(false)}
            isOpen={showThemeCreationAlert}
            title={message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Activate.Title')}
            contentBody={
              <p className={classes.themeCreateAlert}>
                {message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Activate.Description')}
              </p>
            }
            FooterActionBarComp={
              <FooterActionBar size="large">
                <span />
                <span className={`${classes.dFlex} ${classes.alertFooter}`}>
                  <Button
                    kind="outlined"
                    size="medium"
                    onClick={() => setShowThemeCreationAlert(false)}
                    className={classes.mr_8}
                  >
                    {message(
                      intl,
                      'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Activate.Button.Secondary'
                    )}
                  </Button>
                  <Button kind="filled" size="medium" onClick={activateThemeHandler}>
                    {message(
                      intl,
                      'UI.Gen2.Admin.Branding.Theme.Modal.Alert.Activate.Button.Primary'
                    )}
                  </Button>
                </span>
              </FooterActionBar>
            }
          />
        )}
        {showExampleModalVariant && (
          <ModalIsland
            key="showExampleModalVariant"
            kind="default"
            onClose={() => setShowExampleModalVariant('')}
            isOpen={showExampleModalVariant}
            title={message(intl, MODAL_TYPES[showExampleModalVariant]?.title)}
            contentBody={<ExampleModals variant={showExampleModalVariant} intl={intl} />}
            FooterActionBarComp={
              <FooterActionBar size="large">
                <span />
                <span className={`${classes.dFlex} ${classes.alertFooter}`}>
                  <Button
                    kind="filled"
                    size="medium"
                    onClick={() => setShowExampleModalVariant('')}
                  >
                    {message(intl, 'UI.Gen2.Admin.Branding.Theme.Modal.Example.Footer.Button')}
                  </Button>
                </span>
              </FooterActionBar>
            }
          />
        )}
        <Snackbar
          open={showToast}
          onSnackBarClose={() => setToast(false)}
          message={intl.formatMessage({
            id: 'UIBrandConfig.ManageImages.Upload.SuccessMessage',
          })}
          verticalPosition="bottom"
          horizontalPosition="left"
        />
      </section>
    </div>
  );
};

BrandingConfig.propTypes = {
  location: PropTypes.shape({}).isRequired,
  intl: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(BrandingConfig);
