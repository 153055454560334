import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ButtonIcon from '../ButtonIcon/ButtonIcon';
import Icon from '../Icon/Icon';

import './InlineMessage.css';

const SIZES = {
  small: 'small',
  medium: 'medium',
};
const KINDS = {
  plain: 'plain',
  background: 'background',
};
const COLOR_THEMES = {
  neutral: 'neutral', // 'black'
  critical: 'critical', // 'red'
  warning: 'warning', // 'yellow'
  success: 'success', // 'green'
  info: 'info', // 'blue'
  disabled: 'disabled', // 'gray'
};

function InlineMessage({
  kind,
  size,
  leftIcon,
  text,
  colorTheme,
  linkComponent,
  isDismissible,
  isVisible,
  onDismiss,
}) {
  if (!isVisible) {
    return null;
  }

  const classes = classnames(
    'InlineMessage',
    colorTheme && `InlineMessage--${colorTheme}`,
    `InlineMessage--${kind}`,
    `InlineMessage--${size}`
  );

  return (
    <div role='status' className={classes}>
      <div className='InlineMessage-leftContent'>
        <div className='InlineMessage-leftIconContainer'>{leftIcon}</div>
        <div className='InlineMessage-textContainer'>{text}</div>
        {linkComponent && (
          <div className='InlineMessage-linkContainer'>{linkComponent}</div>
        )}
      </div>
      {isDismissible && (
        <ButtonIcon
          kind='ghost'
          size='small'
          icon={<Icon kind='close' color='neutral-100' size='medium' />}
          onClick={onDismiss}
        />
      )}
    </div>
  );
}

InlineMessage.propTypes = {
  kind: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  leftIcon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  colorTheme: PropTypes.oneOf(Object.values(COLOR_THEMES)),
  linkComponent: PropTypes.element,
  isDismissible: PropTypes.bool,
  isVisible: PropTypes.bool,
  onDismiss: PropTypes.func,
};
InlineMessage.defaultProps = {
  kind: KINDS.plain,
  size: SIZES.medium,
  colorTheme: null,
  linkComponent: null,
  isDismissible: false,
  isVisible: true,
  onDismiss: () => {},
};

export default InlineMessage;
