import './SpvDatabase.css';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { usePlatformLabel } from '../../../../../../hooks/use-labels';
import { ModuleDetails } from '../../../../../../models/PamModels';
import { LABELS, TITLES } from '../../../../constants';
import SpvSection from '../../../SpvSection/SpvSection';

function SpvDatabase({ data, dataTestId, className }) {
  const classes = classnames('SpvDatabase', className);

  const cloudProvider = data.connectionType?.toUpperCase();

  const summaryHasFields =
    data.platformType || data.description || data.customproperty10;

  const systemDetailsHasFields =
    data.customproperty5 ||
    data.customproperty7 ||
    data.customproperty30 ||
    data.displayName ||
    data.customproperty17;

  return (
    <div className={classes} data-testid={dataTestId}>
      {summaryHasFields && (
        <SpvSection
          title={TITLES.SUMMARY}
          fields={[
            {
              label: LABELS.PLATFORM,
              value: data.platformType,
            },
            {
              label: LABELS.DESCRIPTION,
              value: data.description,
            },
            {
              label: LABELS.LOCATION,
              value: data.customproperty10,
            },
          ]}
        />
      )}
      {systemDetailsHasFields && (
        <SpvSection
          title={TITLES.SYSTEM_DETAILS}
          fields={[
            {
              label: LABELS.DATABASE_NAME,
              value: data.customproperty7,
            },
            {
              label: LABELS.ENVIRONMENT,
              value: data.customproperty5,
            },
            {
              label: LABELS.INSTANCE,
              value: data.customproperty30,
            },
            {
              // TODO: Temp mapping; real mapped field unknown; needs update
              label: LABELS.RDS_TYPE,
              value: data.displayName,
            },
            {
              label: usePlatformLabel(cloudProvider),
              value: data.customproperty17,
            },
          ]}
          className='SpvDatabase-section'
        />
      )}
    </div>
  );
}

SpvDatabase.propTypes = {
  data: ModuleDetails,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

SpvDatabase.defaultProps = {
  data: null,
  dataTestId: null,
  className: null,
};

export default SpvDatabase;
