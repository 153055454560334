import { Cookies } from 'react-cookie';

import HttpService from '../../misc/HttpService/HttpService';
import getAccountDetailsApiUrl from './urls/getAccountDetailsApiUrl';
import getAccountsApiUrl from './urls/getAccountsApiUrl';
import getAccountTypesApiUrl from './urls/getAccountTypesApiUrl';
import getAssetTypeApiUrl from './urls/getAssetTypeApiUrl';
import getCheckInApiUrl from './urls/getCheckInApiUrl';
import getEndpointDetailsApiUrl from './urls/getEndpointDetailsApiUrl';
import getEndpointsApiUrl from './urls/getEndpointsApiUrl';
import getListRowApiUrl from './urls/getListRowApiUrl';
import getPlatformTypeApiUrl from './urls/getPlatformTypeApiUrl';
import getRequestDetailsUrl from './urls/getRequestDetailsUrl';
import getSessionDetailsApiUrl from './urls/getSessionDetailsApiUrl';
import getSessionsAccountScheduleUrl from './urls/getSessionsAccountScheduleUrl';
import postCheckOutCredentialRequestUrl from './urls/postCheckOutCredentialRequestUrl';

const cookies = new Cookies();

export const getPlatformTypeApi = () =>
  HttpService.get(getPlatformTypeApiUrl()).then((response) => response);

export const getAssetTypeApi = () =>
  HttpService.get(getAssetTypeApiUrl()).then((response) => response);

export const getAccountTypesApi = () =>
  HttpService.get(getAccountTypesApiUrl()).then((response) => response);

export const getAccountDetailsApi = (accountKey) =>
  HttpService.get(getAccountDetailsApiUrl(accountKey)).then(
    (response) => response
  );

export const getListRowApi = () =>
  HttpService.post(getListRowApiUrl(), {}).then((response) => response);

export const getEndpointsApi = (request, errorCallback) =>
  HttpService.post(getEndpointsApiUrl(), request, errorCallback);

export const getEndpointDetailsApi = (endpointKey) =>
  HttpService.get(getEndpointDetailsApiUrl(endpointKey)).then(
    (response) => response
  );

export const getListRowApiFilterAndSearch = (
  platformTypes,
  assetTypes,
  search
) => {
  const filterBy = {};

  if (platformTypes.length > 0) {
    filterBy.platformTypes = platformTypes;
  }

  if (assetTypes.length > 0) {
    filterBy.assetTypes = assetTypes;
  }

  return HttpService.post(getListRowApiUrl(), {
    filterBy,
    findBy: search,
  }).then((response) => response);
};

export const getAccountListApi = (listKey, pamType) =>
  HttpService.post(getAccountsApiUrl(), { filterBy: { endpointKeys: [listKey], pamType }, }).then((response) => response);

export const getCheckInApi = (row) =>
  HttpService.post(getCheckInApiUrl(), {
    endSessionModels: [
      {
        requestAccessKey: row.requestAccessKey,
        accountName: row.accountName,
        userName: cookies.get('user_name'),
        resourceId: row.endpointDisplayName,
        removeaccess: true,
      },
    ],
  }).then((response) => response);

export const getSessionDetailsApi = (requestAccessKey, userKey) =>
  HttpService.get(getSessionDetailsApiUrl(requestAccessKey, userKey)).then(
    (response) => response
  );

export const getRequestUploadMetaDataApi = (mockData) =>
  // for now, simply return a promise that resolves with the mock data
  new Promise((resolve) => {
    setTimeout(() => resolve(mockData), 300);
  });

export const getSessionsAccountSchedule = (accountKey, fromDate, toDate) =>
  HttpService.post(getSessionsAccountScheduleUrl(accountKey), {
    fromDate,
    toDate,
  }).then((response) => response);

export const postCheckOutCredentialRequest = (request) =>
  HttpService.post(postCheckOutCredentialRequestUrl(), request).then(
    (response) => response
  );

export const getRequestDetails = (userName, requestId) =>
  HttpService.get(getRequestDetailsUrl(userName, requestId)).then(
    (response) => response
  );
