import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../../Icon/Icon';
import { SIZES } from '../constants';

import './CheckboxCore.css';

function CheckboxCore({
  name,
  size,
  isChecked,
  isDisabled,
  isIndeterminate,
  handleChange,
  tabIndex,
  dataTestId,
}) {
  let checkboxContent;
  let classes = classnames(
    'Checkbox-icon',
    `Checkbox-icon--${size}`,
    isChecked && 'Checkbox-icon--checked',
    isDisabled && 'Checkbox-icon--disabled',
    isIndeterminate && 'Checkbox-icon--indeterminate'
  );

  if (isIndeterminate && isDisabled) {
    checkboxContent = (
      <Icon
        kind='CheckboxIndeterminateDisabled'
        color={null}
        className={classes}
      />
    );
  } else if (isIndeterminate) {
    checkboxContent = (
      <Icon kind='CheckboxIndeterminate' color={null} className={classes} />
    );
  } else if (isDisabled && isChecked) {
    checkboxContent = (
      <Icon kind='CheckboxCheckedDisabled' color={null} className={classes} />
    );
  } else if (isDisabled) {
    checkboxContent = (
      <Icon kind='CheckboxDisabled' color={null} className={classes} />
    );
  } else if (isChecked) {
    checkboxContent = (
      <Icon kind='CheckboxChecked' color={null} className={classes} />
    );
  } else {
    checkboxContent = (
      <Icon
        kind='Checkbox'
        color={null}
        className={(classes += ' Checkbox-icon--default')}
      />
    );
  }

  const isControlledProps =
    isChecked !== undefined
      ? { checked: isChecked, onChange: handleChange }
      : { defaultChecked: isChecked };

  return (
    <div className='Checkbox--core'>
      <input
        type='checkbox'
        name={name}
        disabled={isDisabled}
        className='Checkbox-input'
        data-testid={dataTestId}
        tabIndex={isDisabled ? '-1' : tabIndex}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...isControlledProps}
      />
      {checkboxContent}

      {/* hover and active SVGs only needed when isChecked, isDisabled,
      and isDeterminate are all false */}
      {!isChecked && !isDisabled && !isIndeterminate && (
        <>
          <Icon
            kind='CheckboxHover'
            color={null}
            className={classnames(
              'Checkbox-icon',
              'Checkbox-icon--hover',
              `Checkbox-icon--${size}`
            )}
          />

          <Icon
            kind='CheckboxActive'
            color={null}
            className={classnames(
              'Checkbox-icon',
              'Checkbox-icon--active',
              `Checkbox-icon--${size}`
            )}
          />
        </>
      )}
    </div>
  );
}

CheckboxCore.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf([SIZES.small, SIZES.medium, SIZES.large]),
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  handleChange: PropTypes.func,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataTestId: PropTypes.string,
};

CheckboxCore.defaultProps = {
  name: null,
  size: SIZES.medium,
  isChecked: undefined,
  isDisabled: false,
  isIndeterminate: false,
  handleChange: null,
  tabIndex: '0',
  dataTestId: '',
};

export default CheckboxCore;
