import React, { useState } from 'react';

import Checkbox from '../components/Checkbox/Checkbox';

function useCheckboxGroup(checkboxConfigs) {
  const [checkboxStates, setCheckboxStates] = useState(checkboxConfigs);

  const handleChange = (event) => {
    const { name, checked } = event.target;

    setCheckboxStates((prevState) =>
      prevState.map((checkbox) =>
        checkbox.name === name ? { ...checkbox, isChecked: checked } : checkbox
      )
    );
  };

  const checkboxes = checkboxStates.map((checkbox) => (
    <Checkbox
      key={checkbox.name}
      name={checkbox.name}
      dataTestId={checkbox.name}
      isChecked={checkbox.isChecked}
      onChange={handleChange}
      label={checkbox.label}
      isDisabled={checkbox.isDisabled}
    />
  ));

  return { checkboxes, handleChange };
}

export { useCheckboxGroup };
