import { getCurrentDate } from '@saviynt/common';
import { Box, Button } from '@saviynt/design-system';
import PropTypes from 'prop-types';
import React from 'react';

import {
  SECONDS_IN_FIVE_MINUTES,
  SECONDS_PER_365_DAYS,
} from '../../../../../utilities/timeConstants';

function DurationContinueButton({
  isNowTab,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isDurationCustom,
  setDurationChipValue,
  customDurationInputValue,
  durationChipValue,
  setDurationContinue,
  isTimeBlocked,
}) {
  const renderContinueButton = () => {
    // Minimum time for a request is 5mins
    if (customDurationInputValue && durationChipValue < SECONDS_IN_FIVE_MINUTES)
      return false;

    if (
      isNowTab &&
      isDurationCustom &&
      customDurationInputValue &&
      !isTimeBlocked
    )
      return true;

    if (!isNowTab && startDate && endDate && !isTimeBlocked) return true;

    return false;
  };

  const handleContinueButton = () => {
    const isFutureChipSelectedWithDates =
      !isNowTab &&
      (durationChipValue || isDurationCustom) &&
      startDate &&
      endDate;

    if (durationChipValue > SECONDS_PER_365_DAYS) {
      setDurationChipValue(SECONDS_PER_365_DAYS);
    }

    if (isNowTab && isDurationCustom) {
      setStartDate(getCurrentDate());
      setEndDate(getCurrentDate(durationChipValue));
      setDurationContinue(true);
    }

    if (isFutureChipSelectedWithDates) {
      setDurationContinue(true);
    }
  };

  if (renderContinueButton()) {
    return (
      <Box className='TimeAccessDuration-continueButton'>
        <Button
          type='button'
          kind='filled'
          onClick={() => handleContinueButton()}>
          Continue
        </Button>
      </Box>
    );
  }

  return null;
}

DurationContinueButton.propTypes = {
  isNowTab: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(Date),
  setEndDate: PropTypes.func.isRequired,
  isDurationCustom: PropTypes.bool.isRequired,
  setDurationChipValue: PropTypes.func.isRequired,
  customDurationInputValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  durationChipValue: PropTypes.number,
  setDurationContinue: PropTypes.func.isRequired,
  isTimeBlocked: PropTypes.bool.isRequired,
};

DurationContinueButton.defaultProps = {
  startDate: null,
  endDate: null,
  customDurationInputValue: '',
  durationChipValue: null,
};

export default DurationContinueButton;
