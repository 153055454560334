import { createStore } from 'redux-dynamic-modules';
import { getSagaExtension } from 'redux-dynamic-modules-saga';
import throttle from 'lodash/throttle';
import { getThunkExtension } from 'redux-dynamic-modules-thunk';
import { routerReducer } from 'react-router-redux';
import logsReducer from 'ui-admin/src/reducers';
import { CredentialRequestFormReducer, PrivilegedAccessReducer, requestSubmittedDetailsReducer } from '@saviynt/pam';
import LoadingReducer from './Loading/Loading-reducer';
import ResponseMessageHandlingReducer from './ResponseHandler/ResponseHandler-reducer';
import LoginReducer from '../features/MainLayout/Login/LoginReducer';
import loginSaga from '../features/MainLayout/Login/LoginSagas';
import RequestHomeReducer from '../features/MainLayout/RequestHome/redux/requestHome-reducer';
import RequestHomeSaga from '../features/MainLayout/RequestHome/redux/requestHome-saga';
import AdminReducer from '../features/AdminHome/redux/adminHome-reducer';
// TODO: Need to remove this no-cycle rule
// eslint-disable-next-line import/no-cycle
import adminHomeSaga from '../features/AdminHome/redux/adminHome-saga';
import AppReducer from '../reducers/AppReducer';
import ProfileReducer from '../reducers/ProfileReducer';
import NotificationReducer from '../reducers/NotificationReducer';

const saveApplicationRequestStateToStorage = (keyName, state) => {
  try {
    if (state.applicationRequest) {
      const serializedState = JSON.stringify(state.applicationRequest);

      localStorage.setItem('ar', serializedState);
    }

    if (state.controlCenter) {
      const serializedControlCenterState =
        Object.keys(state.controlCenter.initialLoad.sessionDetails).length > 0
          ? JSON.stringify(state.controlCenter)
          : localStorage.getItem('cc');

      localStorage.setItem('cc', serializedControlCenterState);
    }

    // if (state.adminHome) {
    //   const serializedUIConfigState = JSON.stringify(state.adminHome);
    //   localStorage.setItem('uiConfig', serializedUIConfigState);
    // }
    localStorage.setItem('storedTime', new Date());
  } catch (e) {
    // Ignore Error for now
  }
};

const defaultModule = {
  id: 'default',
  retained: true,
  reducerMap: {
    user: LoginReducer,
    loader: LoadingReducer,
    message: ResponseMessageHandlingReducer,
    // mfa: Mfa,
    routing: routerReducer,
    requestHome: RequestHomeReducer,
    adminHome: AdminReducer,
    admin: logsReducer,
    app: AppReducer,
    profile: ProfileReducer,
    notification: NotificationReducer,
    privilegedAccess: PrivilegedAccessReducer,
    credentialRequestForm: CredentialRequestFormReducer,
    requestSubmittedDetails: requestSubmittedDetailsReducer,
  },
  sagas: [loginSaga, RequestHomeSaga, adminHomeSaga],
};

const store =
  window.store === undefined
    ? createStore(
        {
          initialState: {},
          enhancers: [],
          extensions: [getThunkExtension(), getSagaExtension()],
        },
        defaultModule
      )
    : window.store;

store.subscribe(
  throttle(() => saveApplicationRequestStateToStorage('applicationRequest', store.getState())),
  1000
);
// TODO: No its not ok to put store in global as anyone with a dev tools can access the store and dispatch actions and modify store
// Right way is to abstract this logic on to a closure and import it inside MFE's and then use it
window.store = store; // Its ok to put store in global. sometimes other mfe's can use it.

export default store;
