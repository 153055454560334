/**
 * A utility function for setting the day period of the time string to lowercase:
 * AM -> am
 * PM -> pm
 *
 *
 * @function
 * @param timeString {string} - The string time to format the period for
 * @returns {string} A formatted time string with the day period set to lowercase
 *
 * @example
 * 09:00am
 */
const periodToLowerCase = (timeString) =>
  timeString.replace(' AM', 'am').replace(' PM', 'pm');

/**
 * A utility function for the scheduler component to format a date string to follow the following format:
 * HH:MM[am/pm]
 *
 *
 * @function
 * @param dateString {string} - The string date representing the start or end of the time window.
 * @param roundToNearest {string} - String value representing the unit of time to round the time up/down to
 * @param is24HourFormat - Boolean value for displaying time format - Shows 24 hour clock if true
 * @returns {string} A formatted string time window for the scheduler component
 *
 * @example
 * 09:00am
 */
const getSchedulerTimeWindowString = (
  dateString,
  roundToNearest,
  is24HourFormat
) => {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
  };

  const date = new Date(dateString);

  // TODO: requirement change - possibly not supporting; remove?
  if (roundToNearest === 'hour') {
    date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
    date.setMinutes(0, 0, 0);
  } else if (roundToNearest === 'halfHour') {
    date.setMinutes(Math.ceil(date.getMinutes() / 30) * 30);
  }

  return periodToLowerCase(
    date.toLocaleString(is24HourFormat ? 'en-GB' : 'en-US', options)
  );
};

export { getSchedulerTimeWindowString, periodToLowerCase };
